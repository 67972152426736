import React, { useEffect, useState } from "react";
import useCustomizationContext from "../context/useCustomizationContext";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import {
  fetchCreateTestLinkToken,
  publishCustomizableLinkChanges,
} from "../../../../../api-client/APIClient";
import CustomizationChangesOverview from "../helpers/CustomizationChangesOverview";
import { getTenantConfigForMergeLink } from "../../../linked-accounts/AddTestLinkedAccountButton";
import { useMergeLink } from "@mergeapi/react-merge-link";

const ViewCustomizationChangesModal = () => {
  const [linkToken, setLinkToken] = useState<string | undefined>(undefined);
  const {
    organizationCustomization,
    integrationCustomizations,
    setModalTypeShown,
    setOriginalOrganizationCustomization,
    setOriginalIntegrationCustomizations,
  } = useCustomizationContext();

  useEffect(() => {
    fetchCreateTestLinkToken(setLinkToken);
  }, []);

  const tenantConfig = getTenantConfigForMergeLink();

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: () => {},
    onExit: () => {
      fetchCreateTestLinkToken(setLinkToken);
    },
    ...tenantConfig,
  });

  return (
    <Dialog
      open
      onClose={() => setModalTypeShown(null)}
      onPrimaryButtonClick={() => {
        publishCustomizableLinkChanges({
          status: "PUBLISHED",
          organization_customization_data: organizationCustomization,
          integration_customizations_data: integrationCustomizations,
        });
        setModalTypeShown(null);
        setOriginalOrganizationCustomization(organizationCustomization);
        setOriginalIntegrationCustomizations(JSON.parse(JSON.stringify(integrationCustomizations)));
      }}
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
      primaryButtonText="Publish changes"
      onSecondaryButtonClick={() => {
        setModalTypeShown(null);
        open();
      }}
      secondaryButtonText="View Merge Link"
      secondaryButtonDisabled={!isReady}
      secondaryButtonLoading={!isReady}
      title="View Merge Link"
      variant="md"
    >
      <p>
        View Merge Link without recent changes. Publish these changes to have them go into effect
        for all users, <b>effective immediately.</b>
      </p>
      <CustomizationChangesOverview />
    </Dialog>
  );
};

export default ViewCustomizationChangesModal;
