import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IntegrationIssue } from "../../IntegrationsManagementEntities";
import { IssueDescription } from "../../../../constants";
import ContentLoader from "react-content-loader";
import { spectrum } from "../../../../styles/theme";
import DeprecatedH2 from "../../../../deprecated/DeprecatedH2";

type Props = {
  integrationIssue: IntegrationIssue | undefined;
};

export const getDescription = (integrationIssue: IntegrationIssue) => {
  switch (integrationIssue?.error_description) {
    case IssueDescription.MISSING_PERMISSION:
      return "Error making API requests due to missing permissions";
    case IssueDescription.BAD_API_KEY:
      return `The API Credentials used to link your system are now invalid. Please go through the authentication flow again and relink your ${integrationIssue?.linked_account?.integration?.name} account.`;
    case IssueDescription.HIT_RATE_LIMIT:
      return "Error making API requests due to hitting the rate limit";
    case IssueDescription.INVALID_LOGIN_CREDENTIALS:
      return "Error accessing provider due to expired login credentials";
    case IssueDescription.EXPIRED_MFA_AUTHENTICATION:
      return "Error accessing provider due to expired multi-factor authentication credentials";
    case IssueDescription.SFTP_MISSING_REQUIRED_FIELDS:
      const missingHeaders = integrationIssue?.metadata
        ?.flatMap((entry) => entry.missing_headers)
        .join(", ");
      return missingHeaders
        ? `The following required field(s) are missing: ${missingHeaders}.`
        : "Some required fields are missing.";
    case IssueDescription.SFTP_UNRECOGNIZED_FILE_NAME:
      const unrecognizedFileName = integrationIssue?.metadata
        ?.flatMap((entry) => entry.file_name)
        .join(", ");
      return `The file you uploaded${
        unrecognizedFileName ? ` “${unrecognizedFileName}”` : ""
      } is not recognized. Please update the file name and try again.`;
    case IssueDescription.SFTP_UNSUPPORTED_FILE_TYPE:
      const unsupportedFileName = integrationIssue?.metadata?.flatMap((entry) => entry.file_name);
      return `Unable to process the file${
        unsupportedFileName ? ` “${unsupportedFileName}”` : ""
      }. Please upload a file in CSV format.`;
    case IssueDescription.SFTP_INCORRECTLY_FORMATTED_ROW:
      const incorrectRows = integrationIssue?.metadata
        ?.flatMap((entry) => entry.incorrect_rows)
        .join(", ");
      return incorrectRows
        ? `The following row(s) contain formatting errors and have been deleted from your report: row ${incorrectRows}.`
        : "Some rows contain formatting errors and have been deleted from your report.";
    case IssueDescription.THIRD_PARTY_OFFLINE:
      return "Data transmission failure because the QuickBooks Desktop web connector is offline. Please make sure the web connector is online.";
    default:
      return "Your connection was disconnected. Please relink to continue syncing the latest information.";
  }
};
const IndividualIssueDescription = ({ integrationIssue }: Props) => {
  return (
    <Row className="mb-3 pl-0 pr-5">
      <Col>
        <Card>
          <Card.Body>
            <DeprecatedH2 className="mb-2">Description</DeprecatedH2>
            <p className="mb-0">
              {integrationIssue ? (
                getDescription(integrationIssue)
              ) : (
                <ContentLoader
                  speed={1.4}
                  width={250}
                  height={40}
                  viewBox="0 0 250 40"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="2" y="4" rx="3" ry="3" width="250" height="14" />
                  <rect x="2" y="25" rx="3" ry="3" width="250" height="14" />
                </ContentLoader>
              )}
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default IndividualIssueDescription;
