import React, { useEffect, useState } from "react";
import ConfigurationSettingsPage from "../helpers/ConfigurationSettingsPage";
import ConfigurationWhiteLabelGuideCard from "../cards/ConfigurationWhiteLabelGuideCard";
import WhiteLabelGuideModal from "../modals/WhiteLabelGuideModal";
import { Button } from "@merge-api/merge-javascript-shared";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import whiteLabelPNG from "../../../../../assets/img/white-label-guides-feature.png";
import ConfigurationSettingsCard from "../helpers/ConfigurationSettingsCard";
import EmptyStateWrapper from "../../../../shared/EmptyStateWrapper";
import { useRequest } from "../../../../shared/hooks/useRequest";
import useAppContext from "../../../../context/useAppContext";
import NoPermissionsBlock from "../../../../common/NoPermissionsBlock";

export type WhiteLabelGuide = {
  subdomain: string;
  square_logo: File | null;
  active: boolean;
};

const ConfigurationWhiteLabelGuideSettingsPage = () => {
  // STATE
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [whiteLabelGuide, setWhiteLabelGuide] = useState<WhiteLabelGuide>({
    subdomain: "",
    square_logo: null,
    active: true,
  });
  const [hasSavedWhiteLabelGuide, setHasSavedWhiteLabelGuide] = useState<boolean>(false);

  // HOOKS
  const { data: remoteWhiteLabelGuideData, loading: isLoading } = useRequest<WhiteLabelGuide>({
    path: `organizations/white-label-guide-configuration`,
    method: HTTPMethod.GET,
  });
  const { isUserPrivileged } = useAppContext();

  useEffect(() => {
    setWhiteLabelGuide(
      remoteWhiteLabelGuideData || { subdomain: "", square_logo: null, active: true },
    );
    setHasSavedWhiteLabelGuide(!!remoteWhiteLabelGuideData);
  }, [remoteWhiteLabelGuideData]);

  // HANDLERS
  const handleWhiteLabelGuideChanges = (whiteLabelGuide: WhiteLabelGuide) => {
    setWhiteLabelGuide(whiteLabelGuide);
  };
  const handleHasSavedWhiteLabelGuide = (isWhiteLabelGuideSaved: boolean) => {
    setHasSavedWhiteLabelGuide(isWhiteLabelGuideSaved);
  };

  return isUserPrivileged ? (
    isLoading ? (
      <EmptyStateWrapper isSpinner />
    ) : (
      <ConfigurationSettingsPage
        title="White-label guides"
        subtitle="Customize your white-labeled version of Merge's help guide articles."
        subtitleRightContent={
          hasSavedWhiteLabelGuide ? (
            <></>
          ) : (
            <>
              <Button
                size="sm"
                disabled={hasSavedWhiteLabelGuide}
                onClick={() => setIsModalOpen(true)}
              >
                Set up
              </Button>
              <WhiteLabelGuideModal
                whiteLabelGuide={whiteLabelGuide}
                handleWhiteLabelGuideChanges={handleWhiteLabelGuideChanges}
                handleHasSavedWhiteLabelGuide={handleHasSavedWhiteLabelGuide}
                isReupload={false}
                isModalOpen={isModalOpen}
                onHide={() => setIsModalOpen(false)}
              />
            </>
          )
        }
      >
        {hasSavedWhiteLabelGuide ? (
          <ConfigurationWhiteLabelGuideCard
            whiteLabelGuide={whiteLabelGuide}
            handleWhiteLabelGuideChanges={handleWhiteLabelGuideChanges}
            handleHasSavedWhiteLabelGuide={handleHasSavedWhiteLabelGuide}
          />
        ) : (
          <ConfigurationSettingsCard title="Learn about white-label guides">
            <img className="max-w-full" src={whiteLabelPNG} />
          </ConfigurationSettingsCard>
        )}
      </ConfigurationSettingsPage>
    )
  ) : (
    <NoPermissionsBlock restrictedPageName="White-label guides" />
  );
};

export default ConfigurationWhiteLabelGuideSettingsPage;
