import React, { useContext, useEffect, useState } from "react";
import { AlertTriangle, ArrowRight } from "lucide-react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { Alert, Link, TextField, Toggle } from "@merge-api/merge-javascript-shared";
import FieldMappingsGridContainer from "../../components/FieldMappingsGridContainer";
import FieldMappingModalTitle from "../components/FieldMappingModalTitle";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../../router/RouterUtils";
import RemoteFieldCard from "../components/RemoteFieldCard";
import FieldMappingTargetBadge from "../../../../../../shared/FieldMappingTargetBadge";
import FieldMappingModalHeader from "../components/FieldMappingModalHeader";
import { createFieldMapping, doesModelHaveRemoteDataEnabled } from "../helpers";
import LinkedAccountFieldMappingsContext from "../../context/LinkedAccountFieldMappingsContext";
import useAppContext from "../../../../../../context/useAppContext";

const LinkedAccountSpecificFieldMappingCreation = () => {
  const { user } = useAppContext();
  const isLinkedAccountFieldMappingPromotionEnabled =
    user.is_linked_account_field_mapping_promotion_enabled;

  const {
    linkedAccount,
    onModalClose,
    selectedRemoteField,
    jmesPath,
    fieldMappingInstances,
    commonModelsToMappingConfig,
  } = useContext(FieldMappingModalContext);
  const [fieldMappingTargetName, setFieldMappingTargetName] = useState<string | null>(null);
  const [fieldMappingTargetDescription, setFieldMappingTargetDescription] = useState<string | null>(
    null,
  );
  const [isCreating, setIsCreating] = useState(false);
  const [isOrganizationWide, setIsOrganizationWide] = useState(false);
  const [
    shouldShowEditingExistingFieldWarningBanner,
    setShouldShowEditingExistingFieldWarningBanner,
  ] = useState<boolean>(false);
  const { setFieldMappingTargetAndInstances } = useContext(LinkedAccountFieldMappingsContext);

  /* this useEffect is for determining if a user is trying to edit a preexisting mapping
   * this means that the field name typed in and the remote field match an existing mapping
   */
  useEffect(() => {
    setShouldShowEditingExistingFieldWarningBanner(
      fieldMappingInstances.some(
        (fieldMappingInstance) =>
          fieldMappingInstance.common_model_name === selectedRemoteField?.commonModelName &&
          fieldMappingInstance.field_key === fieldMappingTargetName,
      ),
    );
  }, [fieldMappingTargetName, selectedRemoteField]);

  return (
    <>
      <FieldMappingModalTitle
        title={`Map additional ${linkedAccount?.end_user.organization_name}-specific target field`}
        onClose={onModalClose}
      />
      <div className="px-6 mt-5">
        <div className="text-base">
          <p className="mb-2">
            Select a third-party Remote Field to create a Field Mapping for this new{" "}
            {linkedAccount?.end_user.organization_name}-specific target field.
          </p>
          <p className="mb-0">
            This change will be reflected after the next sync which will be a full resync of data
            for {linkedAccount?.end_user.organization_name}.
          </p>
        </div>
        <div className="flex flex-col mt-6 gap-y-4">
          <FieldMappingModalHeader
            integrationName={linkedAccount?.integration.name!}
            integrationSquareImage={linkedAccount?.integration.square_image}
            commonModel={selectedRemoteField?.commonModelName}
          />
          <FieldMappingsGridContainer>
            <>
              <div className="flex-row flex-shrink-0">
                <RemoteFieldCard
                  displayName={selectedRemoteField?.display_name || ""}
                  apiEndpointPath={selectedRemoteField?.api_endpoint_path}
                  type={selectedRemoteField?.type}
                  jmesPath={jmesPath}
                  badge={
                    <FieldMappingTargetBadge
                      type={
                        isOrganizationWide
                          ? "org-wide-target-field"
                          : "linked-account-specific-target-field"
                      }
                      linkedAccount={linkedAccount!}
                    />
                  }
                />
                {isLinkedAccountFieldMappingPromotionEnabled && (
                  <Toggle
                    checked={isOrganizationWide}
                    onChange={() => setIsOrganizationWide(!isOrganizationWide)}
                    label="Convert to integration-wide mapping"
                    className="mt-4"
                  />
                )}
              </div>
              <div className="flex p-4 pt-3 flex-shrink-0">
                <ArrowRight size={20} />
              </div>

              {!doesModelHaveRemoteDataEnabled(
                selectedRemoteField!.commonModelName,
                commonModelsToMappingConfig,
              ) ? (
                <Alert color="yellow" icon={<AlertTriangle size={16} />}>
                  <span>
                    <Link
                      target="_blank"
                      href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount!.category}#${
                        selectedRemoteField!.commonModelName
                      }`}
                    >
                      Enable Remote Data
                    </Link>
                    {"  "} to create a new target field for this model
                  </span>
                </Alert>
              ) : (
                <div className="flex-row">
                  <TextField
                    placeholder="Name"
                    value={fieldMappingTargetName!}
                    onChange={(e) => {
                      setFieldMappingTargetName(e.target.value);
                    }}
                  />
                  <TextField
                    className="mt-4"
                    placeholder="Description (optional)"
                    value={fieldMappingTargetDescription ? fieldMappingTargetDescription : ""}
                    onChange={(e) => {
                      setFieldMappingTargetDescription(e.target.value);
                    }}
                  />
                  {shouldShowEditingExistingFieldWarningBanner && (
                    <div className="w-full mt-4 h-[40px]">
                      <Alert showWarningIcon className="h-[40px]">
                        <span className="text-sm">You are editing an existing target field</span>
                      </Alert>
                    </div>
                  )}
                </div>
              )}
            </>
          </FieldMappingsGridContainer>
        </div>
      </div>
      <div className="pr-6 !mt-6">
        <CancelAndNextFooter
          buttonText="Save mapping"
          onCancel={onModalClose}
          onNext={() => {
            createFieldMapping(
              linkedAccount!,
              selectedRemoteField!,
              fieldMappingTargetName!,
              setIsCreating,
              onModalClose,
              setFieldMappingTargetAndInstances,
              fieldMappingTargetDescription,
              isOrganizationWide,
              jmesPath,
            );
          }}
          isNextDisabled={fieldMappingTargetName == null || fieldMappingTargetName == undefined}
          isLoading={isCreating}
        />
      </div>
    </>
  );
};
export default LinkedAccountSpecificFieldMappingCreation;
