import React from "react";
import { Key } from "lucide-react";
import { LOGIN_WITH_SSO_PATH } from "../../../../router/RouterUtils";
import GoogleGLogo from "../../../../assets/svg/logos/google/google-g-logo.svg";
import { API_DOMAIN } from "../../../../api-client/APIClient";
import { Button } from "react-bootstrap";

interface Props {
  text?: string;
}

export const SignInWithGoogleLink = ({ text }: Props) => {
  const signInWithGoogleUrl = `${API_DOMAIN}/sso/social/google/login`;
  return (
    <Button
      href={signInWithGoogleUrl}
      variant="link"
      block
      className="btn-sign-in-with-button relative flex"
    >
      <img src={GoogleGLogo} className="absolute start-4" />
      <span className="w-100 text-center">{text ? text : "Sign in with Google"}</span>
    </Button>
  );
};

export default SignInWithGoogleLink;

export const SignInWithSSOLink = () => {
  return (
    <Button
      href={LOGIN_WITH_SSO_PATH}
      variant="link"
      block
      className="btn-sign-in-with-button relative flex items-center"
    >
      <Key className="absolute start-4 text-gray-60" />
      <span className="w-100 text-center">Sign in with SSO</span>
    </Button>
  );
};
