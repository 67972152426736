import React from "react";
import MergeMark from "../../../../../../../assets/img/mark-borderless.png";
import { OrganizationAvatar, UserAvatarSize } from "../../../../../../shared/MergeAvatars";
import FieldMappingsGridContainer from "../../components/FieldMappingsGridContainer";

type FieldMappingModalHeaderProps = {
  integrationName?: string;
  integrationSquareImage?: string | undefined;
  isCommonModelOverride?: boolean;
  commonModel?: string;
};

const FieldMappingModalHeader = ({
  integrationName,
  integrationSquareImage,
  isCommonModelOverride = false,
  commonModel,
}: FieldMappingModalHeaderProps) => {
  return (
    <FieldMappingsGridContainer>
      <>
        <div className="flex items-center font-semibold">
          <OrganizationAvatar
            size={UserAvatarSize.xs}
            imageURL={integrationSquareImage}
            organizationName={integrationName}
            isCircle
            className="mr-3 d-flex"
          />
          {integrationName} Remote Field
        </div>
        <div className="w-5"></div>
        <div className="flex items-center font-semibold">
          <img
            src={MergeMark}
            width={20}
            height={20}
            className="rounded-full flex items-center justify-center"
          />
          <div className="ml-2">
            {isCommonModelOverride
              ? `Merge ${commonModel || ""} field`
              : `New ${commonModel || ""} target field`}
          </div>
        </div>
      </>
    </FieldMappingsGridContainer>
  );
};

export default FieldMappingModalHeader;
