import React, { useState } from "react";
import { Link, Text } from "@merge-api/merge-javascript-shared";
import {
  fetchWithoutAuth,
  UserSuccessData,
  FormErrorData,
  setAuthTokenAndUserType,
  hasAuthToken,
  getTenancy,
  Tenancy,
} from "../../../../api-client/APIClient";
import { Redirect } from "react-router-dom";
import { loginTenantOptions } from "../../../../constants";
import { Row, Col, Card, ListGroup, Form, Alert } from "react-bootstrap";
import cx from "classnames";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../shared/SpinnerButton";
import MergeLogo from "../../../../assets/svg/logos/merge/logo.svg";
import { useMediaQuery } from "react-responsive";
import { User } from "../../../../models/Entities";
import { LANDING_PAGE_PATH, PASSWORD_SEND_RESET_PATH } from "../../../../router/RouterUtils";
import SignInWithGoogleLink, { SignInWithSSOLink } from "../signup/SignInWithButtons";
import HorizontalRuleWithText from "../shared/HorizontalRuleWithText";
import { REGEX_EMAIL_ADDRESS } from "../../../shared/utils/SharedComponentUtils";
import QRCode from "qrcode.react";
import useSSOFeatureFlags from "../saml/SamlUtils";
import DeprecatedH5 from "../../../../deprecated/DeprecatedH5";
import CustomSelect from "../CustomSelect"; // Importing the CustomSelect component
import { SIGNUP_PATH } from "../../../../router/RouterUtils";

interface FormData {
  email: string;
  password: string;
  totp_token?: string;
}

interface CreatedTotpResponse {
  config_url: string;
}

type Props = {
  setUser: (user: User) => void;
};

const Login: React.FC<Props> = ({ setUser }) => {
  const { register, handleSubmit, setError, errors } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMfaRequiredForOrg, setIsMfaRequiredForOrg] = useState(false);
  const [isTwoFactorFieldShown, setIsTwoFactorFieldShown] = useState(false);
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const [configURLMfa, setConfigURLMfa] = useState<null | string>(null);
  const [confirmTotpToken, setConfirmTotpToken] = useState(false);
  const isSingleTenant = getTenancy() == Tenancy.SingleTenant;
  const isEUMultiTenant = getTenancy() === Tenancy.EUMultiTenant;
  const isAPAMultiTenant = getTenancy() === Tenancy.APMultiTenant;
  const { isGoogleSSODisabled, isSAMLSSODisabled } = useSSOFeatureFlags();
  const selectedOptionValue =
    loginTenantOptions[isEUMultiTenant ? "eu" : isAPAMultiTenant ? "apac" : "usa"];

  const performLogin = (data: FormData) => {
    setErrorMessage("");
    setIsLoading(true);
    const userInfo = {
      email: data.email,
      password: data.password,
      totp_token: data.totp_token,
      confirm_totp_on_login: confirmTotpToken,
    };
    fetchWithoutAuth({
      path: "/users/login",
      method: "POST",
      body: userInfo,
      onResponse: (data: UserSuccessData) => {
        if (data.token) {
          setAuthTokenAndUserType(data.token, data.user.type);
        }
        setUser(data.user);
        setIsLoading(false);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((errData: FormErrorData) => {
            for (const field_name in errData) {
              if (field_name === "non_field_errors") {
                if (
                  errData[field_name][0] == "A two-factor auth token is required for this user."
                ) {
                  setIsTwoFactorFieldShown(true);
                } else if (
                  errData[field_name][0] ==
                  "User is required to set up two-factor auth for this organization."
                ) {
                  setIsMfaRequiredForOrg(true);
                  createTotpForUser(data);
                } else {
                  setErrorMessage(errData[field_name][0]);
                }
                continue;
              }
              setError(field_name, { message: errData[field_name][0] });
            }
            setIsLoading(false);
          });
        } else {
          setErrorMessage("An unknown error occurred. Please try again.");
          setIsLoading(false);
        }
      },
    });
  };

  const createTotpForUser = (data: FormData) => {
    setIsLoading(true);
    const userInfo = {
      email: data.email,
      password: data.password,
      create_totp_on_login: true,
    };
    fetchWithoutAuth({
      path: "/users/login",
      method: "POST",
      body: userInfo,
      onResponse: (data: CreatedTotpResponse) => {
        if (data.config_url) {
          setConfigURLMfa(data.config_url);
          setConfirmTotpToken(true);
        }
        setIsLoading(false);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                if (data[field_name][0] == "A two-factor auth token is required for this user.") {
                  setIsTwoFactorFieldShown(true);
                } else {
                  setErrorMessage(data[field_name][0]);
                }
                continue;
              }
              setError(field_name, { message: data[field_name][0] });
            }
            setIsLoading(false);
          });
        } else {
          setErrorMessage("An unknown error occurred. Please try again.");
          setIsLoading(false);
        }
      },
    });
  };

  return (
    <>
      {hasAuthToken() && !isLoading ? <Redirect to="/" /> : null}
      <div className="Login">
        <div
          className={cx(
            "Login-header d-flex align-items-center",
            isDesktopOrTablet ? "justify-content-between" : "justify-content-center",
          )}
        >
          <a href={LANDING_PAGE_PATH}>
            <img src={MergeLogo} />
          </a>
        </div>

        <div className="Login-form d-flex align-items-center justify-content-center">
          <div
            className={cx(
              "col-12 col-md-5 col-xl-4 my-24 rounded-2xl bg-white border-charcoal-20 border-[1px] drop-shadow-sm px-12 pt-12 max-w-[488px]",
              isDesktopOrTablet ? "pb-6" : "pb-4",
            )}
          >
            <Text variant="h1" className="mb-1">
              <>{!confirmTotpToken ? "Sign in" : "Set up 2FA"}</>
            </Text>
            {isDesktopOrTablet && confirmTotpToken && (
              <p className="mb-9 text-center" style={{ whiteSpace: "pre-line" }}>
                {"You're required to set up two-factor authentication" +
                  "\n" +
                  "based on your organization settings"}
              </p>
            )}
            <Form.Group
              className={cx("d-flex justify-content-between", isDesktopOrTablet ? "mb-8" : "mb-6")}
            >
              {isDesktopOrTablet && (
                <p className="mb-0">
                  New to Merge? <Link href={SIGNUP_PATH}>Sign up</Link>
                </p>
              )}
              {isDesktopOrTablet && !isSingleTenant && (
                <CustomSelect options={loginTenantOptions} selectedOption={selectedOptionValue} />
              )}
            </Form.Group>
            <Form className="Hubspot-sign-in" onSubmit={handleSubmit(performLogin)}>
              {!isTwoFactorFieldShown && !isMfaRequiredForOrg && !isGoogleSSODisabled && (
                <>
                  <Form.Group>
                    <SignInWithGoogleLink />
                    {!isSAMLSSODisabled && (
                      <div className="mt-6">
                        <SignInWithSSOLink />
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <HorizontalRuleWithText text="OR" />
                  </Form.Group>
                </>
              )}
              {errorMessage && <Alert variant="dark">{errorMessage}</Alert>}

              <Form.Group hidden={isTwoFactorFieldShown || isMfaRequiredForOrg}>
                <Form.Label className="mb-2" htmlFor="email">
                  Email
                </Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  className={cx("h-[38px]", { "is-invalid": errors.email })}
                  ref={register({
                    required: true,
                    pattern: REGEX_EMAIL_ADDRESS,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group hidden={isTwoFactorFieldShown || isMfaRequiredForOrg}>
                <div className="mb-2 flex justify-between">
                  <Form.Label htmlFor="password">Password</Form.Label>
                  <Link href={PASSWORD_SEND_RESET_PATH}>Forgot password?</Link>
                </div>

                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={cx("h-[38px]", {
                    "is-invalid": errors.password,
                  })}
                  ref={register({
                    required: true,
                    minLength: 8,
                    maxLength: 100,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid password at least 8 characters in length
                </Form.Control.Feedback>
              </Form.Group>

              {isTwoFactorFieldShown && !isMfaRequiredForOrg && (
                <Form.Group>
                  <Form.Label htmlFor="totp_token">Two-factor code</Form.Label>
                  <Form.Control
                    name="totp_token"
                    className={cx({
                      "is-invalid": errors.totp_token,
                    })}
                    ref={register({
                      required: true,
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid two-factor auth token. If you continue to have trouble,
                    please contact us via live chat in the bottom right.
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {configURLMfa && (
                <Row className="align-items-center justify-content-center">
                  <ListGroup variant="flush" className="my-n3">
                    <ListGroup.Item>
                      <Card>
                        <Card.Body>
                          <Row className="align-items-center justify-content-center mx-3 mb-5">
                            Use your authenticator app to scan the QR code and enter the generated
                            code below
                          </Row>
                          <Row className="align-items-center justify-content-center">
                            <Col className="col-auto">
                              <Card className="card-inactive border-0">
                                <QRCode value={configURLMfa} className="m-9" />
                              </Card>
                            </Col>
                          </Row>
                          <Row className="align-items-center justify-content-center">
                            <DeprecatedH5 className="text-gray-60">
                              We recommend Google Authenticator (iOS, Android)
                            </DeprecatedH5>
                          </Row>
                        </Card.Body>
                      </Card>
                      <Row className="align-items-center">
                        <Col>
                          <Form.Group>
                            <Form.Label htmlFor="totp_token">Two-factor code</Form.Label>
                            <Form.Control
                              name="totp_token"
                              className={cx({
                                "is-invalid": errors.totp_token,
                              })}
                              ref={register({
                                required: true,
                              })}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please enter a valid two-factor auth token. If you continue to have
                              trouble, please contact us via live chat in the bottom right.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Row>
              )}
              <Form.Group>
                <SpinnerButton
                  text="Sign in"
                  isLoading={isLoading}
                  className="primaryAction btn btn-primary btn-block font-semibold h-12 mt-8"
                />
              </Form.Group>
              {!isDesktopOrTablet && (
                <Form.Group className="d-flex justify-content-center mb-0">
                  <Link href={SIGNUP_PATH}>Create account</Link>
                </Form.Group>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
