import React, { useCallback, useEffect, useState } from "react";
import TileStep from "../helpers/TileStep";
import { ReactComponent as ScopeLock } from "../../src/scopes.svg";
import { ReactComponent as ScopeToggle } from "../../src/ScopeToggle.svg";
import LeftSideBar from "../helpers/LeftSideBar";
import LeftSideBarSpacing, { PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import { ChevronRight, Star } from "lucide-react";
import {
  APICategory,
  Typeahead,
  displayNameForAPICategory,
} from "@merge-api/merge-javascript-shared";
import INTEGRATION_CATEGORY_LIST from "../../../../../models/Helpers";
import {
  SortedModelsMap,
  createSortedModelsMap,
} from "../../../configuration/common-models/CommonModelUtils";
import {
  fetchCommonModelsFromCategory,
  updateCommonModel,
} from "../../../../../api-client/categories/CommonModelTogglesAPIClient";
import ErrorFallback from "../../../../shared/ErrorFallback/ErrorFallback";
import EmptyStateWrapper from "../../../../shared/EmptyStateWrapper";
import {
  CATEGORY_RECOMMENDED_MODELS,
  CUSTOM_OBJECT_COMMON_MODEL_NAMES,
} from "../../../../../constants";
import ScopeRow from "./ScopeRow";
import { commonModelsConfigurationPathForIndividualCategory } from "../../../../../router/RouterUtils";
import { MergeFlagFeature, useMergeFlag } from "../../../../shared/hooks/useMergeFlag";
import useAppContext from "../../../../context/useAppContext";
import { EnabledAction } from "../../../../../models/CommonModel";
import { Result } from "../../../../../api-client/APIClient";
import { processErrorResult } from "../../../configuration/common-models/ConfigurationCommonModelsPage";
import { Text } from "@merge-api/merge-javascript-shared";

const ScopesSection = () => {
  const [selectedCategory, setSelectedCategory] = useState<APICategory>(APICategory.hris);
  const [commonModels, setCommonModels] = useState<SortedModelsMap>({});
  const [recommendedModelsMap, setRecommendedModelsMap] = useState<SortedModelsMap>({});
  const [otherModelsMap, setOtherModelsMap] = useState<SortedModelsMap>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const { user } = useAppContext();

  const { enabled: dependentModelScopesFlag } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_COMMON_MODEL_SCOPE_DEPENDENCIES,
    organizationId: user.organization.id,
  });
  const { enabled: isDefaultOffScopesEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_DEFAULT_OFF_SCOPES,
    organizationId: user.organization.id,
  });

  const filterObject = (obj: SortedModelsMap, keys: string[]) => {
    keys = keys?.map((key) => key.toLowerCase()) ?? [];

    const subset: SortedModelsMap = {};
    const remaining: SortedModelsMap = {};

    for (const key in obj) {
      if (keys.includes(key.toLowerCase())) {
        subset[key] = obj[key];
      } else {
        remaining[key] = obj[key];
      }
    }

    setRecommendedModelsMap(subset);
    setOtherModelsMap(remaining);
  };

  const updateEnabledStatus = useCallback(
    (
      modelName: string,
      fieldName: string | null,
      enabledActions: Array<EnabledAction>,
      optionalActions: Array<EnabledAction>,
      nonOptionalActions: Array<EnabledAction>,
      isFieldConfigurable?: boolean,
    ): Promise<Result<void>> => {
      const relatingField = (fieldName && commonModels[modelName].fields[fieldName]) || undefined;
      const relatedModelName = relatingField?.related_to;
      const relatedModel = !relatedModelName ? undefined : commonModels[relatedModelName];
      const isRelatedToSubobject = relatedModel && !relatedModel.has_endpoints;
      const shouldToggleRelatedModelInsteadOfField =
        relatedModelName && isRelatedToSubobject && relatedModel.name !== modelName;
      const transmittedFieldName = shouldToggleRelatedModelInsteadOfField
        ? undefined
        : fieldName ?? undefined;
      return updateCommonModel({
        category: selectedCategory,
        enabledActions,
        modelName: shouldToggleRelatedModelInsteadOfField ? relatedModelName : modelName,
        fieldName: transmittedFieldName,
        optionalActions,
        nonOptionalActions,
        isFieldConfigurable,
      }).then((result) => {
        if (result.status === "success") {
          const sortedModelsMap = createSortedModelsMap(result.data);
          setCommonModels(sortedModelsMap);
          filterObject(sortedModelsMap, CATEGORY_RECOMMENDED_MODELS[selectedCategory]);
          return { status: "success", data: undefined };
        } else {
          processErrorResult(result, commonModels[modelName]);
          return result;
        }
      });
    },
    [commonModels],
  );

  /**
   * Gets the Common Models on page load and sets them in the UI
   */
  useEffect(() => {
    setIsLoading(true);
    fetchCommonModelsFromCategory(selectedCategory)
      .then((result) => {
        if (result.status === "success") {
          return createSortedModelsMap(result.data);
        } else {
          setHasError(true);
          return null;
        }
      })
      .then((commonModelsMap) => {
        if (commonModelsMap) {
          filterObject(commonModelsMap, CATEGORY_RECOMMENDED_MODELS[selectedCategory]);
          setCommonModels(commonModelsMap);

          setTimeout(() => {
            setIsLoading(false);
          }, 400);
        }
      });
  }, [selectedCategory]);

  return (
    <div className="flex flex-col mx-auto w-11/12">
      <Text variant="h3" className="mb-6">
        Manage data access
      </Text>
      <div className="flex flex-row">
        <LeftSideBar stepNumber="1" isNormalStep />
        <TileStep
          img={<ScopeLock />}
          title="Set up your Scopes"
          subtitle={
            <>
              <p className="mb-2">
                Enable the data models that you want from your users to finish this step!
              </p>
              Aim to keep this minimal. Data will sync significantly faster, and your users’
              security teams will demand it. You can always enable more Scopes later.{" "}
              <a
                href="https://help.merge.dev/en/articles/5950052-common-model-and-field-scopes"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
              .
            </>
          }
          stepNumber={1}
        />
      </div>
      <div className="flex flex-row">
        <LeftSideBarSpacing />
        <PaddingLessCol>
          <div className="h-8" />
        </PaddingLessCol>
      </div>
      <div className="flex flex-row">
        <LeftSideBarSpacing />
        <div className="flex-grow bg-white shadow-md rounded-[10px] mb-8 min-w-0">
          <div className="flex flex-row px-6 py-5 items-center justify-between border-b border-gray-10 min-w-0">
            <div>
              <h4>Common Model Scopes</h4>
              <a
                href={commonModelsConfigurationPathForIndividualCategory(selectedCategory)}
                className="font-medium my-0 text-12"
              >
                More configuration <ChevronRight size={12} />
              </a>
            </div>

            <div className="max-w-[192px]">
              <Typeahead
                options={INTEGRATION_CATEGORY_LIST}
                getOptionLabel={(category: APICategory) => displayNameForAPICategory(category)}
                onChange={(_, category) => {
                  if (category) {
                    setSelectedCategory(category);
                  }
                }}
                value={selectedCategory}
                disableClearable
              />
            </div>
          </div>
          {isLoading ? (
            <div className="h-[360px] flex items-center justify-center">
              <EmptyStateWrapper isSpinner />
            </div>
          ) : (
            <>
              {Object.keys(CATEGORY_RECOMMENDED_MODELS[selectedCategory] ?? {}).length > 0 && (
                <div className="py-4 px-6 border-b-[1px] border-gray-10">
                  <div className=" py-1 text-base font-semibold pb-1 flex flex-row items-center">
                    <div className="pr-2 flex">
                      <Star size={16} />
                    </div>
                    <div className="flex">Recommended models</div>
                  </div>
                  {hasError ? (
                    <ErrorFallback />
                  ) : Object.keys(recommendedModelsMap).length === 0 ? (
                    <EmptyStateWrapper isSpinner />
                  ) : (
                    Object.values(recommendedModelsMap)
                      .filter((model) => model.has_endpoints)
                      .filter((model) => !CUSTOM_OBJECT_COMMON_MODEL_NAMES.includes(model.name))
                      .filter((model) => !model.is_permanently_disabled)
                      .map((model, index, filteredModels) => {
                        const isLast = index === filteredModels.length - 1;
                        return (
                          <div>
                            <ScopeRow
                              model={model}
                              selectedCategory={selectedCategory}
                              updateEnabledStatus={updateEnabledStatus}
                              isDefaultScopesOffFlag={isDefaultOffScopesEnabled}
                              dependentModelScopesFlag={dependentModelScopesFlag}
                            />
                          </div>
                        );
                      })
                  )}
                </div>
              )}
              <div className="py-4 px-6">
                {hasError ? (
                  <ErrorFallback />
                ) : Object.keys(otherModelsMap).length === 0 ? (
                  <EmptyStateWrapper isSpinner />
                ) : (
                  Object.values(otherModelsMap)
                    .filter((model) => model.has_endpoints)
                    .filter((model) => !CUSTOM_OBJECT_COMMON_MODEL_NAMES.includes(model.name))
                    .filter((model) => !model.is_permanently_disabled)
                    .map((model, index, filteredModels) => {
                      const isLast = index === filteredModels.length - 1;
                      return (
                        <div>
                          <ScopeRow
                            model={model}
                            selectedCategory={selectedCategory}
                            updateEnabledStatus={updateEnabledStatus}
                            isDefaultScopesOffFlag={isDefaultOffScopesEnabled}
                            dependentModelScopesFlag={dependentModelScopesFlag}
                          />
                        </div>
                      );
                    })
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-row">
        <LeftSideBar stepNumber="2" isNormalStep={false} />
        <TileStep
          img={<ScopeToggle />}
          title="Update your Scopes"
          subtitle={
            <>
              <p className="mb-2">
                You can update these settings at any time on the{" "}
                <a href={commonModelsConfigurationPathForIndividualCategory(selectedCategory)}>
                  Scopes page
                </a>
                .
              </p>
            </>
          }
          stepNumber={2}
        />
      </div>
    </div>
  );
};

export default ScopesSection;
