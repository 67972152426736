import React from "react";
import { Text, Button, Tooltip, Link } from "@merge-api/merge-javascript-shared";
import {
  LinkedAccount,
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../models/Entities";
import LinkedAccountConditionInputCombo from "./LinkedAccountConditionInputCombo";
import { getFilteredModelOptions } from "./LinkedAccountConditionInputCombo/helpers";
import { Plus } from "lucide-react";

type LinkedAccountConditionsListProps = {
  linkedAccount: LinkedAccount;
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
  linkedAccountConditionErrors: (string | undefined)[];
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  onLinkedAccountConditionAdd: () => void;
  onLinkedAccountConditionDelete: (index: number) => void;
  onLinkedAccountConditionChange: (
    linkedAccountCondition: Partial<LinkedAccountCondition>,
    index: number,
  ) => void;
  showGatedSelectiveSync: boolean;
};

const LinkedAccountConditionsList = ({
  linkedAccount,
  linkedAccountConditions,
  linkedAccountConditionErrors,
  linkedAccountConditionMeta,
  onLinkedAccountConditionAdd,
  onLinkedAccountConditionDelete,
  onLinkedAccountConditionChange: propsOnLinkedAccountConditionChange,
  showGatedSelectiveSync,
}: LinkedAccountConditionsListProps) => {
  // derived state
  const canAddFilter = !!getFilteredModelOptions(
    {},
    linkedAccountConditionMeta,
    linkedAccountConditions,
  ).length;

  // event handlers
  const onLinkedAccountConditionChange =
    (index: number) => (linkedAccountCondition: Partial<LinkedAccountCondition>) => {
      propsOnLinkedAccountConditionChange(linkedAccountCondition, index);
    };

  return (
    <>
      <Text>
        Only sync {linkedAccount.end_user.organization_name} data from{" "}
        {linkedAccount.integration?.name} where...
      </Text>

      {linkedAccountConditions.map((linkedAccountCondition, index) => {
        // derive key from common_model + normalized_key_name in the
        // event that it is a new condition preset, which wont have an id
        const linkedAccountConditionKey =
          linkedAccountCondition.id ||
          `${linkedAccountCondition.common_model}-${linkedAccountCondition.normalized_key_name}-${index}`;

        return (
          <LinkedAccountConditionInputCombo
            key={linkedAccountConditionKey}
            integrationName={linkedAccount.integration.name}
            linkedAccountCondition={linkedAccountCondition}
            errorText={linkedAccountConditionErrors[index]}
            linkedAccountConditionMeta={linkedAccountConditionMeta}
            linkedAccountConditions={linkedAccountConditions}
            onLinkedAccountConditionChange={onLinkedAccountConditionChange(index)}
            onLinkedAccountConditionDelete={() => onLinkedAccountConditionDelete(index)}
            isFirst={index === 0}
            showGatedSelectiveSync={showGatedSelectiveSync}
          />
        );
      })}

      <Tooltip
        title={
          showGatedSelectiveSync ? (
            <Text variant="sm">
              <Link href="/billing" className="text-white underline">
                Upgrade your plan{" "}
              </Link>
              to configure Selective Sync in Link
            </Text>
          ) : (
            !canAddFilter && "No more filters available without creating duplicates"
          )
        }
      >
        <Button
          onClick={onLinkedAccountConditionAdd}
          disabled={!canAddFilter}
          leftIcon={<Plus size={16} />}
        >
          Filter
        </Button>
      </Tooltip>
    </>
  );
};

export default LinkedAccountConditionsList;
