import React from "react";
import { LinkedAccount, LinkedAccountConfigurationCounts } from "../../../../../../models/Entities";
import clsx from "clsx";
import RedactUnmappedDataWidgetCard from "./components/RedactUnmappedDataWidgetCard";
import { ChevronRight } from "lucide-react";
import SkeletonLoader from "../../../../../shared/SkeletonLoader";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import { useRequest } from "../../../../../shared/hooks/useRequest";
import SelectiveSyncWidgetCard from "./components/SelectiveSyncWidgetCard";
import WebhookReceiversWidgetCard from "./components/WebhookReceiversWidgetCard";
import CommonModelOverridesWidgetCard from "./components/CommonModelOverridesWidgetCard";
import FieldMappingsWidgetCard from "./components/FieldMappingsWidgetCard";
import { SetOpenDropdownType } from "../types";

type Props = {
  linkedAccount: LinkedAccount | null;
  shouldShowWebhooksTab: boolean;
  shouldShowFieldMappingsTab: boolean;
  shouldShowRedactUnmappedDataRow: boolean;
  shouldShowSelectiveSyncTab: boolean;
  className?: string;
  openDropdown: string | null;
  setOpenDropdown: SetOpenDropdownType;
};
const LinkedAccountConfigurationCard = ({
  linkedAccount,
  shouldShowWebhooksTab,
  shouldShowFieldMappingsTab,
  shouldShowRedactUnmappedDataRow,
  shouldShowSelectiveSyncTab,
  className,
  openDropdown,
  setOpenDropdown,
}: Props) => {
  // hooks
  const {
    data: linkedAccountConfigurationCounts,
    loading: linkedAccountConfigurationCountsLoading,
  } = useRequest<LinkedAccountConfigurationCounts>({
    path: `integrations/linked-accounts/${linkedAccount?.id}/configuration-counts`,
    skip: !linkedAccount,
    errorText: "Error fetching linked account configuration counts",
  });

  // derived state
  const {
    linked_account_field_mappings_count,
    org_wide_field_mappings_count,
    linked_account_common_model_overrides_count,
    org_wide_common_model_overrides_count,
    active_webhook_receivers_count,
    selective_sync_filters_count,
  } = linkedAccountConfigurationCounts || {};

  // skeleton
  if (!linkedAccount || linkedAccountConfigurationCountsLoading) {
    return (
      <Card className={clsx(className, "overflow-hidden")}>
        <div className="flex flex-col gap-2 bg-gray-0 p-2">
          <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
            <SkeletonLoader width={128} height={12} borderRadius={2} className="my-[5px]" />
            <ChevronRight size={12} />
          </div>
          <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
            <SkeletonLoader width={80} height={12} borderRadius={2} className="my-[5px]" />
            <ChevronRight size={12} />
          </div>
          <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
            <SkeletonLoader width={112} height={12} borderRadius={2} className="my-[5px]" />
            <ChevronRight size={12} />
          </div>
          <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
            <SkeletonLoader width={112} height={12} borderRadius={2} className="my-[5px]" />
            <ChevronRight size={12} />
          </div>
          <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
            <SkeletonLoader width={112} height={12} borderRadius={2} className="my-[5px]" />
            <ChevronRight size={12} />
          </div>
          <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
            <SkeletonLoader width={112} height={12} borderRadius={2} className="my-[5px]" />
            <ChevronRight size={12} />
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className={clsx(className, "overflow-hidden")}>
      <div className="w-full flex flex-col">
        {/* header */}
        <div className="px-4 py-3 border-b-solid border-b-gray-10 border-b-[1px]">
          <Text variant="h5">Configuration</Text>
        </div>

        {/* body */}
        <div className="p-4">
          {/* Field Mapping */}
          {shouldShowFieldMappingsTab && (
            <>
              <FieldMappingsWidgetCard
                linkedAccountId={linkedAccount.id}
                linkedAccountFieldMappingsCount={linked_account_field_mappings_count}
                orgWideFieldMappingsCount={org_wide_field_mappings_count}
              />
              <hr className="border-gray-10" />
            </>
          )}

          {/* Common model overrides */}
          {shouldShowFieldMappingsTab && (
            <>
              <CommonModelOverridesWidgetCard
                linkedAccountId={linkedAccount.id}
                linkedAccountCommonModelOverridesCount={linked_account_common_model_overrides_count}
                orgWideCommonModelOverridesCount={org_wide_common_model_overrides_count}
              />
              {(shouldShowWebhooksTab ||
                shouldShowSelectiveSyncTab ||
                shouldShowRedactUnmappedDataRow) && <hr className="border-gray-10" />}
            </>
          )}

          {/* Webhook Receivers */}
          {shouldShowWebhooksTab && (
            <>
              <WebhookReceiversWidgetCard
                linkedAccountId={linkedAccount.id}
                activeWebhookReceiversCount={active_webhook_receivers_count}
              />
              {(shouldShowSelectiveSyncTab || shouldShowRedactUnmappedDataRow) && (
                <hr className="border-gray-10" />
              )}
            </>
          )}

          {/* Selective Sync */}
          {shouldShowSelectiveSyncTab && (
            <>
              <SelectiveSyncWidgetCard
                linkedAccountId={linkedAccount.id}
                selectiveSyncFiltersCount={selective_sync_filters_count}
              />
              {shouldShowRedactUnmappedDataRow && <hr className="border-gray-10" />}
            </>
          )}

          {/* Redact unmapped data */}
          {shouldShowRedactUnmappedDataRow && (
            <RedactUnmappedDataWidgetCard
              linkedAccount={linkedAccount}
              openDropdown={openDropdown}
              setOpenDropdown={setOpenDropdown}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default LinkedAccountConfigurationCard;
