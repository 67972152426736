import { useEffect, useRef, useState, useCallback } from "react";
import type {
  InkeepJS,
  InkeepComponentInstance,
  Style,
  InkeepBaseSettings,
  InkeepAIChatSettings,
  InkeepSearchSettings,
} from "@inkeep/cxkit-types";

declare global {
  interface Window {
    Inkeep: InkeepJS;
  }
}

const INTRO_MESSAGE = `Hi! I'm an AI assistant, ask me anything about Merge.`;

const baseSettings: InkeepBaseSettings = {
  apiKey: process.env.REACT_APP_INKEEP_API_KEY!,
  organizationDisplayName: "Merge",
  primaryBrandColor: "#FFFFFF",
  customIcons: {
    switchToChat: { custom: "/inkeep/askAi-icon-black.svg" },
    chatSubmit: { custom: "/inkeep/send-chat-icon.svg" },
  },
  theme: {
    styles: [
      {
        key: "1",
        type: "link",
        value: "/inkeep/inkeep.css",
      },
    ] as Style[],
  },
};

const aiChatSettings: InkeepAIChatSettings = {
  aiAssistantAvatar: "/inkeep/askAi-icon-blue.svg",
  userAvatar: "/inkeep/user-icon-ai-chat.svg",
  introMessage: INTRO_MESSAGE,
};

const searchSettings: InkeepSearchSettings = {
  tabs: [],
  placeholder: "Search docs...",
  shouldOpenLinksInNewTab: true,
};

const inkeepProps = {
  baseSettings: baseSettings,
  aiChatSettings: aiChatSettings,
  searchSettings: searchSettings,
};

type InkeepModalProps = {
  showInkeepModal: boolean;
  setShowInkeepModal: any;
};

const InkeepModal = ({ showInkeepModal, setShowInkeepModal }: InkeepModalProps) => {
  const CustomTriggerRef = useRef<InkeepComponentInstance | null>(null);

  const handleOpenChange = useCallback((newOpen: boolean) => {
    setShowInkeepModal(newOpen);
  }, []);

  useEffect(() => {
    if (CustomTriggerRef != null && window?.Inkeep?.ModalSearchAndChat) {
      CustomTriggerRef.current = window.Inkeep.ModalSearchAndChat({
        ...inkeepProps,
        forceDefaultView: true,
        defaultView: "chat",
        modalSettings: {
          isOpen: showInkeepModal,
          onOpenChange: handleOpenChange,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (CustomTriggerRef.current) {
      CustomTriggerRef.current.update({ modalSettings: { isOpen: showInkeepModal } });
    }
  }, [showInkeepModal]);

  // need to return null, since Inkeep.ModalSearchAndChat doesn't return anything (just renders on the screen)
  return null;
};
export default InkeepModal;
