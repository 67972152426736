import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import useAppContext from "../../../../../context/useAppContext";
import {
  getCommonModelOverrideIntegrationInformation,
  getCommonModelOverrideTarget,
} from "../../utils/FieldMappingUtils";
import MergeTable from "../../../../../shared/MergeTable";
import CommonModelOverrideByIntegrationRow from "./CommonModelOverrideByIntegrationRow";
import {
  OverriddenCommonModelInstance,
  OverriddenCommonModelTarget,
  OverrideCommonModelIntegrationInfo,
} from "../../../../../../models/Entities";
import { showErrorToast } from "../../../../../shared/Toasts";
import {
  Button,
  ButtonVariant,
  Card,
  Link,
  TextFieldVariant,
  Typeahead,
  Text,
} from "@merge-api/merge-javascript-shared";
import { navigateToFieldMappings } from "../../../../../../router/RouterUtils";
import { ArrowLeft } from "lucide-react";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../router/RouterUtils";
import FieldMappingHeader from "../../components/FieldMappingHeader";

const filterAvailableIntegrations = (availableIntegrations: any, selectedSearchOption: any) => {
  if (selectedSearchOption == "") {
    return availableIntegrations;
  }
  return availableIntegrations.filter(
    (availableIntegration: any) =>
      availableIntegration.integration.name.includes(selectedSearchOption) ||
      (
        availableIntegration?.integration?.common_model_override_instance?.display_name ?? ""
      ).includes(selectedSearchOption) ||
      (
        availableIntegration?.integration?.common_model_override_instance?.remote_endpoint_path ??
        ""
      )?.includes(selectedSearchOption),
  );
};

type Props = {
  id: string;
};

const ConfigurationCommonModelOverridesSettings = () => {
  const { id } = useParams<Props>();
  const [availableIntegrations, setAvailableIntegrations] = useState<
    OverrideCommonModelIntegrationInfo[] | undefined
  >(undefined);
  const [commonModelTarget, setCommonModelTarget] = useState<
    OverriddenCommonModelTarget | undefined
  >(undefined);
  const [selectedSearchOption, setSelectedSearchOption] = useState<string>("");
  const { user } = useAppContext();
  const history = useHistory();

  const tableHeaders = (
    <>
      <th scope="col" className="pl-6">
        Integration
      </th>
      <th scope="col">Remote Field</th>
      <th scope="col">Endpoint</th>
      <th scope="col" className="pr-6"></th>
    </>
  );
  useEffect(() => {
    getCommonModelOverrideIntegrationInformation(
      id,
      (data) => {
        setAvailableIntegrations(data);
      },
      () => {
        showErrorToast("Not able to fetch override integration information for target");
      },
    );

    getCommonModelOverrideTarget(
      id,
      (data) => {
        setCommonModelTarget(data);
      },
      () => {
        showErrorToast("Not able to fetch target");
      },
    );
  }, [id]);

  const deleteOverrideModelInstance = (integrationID: string) => {
    setAvailableIntegrations((prevIntegrations) => {
      if (prevIntegrations) {
        return prevIntegrations.map((integrationInfo) => {
          const integrationData = integrationInfo.integration;
          return integrationData.id === integrationID
            ? {
                integration: integrationData,
                common_model_override_instance: null,
              }
            : integrationInfo;
        });
      }
      return undefined;
    });
  };

  const updateCommonModelInstance = (
    integrationID: string,
    updatedInstance: OverriddenCommonModelInstance,
  ) => {
    setAvailableIntegrations((prevIntegrations) => {
      if (prevIntegrations) {
        return prevIntegrations.map((integrationInfo) => {
          const integrationData = integrationInfo.integration;
          return integrationData.id === integrationID
            ? {
                integration: integrationData,
                common_model_override_instance: updatedInstance,
              }
            : integrationInfo;
        });
      }
      return undefined;
    });
  };

  const filteredAvailableIntegrations = availableIntegrations
    ? filterAvailableIntegrations(availableIntegrations, selectedSearchOption)
    : undefined;
  const rowData =
    commonModelTarget &&
    filteredAvailableIntegrations &&
    filteredAvailableIntegrations.map((availableIntegration: any) => {
      const integration = availableIntegration["integration"];
      const commonModelInstance = availableIntegration["common_model_override_instance"];
      return (
        <CommonModelOverrideByIntegrationRow
          commonModelOverrideTargetID={id}
          commonModelOverrideTargetType={
            commonModelTarget.overridden_field_definition?.type || null
          }
          commonModelOverrideFormat={commonModelTarget.overridden_field_definition?.format || null}
          integrationID={integration.id}
          organizationID={user.organization.id}
          commonModelOverrideCommonModelName={commonModelTarget.common_model_name}
          name={
            integration.abbreviated_name != null && integration.abbreviated_name.trim() !== ""
              ? integration.abbreviated_name
              : integration.name
          }
          squareImage={integration?.square_image || undefined}
          commonModelInstanceID={commonModelInstance ? commonModelInstance.id : null}
          remoteEndpointPath={commonModelInstance ? commonModelInstance.remote_endpoint_path : null}
          setAvailableIntegrations={setAvailableIntegrations}
          fieldTraversalPath={commonModelInstance ? commonModelInstance.field_traversal_path : null}
          deleteInstance={deleteOverrideModelInstance}
          updateCommonModelInstance={updateCommonModelInstance}
          isRemoteDataEnabled={commonModelTarget.is_remote_data_enabled}
        />
      );
    });

  const options: string[] = [];
  if (filteredAvailableIntegrations) {
    filteredAvailableIntegrations.forEach((availableIntegration: any) => {
      options.push(availableIntegration?.integration.name);
      if (availableIntegration?.integration?.common_model_override_instance?.display_name != null) {
        options.push(availableIntegration?.common_model_override_instance?.display_name);
      }
      if (
        availableIntegration?.integration.common_model_override_instance?.remote_endpoint_path !=
        null
      ) {
        options.push(
          availableIntegration?.integration.common_model_override_instance?.remote_endpoint_path,
        );
      }
    });
  }

  options.sort();

  return (
    <>
      <div className="flex flex-col gap-y-6 mb-6">
        <Button
          variant={ButtonVariant.TextBlue}
          onClick={() => navigateToFieldMappings(history)}
          leftIcon={<ArrowLeft size={14} />}
        >
          Configure organization-wide Field Mappings
        </Button>
        {commonModelTarget && (
          <FieldMappingHeader
            targetId={commonModelTarget.id}
            title={commonModelTarget.overridden_field}
            commonModel={commonModelTarget?.common_model_name}
            category={commonModelTarget?.category}
            description={commonModelTarget.overridden_field_definition?.description ?? ""}
            isOverride
          />
        )}
        <Card className="flex flex-column pt-5 gap-y-5" size="lg">
          <div className="gap-y-2 px-6">
            <Text variant="h5" className="mb-2">
              Overrides by integration
            </Text>
            {commonModelTarget && commonModelTarget?.is_remote_data_enabled ? (
              <>
                Create Field Mappings that apply across all Linked Accounts of an integration for
                this field. Any changes to data made here will be updated in each Linked Account’s
                next sync.
              </>
            ) : (
              <>
                <Link href={`${CONFIGURATION_COMMON_MODELS_PATH}/${commonModelTarget?.category}`}>
                  <span> Enable Remote Data </span>
                </Link>
                {"  "}
                <span>
                  to create new Field Mappings across all Linked Accounts of an integration for this
                  target field
                </span>
              </>
            )}
          </div>
          <div className="px-6">
            <Typeahead
              showSearchIcon
              borderVariant={TextFieldVariant.Bordered}
              value={selectedSearchOption === "" ? null : selectedSearchOption}
              options={options}
              onChange={(_, selected: any) => {
                setSelectedSearchOption(selected ?? "");
              }}
              placeholder="Search integrations, mappings, or endpoints..."
            />
          </div>
          {availableIntegrations && (
            <MergeTable
              header={tableHeaders}
              content={rowData}
              hasMarginBottom={false}
              isOverviewPage
            />
          )}
        </Card>
      </div>
    </>
  );
};
export default ConfigurationCommonModelOverridesSettings;
