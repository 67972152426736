import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Volume2, VolumeX } from "lucide-react";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { IntegrationIssue } from "../../IntegrationsManagementEntities";
import { muteIntegrationIssue } from "../../utils/IntegrationsManagementAPIClient";
import { isIssueMuted } from "../../utils/IntegrationsManagementUtils";
import DatePicker from "react-datepicker";
import { Button, ButtonVariant, Dialog, Menu, MenuItem } from "@merge-api/merge-javascript-shared";

interface Props {
  integrationIssue: IntegrationIssue;
  onIssueUpdate: (issue: IntegrationIssue) => void;
}

const IndividualIssueMuteButton = ({ integrationIssue, onIssueUpdate }: Props) => {
  const [issue, setIssue] = useState<IntegrationIssue>(integrationIssue);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [displayMuteDateModal, setDisplayMuteDateModal] = useState(false);
  const [displayCountModal, setDisplayCountModal] = useState(false);

  const isMuted = isIssueMuted(issue);

  function dateInXHours(x: number) {
    const now = new Date();
    now.setHours(now.getHours() + x);
    return now;
  }

  const muteIssue = ({
    muted_until,
    mute_incident_count,
  }: {
    muted_until?: Date;
    mute_incident_count?: number;
  }) => {
    setUpdateLoading(true);
    muteIntegrationIssue({
      integrationIssueID: integrationIssue.id,
      muted_until: muted_until,
      mute_incident_count: mute_incident_count,
      onSuccess: (updatedIssue) => {
        setIssue(updatedIssue);
        onIssueUpdate(updatedIssue);
        setUpdateLoading(false);
        showSuccessToast(`${isMuted ? "Unmuted" : "Muted"} Issue.`);
      },
      onFailure: () => {
        setUpdateLoading(false);
        showErrorToast(`Failed to update Issue`);
      },
    });
  };

  function muteAndCloseModal(selected_date: Date | undefined | null) {
    setDisplayMuteDateModal(false);
    if (selected_date) {
      muteIssue({ muted_until: selected_date });
    }
  }

  return (
    <>
      {isMuted ? (
        <Button
          size="sm"
          variant={ButtonVariant.TertiaryWhite}
          onClick={() => {
            muteIssue({
              muted_until: undefined,
              mute_incident_count: undefined,
            });
          }}
          loading={updateLoading}
          leftIcon={updateLoading ? undefined : <Volume2 size={12} />}
        >
          {updateLoading ? "Updating" : "Unmute"}
        </Button>
      ) : (
        <>
          <Menu
            ButtonProps={{
              variant: ButtonVariant.TertiaryWhite,
              children: "Mute issue",
              size: "sm",
              leftIcon: <VolumeX size={12} />,
            }}
            menuPlacement="bottom-end"
          >
            <MenuItem
              onClick={() => {
                muteIssue({ muted_until: dateInXHours(24 * 360 * 100) });
              }}
            >
              Forever
            </MenuItem>

            <MenuItem
              onClick={() => {
                muteIssue({ muted_until: dateInXHours(24) });
              }}
            >
              For a day
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplayMuteDateModal(true);
              }}
            >
              For a custom amount of time
            </MenuItem>

            <MenuItem
              onClick={() => {
                muteIssue({ mute_incident_count: 1 });
              }}
            >
              Until this occurs one more time
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplayCountModal(true);
              }}
            >
              Until this occurs a custom amount
            </MenuItem>
          </Menu>
          <CustomMuteDateModal
            isShown={displayMuteDateModal}
            setIsShown={setDisplayMuteDateModal}
            onSelect={(selectedDate) => {
              muteAndCloseModal(selectedDate);
            }}
          />
          <CustomIncidentCountModal
            isShown={displayCountModal}
            setIsShown={setDisplayCountModal}
            onSelect={(selectedCount) => muteIssue({ mute_incident_count: selectedCount })}
          />
        </>
      )}
    </>
  );
};

type countModalProps = {
  isShown: boolean;
  setIsShown: (value: boolean) => void;
  onSelect: (selectedCount: number) => void;
};

const CustomIncidentCountModal = (props: countModalProps) => {
  const [count, setCount] = useState<number | undefined>(1000);
  const [validated, setValidated] = useState(true);

  const updateCount = ({ newVal }: { newVal: any }) => {
    if (newVal == undefined || newVal == "") {
      setCount(undefined);
      setValidated(false);
    } else {
      setValidated(true);
      setCount(Number(newVal));
    }
  };

  const submit = () => {
    if (count) {
      props.onSelect(count);
    }
    props.setIsShown(false);
  };

  return (
    <Dialog
      open={props.isShown}
      onClose={() => {
        props.setIsShown(false);
      }}
      onPrimaryButtonClick={submit}
      onSecondaryButtonClick={() => {
        props.setIsShown(false);
      }}
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
      primaryButtonText="Mute"
      primaryButtonDisabled={!validated}
      secondaryButtonText="Cancel"
      title="Mute for custom incident count"
      variant="sm"
    >
      <InputGroup className="mb-3">
        <FormControl
          type="number"
          name="clicks"
          isValid={validated}
          value={count}
          onChange={(event) => {
            updateCount({ newVal: event.target.value });
          }}
        />
      </InputGroup>
    </Dialog>
  );
};

type dateModalProps = {
  isShown: boolean;
  setIsShown: (value: boolean) => void;
  onSelect: (selectedDate: Date | undefined | null) => void;
};
const CustomMuteDateModal = (props: dateModalProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [calendarDisplayMonth, setCalendarDisplayMonth] = useState(new Date().getMonth());

  const startDate = new Date();

  function onCalendarSelect(date: Date | [Date, Date] | null) {
    if (date == null) {
      setSelectedDate(startDate);
    } else if (date instanceof Array) {
      setSelectedDate(date[0]);
    } else {
      setSelectedDate(date);
    }
  }

  return (
    <>
      <Dialog
        open={props.isShown}
        onClose={() => {
          props.setIsShown(false);
        }}
        onPrimaryButtonClick={() => {
          props.onSelect(selectedDate);
        }}
        primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
        primaryButtonText="Mute"
        primaryButtonDisabled={selectedDate == undefined || selectedDate == null}
        onSecondaryButtonClick={() => {
          props.setIsShown(false);
        }}
        secondaryButtonText="Cancel"
        title="Select when to mute"
        variant="sm"
      >
        <DatePicker
          calendarClassName="date-picker-font"
          className="w-100 input-group-text date-picker"
          placeholderText="Select date"
          selected={selectedDate}
          onChange={(date) => onCalendarSelect(date)}
          selectsEnd
          startDate={startDate}
          endDate={selectedDate}
          minDate={startDate}
          onMonthChange={(date) => setCalendarDisplayMonth(date.getMonth())}
          dayClassName={(date) =>
            date.getMonth() !== calendarDisplayMonth && date < startDate ? "day-disabled" : ""
          }
        />
      </Dialog>
    </>
  );
};

export default IndividualIssueMuteButton;
