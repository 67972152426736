import React, { useState } from "react";
import { Card, Link, Text } from "@merge-api/merge-javascript-shared";
import { APICategory, LinkedAccount } from "../../../../../models/Entities";
import useLoadLinkedAccountConditionData from "./hooks/useLoadLinkedAccountConditionData";
import LinkedAccountConditionsList from "./components/LinkedAccountConditionsList";
import useManageLinkedAccountConditions from "./hooks/useManageLinkedAccountConditions";
import LinkedAccountConditionHeader from "./components/LinkedAccountConditionHeader";
import LinkedAccountConditionPublishChangesDialog from "./components/LinkedAccountConditionPublishChangesDialog";
import LinkedAccountConditionConfirmDiscardChangesDialog from "./components/LinkedAccountConditionConfirmDiscardChangesDialog";
import LinkedAccountFileStorageFiltersTable from "./components/LinkedAccountFileStorageFiltersTable";
import useLoadLinkedAccountFileStorageFilters from "./hooks/useLoadLinkedAccountFileStorageFilters";
import useAppContext from "../../../../context/useAppContext";
import { BillingPlanTier } from "../../../settings/billing/BillingModels";
import { MergeFlagFeature, useMergeFlag } from "../../../../shared/hooks/useMergeFlag";
import EmptyStateWrapper from "../../../../shared/EmptyStateWrapper";
import SkeletonLoader from "../../../../shared/SkeletonLoader";
import { isEmpty } from "lodash";

type LinkedAccountSelectiveSyncPageProps = {
  linkedAccount: LinkedAccount;
};

const LinkedAccountSelectiveSyncPage = ({ linkedAccount }: LinkedAccountSelectiveSyncPageProps) => {
  const { user } = useAppContext();
  // state
  const [publishChangesDialogOpen, setPublishChangesDialogOpen] = useState(false);

  // hooks
  const { loading, remoteLinkedAccountConditions, linkedAccountConditionMeta, refetch } =
    useLoadLinkedAccountConditionData({ linkedAccount });
  const {
    linkedAccountConditions,
    linkedAccountConditionErrors,
    onLinkedAccountConditionChange,
    onLinkedAccountConditionDelete,
    onLinkedAccountConditionAdd,
    publishChanges,
    changes,
    publishing,
    hasChanges,
    validateChanges,
  } = useManageLinkedAccountConditions({
    remoteLinkedAccountConditions,
    linkedAccountConditionMeta,
    linkedAccountId: linkedAccount.id,
    refetch: () => {
      refetch();
      setPublishChangesDialogOpen(false);
    },
  });
  const { linkedAccountFileStorageFilters } = useLoadLinkedAccountFileStorageFilters({
    linkedAccount,
  });

  // event handlers
  const onPublishChangesClick = () => {
    const hasErrors = validateChanges();

    if (hasErrors) return;
    setPublishChangesDialogOpen(true);
  };

  // derived state
  const showFileStorageSelectiveSync =
    linkedAccount.category === APICategory.filestorage &&
    user.is_file_storage_selective_sync_enabled;

  // this covers the non enterprise customers who were on the s2 flag
  const { enabled: selectiveSyncEnabled } = useMergeFlag({
    feature: MergeFlagFeature.ENABLE_SELECTIVE_SYNC,
    organizationId: user.organization.id,
  });

  const showGatedSelectiveSync =
    !selectiveSyncEnabled &&
    user.organization.organization_billing_plan?.billing_plan.plan_tier !==
      BillingPlanTier.BILLING_PLAN_TIER_ENTERPRISE &&
    user.organization.organization_billing_plan?.billing_plan.plan_tier !==
      BillingPlanTier.BILLING_PLAN_TIER_EXPAND;

  const hasDefaultFiltersIfGatedSelectiveSync =
    showGatedSelectiveSync &&
    remoteLinkedAccountConditions &&
    remoteLinkedAccountConditions.length > 0;

  return (
    <>
      <LinkedAccountConditionHeader
        hasChanges={hasChanges}
        onPublishChangesClick={onPublishChangesClick}
        category={linkedAccount.category}
        linkedAccount={linkedAccount}
      >
        {showFileStorageSelectiveSync ? (
          // Includes empty state and loading state for file storage table
          <LinkedAccountFileStorageFiltersTable
            linkedAccountFileStorageFilters={linkedAccountFileStorageFilters}
          />
        ) : (
          <Card>
            <div className="flex px-6 py-5 border-b border-gray-10">
              <Text variant="h4">Filters</Text>
            </div>
            <div className="flex flex-col pt-5 px-6 pb-6 gap-y-6">
              {loading || !linkedAccountConditionMeta ? (
                <>
                  <SkeletonLoader height={35} fullWidth borderRadius={4} />
                  <SkeletonLoader height={35} fullWidth borderRadius={4} />
                  <SkeletonLoader height={35} fullWidth borderRadius={4} />
                </>
              ) : showGatedSelectiveSync && !hasDefaultFiltersIfGatedSelectiveSync ? (
                <EmptyStateWrapper
                  title="No filters available"
                  subtitle={
                    <>
                      <Link href="/billing" className="text-blue">
                        Upgrade your plan{" "}
                      </Link>
                      to add filters
                    </>
                  }
                />
              ) : linkedAccountConditionMeta && !isEmpty(linkedAccountConditionMeta) ? (
                <LinkedAccountConditionsList
                  linkedAccount={linkedAccount}
                  linkedAccountConditions={linkedAccountConditions}
                  linkedAccountConditionErrors={linkedAccountConditionErrors}
                  linkedAccountConditionMeta={linkedAccountConditionMeta}
                  onLinkedAccountConditionChange={onLinkedAccountConditionChange}
                  onLinkedAccountConditionDelete={onLinkedAccountConditionDelete}
                  onLinkedAccountConditionAdd={onLinkedAccountConditionAdd}
                  showGatedSelectiveSync={showGatedSelectiveSync}
                />
              ) : (
                <EmptyStateWrapper title="No filters have been set for this Linked Account" />
              )}
            </div>
          </Card>
        )}
      </LinkedAccountConditionHeader>

      <LinkedAccountConditionConfirmDiscardChangesDialog hasChanges={hasChanges} />

      <LinkedAccountConditionPublishChangesDialog
        organizationName={linkedAccount.end_user.organization_name}
        changes={changes}
        open={publishChangesDialogOpen}
        onClose={() => setPublishChangesDialogOpen(false)}
        publishChanges={publishChanges}
        publishing={publishing}
      />
    </>
  );
};

export default LinkedAccountSelectiveSyncPage;
