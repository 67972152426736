import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { Link, Text } from "@merge-api/merge-javascript-shared";
import { useHistory, Redirect } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import MergeLogo from "../../../../assets/svg/logos/merge/logo.svg";
import { signupTenantOptions } from "../../../../constants";
import hubspotSignUp from "./HubspotSignUp";

import {
  fetchWithoutAuth,
  UserSuccessData,
  FormErrorData,
  setAuthTokenAndUserType,
  hasAuthToken,
  SSOLoginData,
  getTenancy,
  Tenancy,
} from "../../../../api-client/APIClient";
import {
  LANDING_PAGE_PATH,
  LOGIN_PATH,
  navigateToHomePage,
  SUBSCRIBER_AGREEMENT_PATH,
  PRIVACY_POLICY_PATH,
  useQuery,
  navigateToSignUpPage,
} from "../../../../router/RouterUtils";
import { User } from "../../../../models/Entities";
import { AuthLayout } from "../AuthLayouts";
import SignInWithGoogleLink from "./SignInWithButtons";
import SpinnerButton from "../../../shared/SpinnerButton";
import { REGEX_EMAIL_ADDRESS } from "../../../shared/utils/SharedComponentUtils";
import FinishSocialSignUp from "./FinishSocialSignUp";
import { showErrorToast } from "../../../shared/Toasts";

import {
  STANDARD_SIGNUP_FORM_ID,
  INVITE_SIGNUP_FORM_ID,
  EU_STANDARD_SIGNUP_FORM_ID,
  EU_INVITE_SIGNUP_FORM_ID,
  EU_SIGNUP_PRE_EMAIL_CONFIRMATION_FORM_ID,
  STANDARD_SIGNUP_PRE_EMAIL_CONFIRMATION_FORM_ID,
} from "./HubspotForms";
import useSSOFeatureFlags from "../saml/SamlUtils";
import HorizontalRuleWithText from "../shared/HorizontalRuleWithText";

import CustomSelect from "../CustomSelect"; // Import the CustomSelect component

const StyledInput = styled.input`
  && {
    background-color: #ffffff;
    border: 0.9375px solid #111317;
    border-radius: 3.75px;

    :checked {
      background-color: #111317;
    }
    :checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
`;
interface OrgForInviteToken {
  has_saml_provider: boolean;
  saml_is_required: boolean;
  user_with_email_exists: boolean;
  name: string;
}

interface FormData {
  name: string;
  organization?: string;
  email: string;
  password?: string;
  agreed_to_terms_of_service_and_read_privacy_policy: boolean;
}

export interface SignUpUserData extends FormData {
  use_saml?: boolean;
  email_token?: string | null;
  social_auth_provider?: string | null;
  id_token?: string | null;
}

const parseJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    );

    return JSON.parse(jsonPayload);
  } catch (_) {
    return undefined;
  }
};

type Props = {
  setUser: (user: User) => void;
};

const SignUpScreen = ({ setUser }: Props) => {
  const history = useHistory();
  const query = useQuery();
  const idToken = query.get("id_token");
  const parsedJWT = idToken && parseJWT(idToken);
  const email = parsedJWT
    ? parsedJWT["email_verified"] && parsedJWT["email"] === query.get("email") && query.get("email")
    : query.get("email");
  const name = parsedJWT?.["name"];
  const inviteToken = query.get("token");
  const createUserToken = query.get("create_user_token");
  const socialAuthProvider = query.get("social_auth_provider");
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [isErrorShown, setIsErrorShown] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isSamlRequired, setIsSamlRequired] = useState<boolean>(false);
  const isEUMultiTenant = getTenancy() === Tenancy.EUMultiTenant;
  const isAPAMultiTenant = getTenancy() === Tenancy.APMultiTenant;
  const isSingleTenant = getTenancy() == Tenancy.SingleTenant;
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const hasSocialAuthData = Boolean(socialAuthProvider && idToken && email);
  const { isGoogleSSODisabled } = useSSOFeatureFlags();
  const hideSignInWithGoogle = Boolean(hasSocialAuthData || isSamlRequired || isGoogleSSODisabled);
  const [backendFieldValidationError, setBackendFieldValidationError] = useState<string>("");
  const selectedOptionValue =
    signupTenantOptions[isEUMultiTenant ? "eu" : isAPAMultiTenant ? "apac" : "usa"];

  let createAccountButtonGTag = "";
  let hubspotSignupFormID = "";

  if (isEUMultiTenant) {
    if (inviteToken) {
      createAccountButtonGTag = "gtag-create-account-invite_eu";
    } else {
      if (isSamlRequired) {
        createAccountButtonGTag = "gtag-create-account-with-sso_eu";
      } else {
        createAccountButtonGTag = "gtag-create-account_eu";
      }
    }
    hubspotSignupFormID = EU_INVITE_SIGNUP_FORM_ID;
  } else {
    if (inviteToken) {
      createAccountButtonGTag = "gtag-create-account-invite";
    } else {
      if (isSamlRequired) {
        createAccountButtonGTag = "gtag-create-account-with-sso";
      } else {
        createAccountButtonGTag = "gtag-create-account";
      }
      hubspotSignupFormID = INVITE_SIGNUP_FORM_ID;
    }
  }

  const isUnknownBackendError = (errorMessage: string): boolean => {
    const fieldNames = ["company", "email", "password", "agree"];
    return !fieldNames.some((fieldName) => errorMessage.toLowerCase().includes(fieldName));
  };

  const { register, handleSubmit, setError, errors } = useForm();
  const handleSuccessfulSignUp = useCallback(
    (data: UserSuccessData, hubspotFormId: string) => {
      setAuthTokenAndUserType(data.token, data.user.type);
      setUser(data.user);
      navigateToHomePage(history);
      setIsLoading(false);
      hubspotSignUp(
        {
          name: data.user.name,
          organization: data.user.organization.name,
          email: data.user.email,
        },
        hubspotFormId,
      );
    },
    [history, setUser],
  );

  const performSignUp = (data: FormData) => {
    setIsLoading(true);
    if (inviteToken) {
      const userInfo: SignUpUserData = {
        name: data.name,
        organization: data.organization,
        email: data.email,
        password: data.password,
        email_token: inviteToken,
        agreed_to_terms_of_service_and_read_privacy_policy:
          data.agreed_to_terms_of_service_and_read_privacy_policy,
        use_saml: isSamlRequired,
      };
      fetchWithoutAuth({
        path: "/users/create",
        method: "POST",
        body: userInfo,
        onResponse: (data: Partial<UserSuccessData & SSOLoginData>) => {
          if (data.sso_url) {
            const ssoData = data as SSOLoginData;
            window.location.href = ssoData.sso_url;
            return;
          }
          handleSuccessfulSignUp(data as UserSuccessData, hubspotSignupFormID);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  if (isUnknownBackendError(data[field_name][0])) {
                    setIsErrorShown(data[field_name][0]);
                  } else {
                    setBackendFieldValidationError(data[field_name][0]);
                  }
                  continue;
                }
                setError(field_name, { message: data[field_name][0] });
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    } else {
      const userInfo: SignUpUserData = {
        name: data.name,
        organization: data.organization,
        email: data.email,
        password: data.password,
        agreed_to_terms_of_service_and_read_privacy_policy:
          data.agreed_to_terms_of_service_and_read_privacy_policy,
      };
      fetchWithoutAuth({
        path: "/users/create/send-token",
        method: "POST",
        body: userInfo,
        onResponse: () => {
          setIsLoading(false);
          setIsEmailSent(true);
          const formId = isEUMultiTenant
            ? EU_SIGNUP_PRE_EMAIL_CONFIRMATION_FORM_ID
            : STANDARD_SIGNUP_PRE_EMAIL_CONFIRMATION_FORM_ID;

          hubspotSignUp(
            {
              name: userInfo.name,
              organization: userInfo.organization,
              email: userInfo.email,
            },
            formId,
          );
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  if (isUnknownBackendError(data[field_name][0])) {
                    setIsErrorShown(data[field_name][0]);
                  } else {
                    setBackendFieldValidationError(data[field_name][0]);
                  }
                  continue;
                }
                setError(field_name, { message: data[field_name][0] });
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    }
  };

  const finishAccountCreation = useCallback(
    (createUserToken: string) => {
      fetchWithoutAuth({
        path: "/users/create/finish",
        method: "POST",
        body: { token: createUserToken },
        onResponse: (data: UserSuccessData) => {
          if (isEUMultiTenant) {
            handleSuccessfulSignUp(data, EU_STANDARD_SIGNUP_FORM_ID);
          } else {
            handleSuccessfulSignUp(data, STANDARD_SIGNUP_FORM_ID);
          }
        },
        onError: () => {
          navigateToSignUpPage(history);
          setIsErrorShown("Token was invalid, please try again.");
        },
      });
    },
    [handleSuccessfulSignUp, history, isEUMultiTenant],
  );

  useEffect(() => {
    if (createUserToken) {
      return finishAccountCreation(createUserToken);
    }
    if (inviteToken) {
      setIsLoading(true);
      fetchWithoutAuth({
        path: `/organizations/users/invites/tokens/${inviteToken}`,
        method: "GET",
        onResponse: (data: OrgForInviteToken) => {
          if (data.user_with_email_exists) {
            // If an existing user wants to accept an invite to join another org,
            // they need to login first and join from within the app.
            history.replace(LOGIN_PATH);
            return;
          }
          setOrganizationName(data.name);
          setIsSamlRequired(data.saml_is_required);
          setIsLoading(false);
        },
        onError: () => {
          setOrganizationName(null);
          setIsLoading(false);
          setIsErrorShown("Token was invalid, please try again.");
        },
      });
    }
  }, [createUserToken, finishAccountCreation, history, inviteToken]);

  if (hasAuthToken()) {
    return <Redirect to="/" />;
  }

  if (createUserToken) {
    return (
      <AuthLayout
        title="Creating your account..."
        description="Hold tight, this should only take a few seconds— If the page is stuck, then please refresh and try again."
      />
    );
  }

  if (isEmailSent) {
    return (
      <AuthLayout title="Check your email inbox">
        <>
          <p className="text-center">We've sent you an email to complete account creation.</p>
          <p className="text-center">
            If you don't see it immediately, please search your inbox for an email from
            hello@merge.dev, check your spam folder, and, for Gmail users, check autofilter
            locations like the Forums folder.
          </p>
        </>
      </AuthLayout>
    );
  }

  if (hasSocialAuthData) {
    return (
      <FinishSocialSignUp
        email={email as string}
        handleSuccessfulSignUp={handleSuccessfulSignUp}
        idToken={idToken as string}
        inviteToken={inviteToken}
        name={name}
        organizationName={organizationName}
        socialAuthProvider={socialAuthProvider as string}
      />
    );
  }

  return (
    <>
      <div className="SignUp">
        <div
          className={cx(
            "SignUp-header d-flex align-items-center",
            isDesktopOrTablet ? "justify-content-between" : "justify-content-center",
          )}
        >
          <a href={LANDING_PAGE_PATH}>
            <img src={MergeLogo} />
          </a>
        </div>
        <div className="SignUp-form d-flex align-items-center justify-content-center">
          <div
            className={cx(
              "col-12 col-md-5 col-xl-4 rounded-2xl my-24 bg-white border-charcoal-20 border-[1px] drop-shadow-sm px-12 pt-12 max-w-[488px]",
              isDesktopOrTablet ? "pb-6" : "pb-4",
            )}
          >
            <Text variant="h1" className="mb-1">
              Sign up
            </Text>
            <Form.Group
              className={cx("d-flex justify-content-between", isDesktopOrTablet ? "mb-2" : "mb-6")}
            >
              {isDesktopOrTablet && (
                <p className="mb-0">
                  Already have an account? <Link href={LOGIN_PATH}>Sign in</Link>
                </p>
              )}
              {isDesktopOrTablet && !isSingleTenant && (
                <div className="mb-5">
                  <CustomSelect
                    options={signupTenantOptions}
                    selectedOption={selectedOptionValue}
                  />
                </div>
              )}
            </Form.Group>

            <Form
              onSubmit={handleSubmit(performSignUp)}
              id={inviteToken ? "gtag-invite-form" : "gtag-signup-form"}
            >
              {!hideSignInWithGoogle && (
                <>
                  <Form.Group className="mb-8">
                    <SignInWithGoogleLink text="Sign up with Google" />
                  </Form.Group>
                  <Form.Group>
                    <HorizontalRuleWithText text="OR" />
                  </Form.Group>
                </>
              )}
              <Form.Group>
                {isErrorShown && <Alert variant="dark">{isErrorShown}</Alert>}
                <Form.Label className="mb-2" htmlFor="name">
                  Full name
                </Form.Label>
                <Form.Control
                  name="name"
                  id="name"
                  type="text"
                  placeholder="Full name"
                  className={cx("h-[38px]", {
                    "is-invalid": errors.name,
                  })}
                  ref={register({
                    required: true,
                  })}
                  defaultValue={name || undefined}
                  readOnly={!!name}
                  isInvalid={errors.name}
                />
                <Form.Control.Feedback type="invalid">Please enter a name.</Form.Control.Feedback>
              </Form.Group>
              {!inviteToken && (
                <Form.Group>
                  <Form.Label className="mb-2" htmlFor="organization">
                    Company
                  </Form.Label>
                  <Form.Control
                    name="organization"
                    id="organization"
                    type="text"
                    placeholder="Company"
                    className={cx("h-[38px]", {
                      "is-invalid": errors.organization,
                    })}
                    onChange={() => setBackendFieldValidationError("")}
                    isInvalid={
                      errors.organization ||
                      backendFieldValidationError.toLowerCase().includes("company")
                    }
                    ref={register({
                      required: true,
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {backendFieldValidationError.toLowerCase().includes("company")
                      ? backendFieldValidationError
                      : "Please enter a company name."}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label className="mb-2" htmlFor="email">
                  Email
                </Form.Label>
                <Form.Control
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email"
                  className={cx("h-[38px]", { "is-invalid": errors.email })}
                  onChange={() => setBackendFieldValidationError("")}
                  isInvalid={
                    errors.email || backendFieldValidationError.toLowerCase().includes("email")
                  }
                  ref={register({
                    required: true,
                    pattern: REGEX_EMAIL_ADDRESS,
                  })}
                  defaultValue={email || undefined}
                  readOnly={!!email}
                />

                <Form.Control.Feedback type="invalid">
                  {backendFieldValidationError.toLowerCase().includes("email")
                    ? backendFieldValidationError
                    : "Please enter a valid email address."}
                </Form.Control.Feedback>
              </Form.Group>
              {!isSamlRequired && (
                <Form.Group>
                  <Form.Label className="mb-2" htmlFor="password">
                    Password
                  </Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    id="password"
                    placeholder="Password"
                    className={cx("h-[38px]", {
                      "is-invalid": errors.password,
                    })}
                    onChange={() => setBackendFieldValidationError("")}
                    isInvalid={
                      errors.password ||
                      backendFieldValidationError.toLowerCase().includes("password")
                    }
                    ref={register({
                      required: true,
                      minLength: 8,
                      maxLength: 100,
                    })}
                  />

                  <Form.Control.Feedback type="invalid">
                    {backendFieldValidationError.toLowerCase().includes("password")
                      ? backendFieldValidationError
                      : "Please enter a valid password at least 8 characters in length."}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Check
                  onChange={() => setBackendFieldValidationError("")}
                  isInvalid={
                    errors.password || backendFieldValidationError.toLowerCase().includes("agree")
                  }
                >
                  <StyledInput
                    name="agreed_to_terms_of_service_and_read_privacy_policy"
                    id="agreed_to_terms_of_service_and_read_privacy_policy"
                    type="checkbox"
                    className={cx("form-check-input", {
                      "is-invalid": errors.agreed_to_terms_of_service_and_read_privacy_policy,
                    })}
                    ref={register({
                      required: true,
                    })}
                  />
                  <Form.Check.Label
                    htmlFor="agreed_to_terms_of_service_and_read_privacy_policy"
                    className="ml-2 leading-[22px]"
                  >
                    I agree to the{" "}
                    <a
                      className="font-semibold"
                      href={SUBSCRIBER_AGREEMENT_PATH}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Subscriber Agreement
                    </a>{" "}
                    and have read the{" "}
                    <a
                      className="font-semibold"
                      href={PRIVACY_POLICY_PATH}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    {backendFieldValidationError.includes("agree")
                      ? backendFieldValidationError
                      : "Please agree to the Subscriber Agreement and read the Privacy Policy."}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
              <Form.Group>
                {isSamlRequired ? (
                  <SpinnerButton
                    text="Create Account with SSO"
                    isLoading={isLoading}
                    className="primaryAction btn btn-primary btn-block h-12"
                    id={createAccountButtonGTag}
                  />
                ) : (
                  <SpinnerButton
                    text="Sign up"
                    isLoading={isLoading}
                    className="primaryAction btn btn-primary btn-block font-semibold h-12"
                    id={createAccountButtonGTag}
                  />
                )}
              </Form.Group>
              {!isDesktopOrTablet && (
                <Form.Group className="d-flex justify-content-center mb-0">
                  <Link href={LOGIN_PATH}>Login</Link>
                </Form.Group>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpScreen;
