import React from "react";
import clsx from "clsx";
import { Button, ButtonVariant, InputCombo, Text } from "@merge-api/merge-javascript-shared";
import { CommonModelSelect, FieldSelect, OperatorSelect } from "./components";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../../models/Entities";
import { Trash2 } from "lucide-react";
import compact from "lodash/compact";
import GenericValueField from "./components/GenericValueField";

type LinkedAccountConditionInputComboProps = {
  integrationName: string;
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  errorText: string | undefined;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
  onLinkedAccountConditionChange: (linkedAccount: Partial<LinkedAccountCondition>) => void;
  onLinkedAccountConditionDelete: () => void;
  isFirst: boolean;
  showGatedSelectiveSync: boolean;
};

const LinkedAccountConditionInputCombo = ({
  integrationName,
  linkedAccountCondition,
  errorText,
  linkedAccountConditionMeta,
  linkedAccountConditions,
  onLinkedAccountConditionChange,
  onLinkedAccountConditionDelete,
  isFirst,
  showGatedSelectiveSync,
}: LinkedAccountConditionInputComboProps) => {
  // instead of disabling fields, we hide them, and replace them with empty space
  const optionalSelectFields = compact([
    linkedAccountCondition.common_model && (
      <FieldSelect
        key="field"
        integrationName={integrationName}
        linkedAccountCondition={linkedAccountCondition}
        linkedAccountConditionMeta={linkedAccountConditionMeta}
        linkedAccountConditions={linkedAccountConditions}
        onLinkedAccountConditionChange={onLinkedAccountConditionChange}
        showGatedSelectiveSync={showGatedSelectiveSync}
      />
    ),
    linkedAccountCondition.normalized_key_name && (
      <OperatorSelect
        key="operator"
        linkedAccountCondition={linkedAccountCondition}
        linkedAccountConditionMeta={linkedAccountConditionMeta}
        onLinkedAccountConditionChange={onLinkedAccountConditionChange}
        showGatedSelectiveSync={showGatedSelectiveSync}
      />
    ),
    linkedAccountCondition.operator && (
      <GenericValueField
        key="generic-value-field"
        linkedAccountCondition={linkedAccountCondition}
        linkedAccountConditionMeta={linkedAccountConditionMeta}
        onLinkedAccountConditionChange={onLinkedAccountConditionChange}
      />
    ),
  ]);

  return (
    <>
      {!isFirst && <Text>and</Text>}
      <div className="flex flex-row items-center">
        <InputCombo
          fullWidth
          error={!!errorText}
          errorText={errorText}
          inputs={compact([
            <CommonModelSelect
              key="commonModel"
              linkedAccountCondition={linkedAccountCondition}
              linkedAccountConditionMeta={linkedAccountConditionMeta}
              linkedAccountConditions={linkedAccountConditions}
              onLinkedAccountConditionChange={onLinkedAccountConditionChange}
              showGatedSelectiveSync={showGatedSelectiveSync}
            />,

            ...optionalSelectFields,
            // add blank space equal to number of hidden inputs
            optionalSelectFields.length < 3 && (
              <div className="flex" style={{ flex: 3 - optionalSelectFields.length }} />
            ),

            !showGatedSelectiveSync && (
              <Button
                size="sm"
                variant={ButtonVariant.IconShadowHidden}
                key="trash"
                className="mr-1"
                onClick={onLinkedAccountConditionDelete}
              >
                <Trash2 size={16} />
              </Button>
            ),
          ])}
        />
      </div>
    </>
  );
};

export default LinkedAccountConditionInputCombo;
