import startCase from "lodash/startCase";
import lowerCase from "lodash/lowerCase";
import upperCase from "lodash/upperCase";
import upperFirst from "lodash/upperFirst";

const ACRONYMS = ["API"];

export const ENUM_FORM = /^[A-Z]+(_[A-Z]+)*$/;

/**
 * Converts from  display name to snake case
 * @param value - string, e.g. "Test API Value"
 * @returns string, e.g. "TEST_API_VALUE"
 */
export const sentenceCaseToSnakeCase = (value: string) => {
  return upperCase(value).split(" ").join("_");
};

/**
 * Converts from snake case to sentence case
 * @param value - string e.g. test_value
 * @returns string, e.g. Test value
 */
export const snakeCaseToSentenceCase = (value?: string) => {
  const displayName = upperFirst(lowerCase(value));

  return displayName
    .split(" ")
    .map((word) => {
      if (ACRONYMS.includes(upperCase(word))) {
        return upperCase(word);
      }
      return word;
    })
    .join(" ");
};

/**
 * Converts from snake case to spaces
 * @param value - string e.g. test_value
 * @returns string, e.g. test value
 */
export const snakeCaseToSpaces = (value?: string) => {
  return value ? value.replace(/_/g, " ") : "";
};

/**
 * Converts from ENUM case to lower case
 * @param value = string in ENUM casee.g., ACCOUNTS_PAYABLE
 * @returns string, e.g. accounts payable
 */
export const enumCaseToLowerCase = (value?: string | null) => {
  return value ? value.replace(/_/g, " ").toLowerCase() : "";
};
