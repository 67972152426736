import React from "react";
import { ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import { ConditionPreset } from "../../../../models/Entities";
import ConditionPresetPublishChangesDialogItem from "./ConditionPresetPublishChangesDialogItem";

type ConditionPresetPublishChangesDialogProps = {
  changes: {
    added: Partial<ConditionPreset>[];
    changed: ConditionPreset[];
    deleted: ConditionPreset[];
  };
  publishChanges: () => void;
  onClose: () => void;
  open: boolean;
  publishing: boolean;
};

const ConditionPresetPublishChangesDialog = ({
  changes,
  publishChanges,
  open,
  onClose,
  publishing,
}: ConditionPresetPublishChangesDialogProps) => {
  return (
    <Dialog
      title="Publish changes"
      open={open}
      onClose={onClose}
      variant="md"
      primaryButtonText="Publish"
      onPrimaryButtonClick={publishChanges}
      primaryButtonLoading={publishing}
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
    >
      <div className="flex flex-col">
        <Text className="pb-4">
          Publishing your changes will affect Merge Link for all new users instantaneously
        </Text>

        {/* added */}
        {changes.added.map((conditionPreset, index) => (
          <ConditionPresetPublishChangesDialogItem
            key={index}
            className="mb-2"
            conditionPreset={{ ...conditionPreset, period: conditionPreset.period?.toLowerCase() }}
            action="added"
          />
        ))}

        {/* changed */}
        {changes.changed.map((conditionPreset) => (
          <ConditionPresetPublishChangesDialogItem
            key={conditionPreset.id}
            className="mb-2"
            conditionPreset={{ ...conditionPreset, period: conditionPreset.period?.toLowerCase() }}
            action="changed"
          />
        ))}

        {/* deleted */}
        {changes.deleted.map((conditionPreset) => (
          <ConditionPresetPublishChangesDialogItem
            key={conditionPreset.id}
            className="mb-2"
            conditionPreset={{ ...conditionPreset, period: conditionPreset.period?.toLowerCase() }}
            action="deleted"
          />
        ))}
      </div>
    </Dialog>
  );
};

export default ConditionPresetPublishChangesDialog;
