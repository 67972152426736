import React, { useCallback, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ConflictErrorData,
  fetchWithAuth,
  FormErrorData,
  MagicLinkResponse,
} from "../../../api-client/APIClient";
import { fetchOrganizationIntegrationSettings } from "../../../api-client/organization/OrganizationIntegrationSettingsAPIClient";
import { showErrorToast } from "../../shared/Toasts";
import {
  APICategory,
  Button,
  ButtonVariant,
  Checkbox,
  Dialog,
  Link,
  Text,
  TextField,
  Typeahead,
} from "@merge-api/merge-javascript-shared";
import DottedOutlineTextCard from "../../shared/DottedOutlineTextCard";
import INTEGRATION_CATEGORY_LIST, { displayNameForAPICategory } from "../../../models/Helpers";
import { Link as LucideLink, ExternalLink, Sparkles } from "lucide-react";
import useAppContext from "../../context/useAppContext";
import { useHistory } from "react-router-dom";
import { navigateToLinkedAccountDetailPageByID } from "../../../router/RouterUtils";
import * as Frigade from "@frigade/react";
import { LinkedAccount, OrganizationIntegrationSettings } from "../../../models/Entities";

interface IntegrationOption {
  value: string;
  label: string;
}

type IntegrationState = Record<string, IntegrationOption[]> | undefined;

interface AddProductionLinkedAccountButtonProps {
  isGetStarted?: boolean;
  linkedAccount?: LinkedAccount;
}

const AddProductionLinkedAccountButton = ({
  isGetStarted,
  linkedAccount,
}: AddProductionLinkedAccountButtonProps) => {
  const { user } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [endUserOrganizationName, setEndUserOrganizationName] = useState<string | undefined>(
    linkedAccount?.end_user.origin_id ?? undefined,
  );
  const [url, setURL] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleSubmit, errors, control, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      uniqueIdentifier: linkedAccount?.end_user.origin_id ?? undefined,
      email: linkedAccount?.end_user.email_address ?? undefined,
      organizationName: linkedAccount?.end_user.organization_name ?? undefined,
      category: (linkedAccount?.category as APICategory) ?? undefined,
    },
  });
  const [selectedCategory, setSelectedCategory] = useState<APICategory | null>(null);

  const [disableOnboardingScreen, setDisableOnboardingScreen] = useState<boolean>(
    user.disable_magic_link_onboarding,
  );
  const [dismissOnboardingScreen, setDismissOnboardingScreen] = useState<boolean>(
    user.disable_magic_link_onboarding,
  );
  const [isConflictingCategoryAndEndUserOriginId, setIsConflictingCategoryAndEndUserOriginId] =
    useState<boolean>(false);
  const [linkedAccountID, setLinkedAccountID] = useState<string | undefined>(undefined);
  const [categoryToIntegrationMap, setCategoryToIntegrationsMap] =
    useState<IntegrationState>(undefined);

  const history = useHistory();

  useEffect(() => {
    fetchOrganizationIntegrationSettings({
      onlyEnabled: true,
      onFetch: (data) => {
        const integrationMap: IntegrationState = {};

        data.forEach((organizationIntegration: OrganizationIntegrationSettings) => {
          const integration = organizationIntegration.integration;
          const categories = integration.categories;

          categories.forEach((category) => {
            if (!integrationMap[category]) {
              integrationMap[category] = [];
            }

            if (
              !integrationMap[category].some(
                (item) => item.value === integration.slug && item.label === integration.name,
              )
            ) {
              integrationMap[category].push({
                value: integration.slug,
                label: integration.name,
              });
            }
          });
        });

        setCategoryToIntegrationsMap(integrationMap);
      },
    });
  }, []);

  const onDisableMagicLinkOnboarding = useCallback(() => {
    setIsLoading(true);

    fetchWithAuth({
      path: `/users/me/disable-magic-link-onboarding`,
      method: "PATCH",
      onResponse: (data: any) => {
        if (!data?.success) {
          showErrorToast("An error has occurred. Please try again later.");
        } else {
          setDisableOnboardingScreen(data.success);
        }
        setIsLoading(false);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            let wasToastShown = false;
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                showErrorToast(data[field_name][0]);
                wasToastShown = true;
              }
            }
            if (!wasToastShown) {
              showErrorToast("An error has occurred. Please try again later.");
            }
          });
        } else {
          showErrorToast("A network error has occurred. Please try again.");
        }
        setIsLoading(false);
      },
    });
  }, []);

  const onSubmit = useCallback(
    (data: {
      uniqueIdentifier: string;
      email: string;
      organizationName: string;
      category: string;
      integration: IntegrationOption | null;
    }) => {
      setIsLoading(true);
      const formData = {
        end_user_origin_id: data.uniqueIdentifier,
        end_user_organization_name: data.organizationName,
        end_user_email_address: data.email,
        categories: [data.category],
        should_create_magic_link_url: true,
        link_expiry_mins: 10080,
        ...(data.integration ? { integration: data.integration.value } : null),
      };

      fetchWithAuth({
        path: linkedAccount
          ? `/integrations/create-link-token-for-magic-link?is_relink=True`
          : `/integrations/create-link-token-for-magic-link`,
        method: "POST",
        body: formData,
        onResponse: (data: MagicLinkResponse) => {
          if (!data?.magic_link_url) {
            showErrorToast("An error has occurred. Please try again later.");
          } else {
            setEndUserOrganizationName(formData.end_user_organization_name);
            setURL(data.magic_link_url);
          }
          setIsLoading(false);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            if (err!.status == 409) {
              setIsConflictingCategoryAndEndUserOriginId(true);
              err.json().then((data: ConflictErrorData) => {
                if ("linked_account_id" in data) {
                  setLinkedAccountID(data.linked_account_id);
                }
              });
            } else {
              err.json().then((data: FormErrorData) => {
                let wasToastShown = false;
                if ("non_field_errors" in data) {
                  showErrorToast(data.non_field_errors[0]);
                  wasToastShown = true;
                }
                if (!wasToastShown) {
                  showErrorToast("An error has occurred. Please try again later.");
                }
              });
            }
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    },
    [setIsLoading],
  );

  const magicLinkOnboarding = (
    <>
      <Text as="p">
        Merge’s Magic Link allows you to deliver an in-browser linking experience and create
        Production Linked Accounts without any frontend code.
      </Text>
      <Text as="p">
        Send your users a secure URL to authorize their integrations in production.
      </Text>
      <Checkbox
        className="my-6"
        label="Don't show this screen again"
        onChange={setDisableOnboardingScreen}
        checked={disableOnboardingScreen}
      />
      <div className="flex flex-row gap-x-4">
        <Button
          onClick={() => window.open("https://docs.merge.dev/guides/magic-link/", "_blank")}
          rightIcon={<ExternalLink size={16} />}
          variant={ButtonVariant.SecondaryCharcoal}
          fullWidth
        >
          Learn more
        </Button>
        <Button
          onClick={() => {
            if (disableOnboardingScreen) {
              onDisableMagicLinkOnboarding();
            }
            setDismissOnboardingScreen(true);
          }}
          variant={ButtonVariant.PrimaryCharcoal}
          fullWidth
        >
          Next
        </Button>
      </div>
    </>
  );

  const conflictingUsers = (
    <>
      <Text as="p" variant="h6">
        Looks like the account {endUserOrganizationName} you are trying to connect already exists!
      </Text>
      <Text as="p">Visit the Linked Account’s page to generate a relink URL.</Text>
      <div className="flex flex-row gap-x-4 mt-6">
        <Button
          onClick={() => setIsConflictingCategoryAndEndUserOriginId(false)}
          variant={ButtonVariant.TextBlack}
          fullWidth
        >
          Go back
        </Button>
        <Button
          onClick={() => navigateToLinkedAccountDetailPageByID(history, linkedAccountID!)}
          fullWidth
        >
          Go to Linked Account
        </Button>
      </div>
    </>
  );

  const form = !url && (
    <form className="flex flex-col gap-y-5" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Text variant="h6" className="mb-2">
          Organization name
        </Text>
        <div className="mt-2">
          <Controller
            name="organizationName"
            control={control}
            rules={{ required: true, minLength: 1 }}
            render={({ name, onChange }) => (
              <TextField
                name={name}
                placeholder="Enter your user’s organization name"
                error={errors.organizationName ? true : false}
                errorText="Please enter a valid organization name"
                onChange={onChange}
                defaultValue={linkedAccount?.end_user.organization_name ?? undefined}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Text variant="h6">Email address</Text>
        <Text variant="sm" className="text-gray-60 mb-2">
          For identification purposes — this will not cause any emails to be sent
        </Text>

        <div className="mt-2">
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                const input = document.createElement("input");

                input.type = "email";
                input.required = true;
                input.value = value;

                const result =
                  typeof input.checkValidity === "function"
                    ? input.checkValidity() || input.validationMessage
                    : /\S+@\S+\.\S+/.test(value);
                input.remove();
                return result;
              },
            }}
            render={({ name, onChange }) => (
              <TextField
                name={name}
                placeholder="Enter your user’s email address"
                error={errors.email ? true : false}
                errorText="Please enter a valid email"
                onChange={onChange}
                defaultValue={linkedAccount?.end_user.email_address ?? undefined}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Text variant="h6" className="mb-2">
          Category
        </Text>

        <Controller
          name="category"
          control={control}
          rules={{
            required: true,
          }}
          render={({ value, onChange, ref }) => (
            <Typeahead
              ref={ref}
              className={
                errors.category
                  ? "ring ring-red-50/70 transition-[border-color,box-shadow] duration-150 ease-in-out rounded-md"
                  : ""
              }
              placeholder="Select category..."
              getOptionLabel={(category: APICategory) => displayNameForAPICategory(category)}
              value={(linkedAccount?.category as APICategory) ?? undefined}
              disabled={!!linkedAccount}
              options={INTEGRATION_CATEGORY_LIST}
              onChange={(_: any, category: APICategory | null) => {
                onChange(category);
                setSelectedCategory(category);
              }}
            />
          )}
        />
        {errors.category && (
          <Text variant="caption" className="text-red-50">
            Please select a category
          </Text>
        )}
      </div>
      {categoryToIntegrationMap && (
        <div>
          <Text variant="h6">Integration</Text>
          <Text variant="sm" className="text-gray-60">
            Optional. If set, creates a Magic Link URL for the specified integration
          </Text>
          <div className="mt-2">
            <Controller
              name="integration"
              control={control}
              render={({ value, onChange, ref }) => (
                <Typeahead
                  ref={ref}
                  className={
                    errors.category
                      ? "ring ring-red-50/70 transition-[border-color,box-shadow] duration-150 ease-in-out rounded-md"
                      : ""
                  }
                  placeholder="Select integration..."
                  disabled={!!linkedAccount}
                  options={
                    selectedCategory
                      ? categoryToIntegrationMap[selectedCategory].sort((a, b) =>
                          a.label.localeCompare(b.label),
                        )
                      : []
                  }
                  getOptionLabel={(option: IntegrationOption) => option.label}
                  onChange={(_: any, integration: IntegrationOption | null) => {
                    onChange(integration);
                  }}
                />
              )}
            />
          </div>
        </div>
      )}
      <div>
        <Text variant="h6">End user origin ID</Text>
        <Text variant="sm" className="text-gray-60">
          Uniquely identifies a Linked Account i.e. a specific customer of yours for a given
          integration.{" "}
          <Link
            href="https://help.merge.dev/en/articles/8032943-end-user-origin-id-best-practices"
            target="_blank"
          >
            Learn more
          </Link>
          .
        </Text>
        <div className="mt-2">
          <Controller
            name="uniqueIdentifier"
            control={control}
            rules={{
              required: true,
            }}
            render={({ name, onChange }) => (
              <TextField
                name={name}
                placeholder="Enter a unique end user origin ID"
                error={errors.uniqueIdentifier ? true : false}
                errorText="Please enter a valid unique identifier"
                onChange={onChange}
                defaultValue={linkedAccount?.end_user.origin_id ?? undefined}
                disabled={linkedAccount != undefined}
              />
            )}
          />
        </div>
      </div>
      <div className="flex flex-row gap-x-4 mt-6">
        <Button fullWidth onClick={() => setIsModalOpen(false)} variant={ButtonVariant.TextBlack}>
          Cancel
        </Button>
        <Button loading={isLoading} fullWidth leftIcon={<Sparkles size={16} />} type="submit">
          Generate URL
        </Button>
      </div>
    </form>
  );

  const urlDisplay = url && (
    <>
      <Text as="p">
        Send this URL to your user to authorize their integration. This URL will expire after 7
        days.
      </Text>
      <Text variant="h6" className="mt-6 mb-2">
        Magic Link URL for {endUserOrganizationName}
      </Text>
      <DottedOutlineTextCard text={url} isUrl />
    </>
  );

  const modalContent = () => {
    if (isConflictingCategoryAndEndUserOriginId) {
      return conflictingUsers;
    } else if (!dismissOnboardingScreen) {
      return magicLinkOnboarding;
    } else {
      return !url ? form : urlDisplay;
    }
  };

  const { track } = Frigade.useUser();

  return (
    <>
      <Frigade.Tour
        className="create_production_linked_account"
        flowId="flow_2JDRLtX1AZ1jeQFl"
        side="left"
        align="after"
        dismissible={false}
        avoidCollisions={false}
        css={{
          transform: "translate(calc(var(--radix-popper-anchor-width) / 2 - 10px), 10px)",
          ".fr-dot-wrapper": {
            scale: "0.5",
            transform: "translate(20px, -20px)",
          },
        }}
      />

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        footerButtonsHidden
        title="Magic Link"
        variant="md"
      >
        {modalContent()}
      </Dialog>

      {linkedAccount ? (
        <Button
          variant={ButtonVariant.TertiaryWhite}
          onClick={() => {
            setEndUserOrganizationName(undefined);
            setURL(undefined);
            reset();
            setIsModalOpen(true);
          }}
          leftIcon={<LucideLink size={12} />}
          fullWidth
        >
          Relink with Magic Link
        </Button>
      ) : isGetStarted ? (
        <Button
          onClick={() => {
            setEndUserOrganizationName(undefined);
            setURL(undefined);
            reset();
            setIsModalOpen(true);
          }}
          fullWidth
          className="mt-5"
        >
          Create Magic Link via URL
        </Button>
      ) : (
        <Button
          size="sm"
          variant={ButtonVariant.TertiaryWhite}
          className="tooltip-select-create-prod-la"
          onClick={() => {
            setEndUserOrganizationName(undefined);
            setURL(undefined);
            reset();
            setIsModalOpen(true);
            track("user-opened-merge-link");
          }}
          leftIcon={<LucideLink size={12} />}
        >
          Create production Linked Account
        </Button>
      )}
    </>
  );
};

export default AddProductionLinkedAccountButton;
