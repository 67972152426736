import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Controller, useForm } from "react-hook-form";
import { fetchWithAuth, FormErrorData } from "../../../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../../../shared/Toasts";
import Dropzone from "../../../../shared/Dropzone";
import { APICategory } from "../../../../../models/Entities";
import { Form } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { CustomIntegration, SetupStep } from "../../../../../models/Entities";
import { navigateToCustomIntegrationsConfigurationPage } from "../../../../../router/RouterUtils";
import INTEGRATION_CATEGORY_LIST, {
  displayNameForAPICategory,
} from "../../../../../models/Helpers";
import {
  Card,
  Button,
  ButtonVariant,
  Spinner,
  Text,
  Toggle,
} from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";

type RouteParams = {
  customIntegrationID: string;
};

function ConfigurationEditCustomIntegrations() {
  const history = useHistory();
  const { customIntegrationID } = useParams<RouteParams>();
  const { register, handleSubmit, errors, control } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [customIntegration, setCustomIntegration] = useState<null | CustomIntegration>();
  const [customIntegrationSetupSteps, setCustomIntegrationSetupSteps] = useState<SetupStep[]>([]);
  const [customIntegrationCompanyLogo, setCustomIntegrationCompanyLogo] = useState<null | Blob>();
  const [customIntegrationCompanySquareLogo, setCustomIntegrationCompanySquareLogo] =
    useState<null | Blob>();
  const [customIntegrationIsActive, setCustomIntegrationIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (customIntegrationID) {
      fetchWithAuth({
        path: `/integrations/custom/${customIntegrationID}`,
        method: "GET",
        onResponse: (data) => {
          setCustomIntegration(data);
          setCustomIntegrationSetupSteps(data.steps);
          setCustomIntegrationIsActive(data.active);
        },
      });
    } else {
      addEmptySetupStep(0);
    }
  }, []);

  function addEmptySetupStep(index: number) {
    const newSetupStep: SetupStep = {
      id: String(index),
      step_number: index,
      title: "",
      description: "",
    };

    const cloneCustomIntegrationSetupSteps = [...customIntegrationSetupSteps];
    cloneCustomIntegrationSetupSteps.push(newSetupStep);
    setCustomIntegrationSetupSteps(cloneCustomIntegrationSetupSteps);
  }

  function deleteSetupStep(index: number) {
    if (customIntegrationSetupSteps.length == 1) {
      showErrorToast("Each custom integration must have at least one step.");
      return;
    }
    const cloneCustomIntegrationSetupSteps = [...customIntegrationSetupSteps];
    cloneCustomIntegrationSetupSteps.splice(index, 1);
    cloneCustomIntegrationSetupSteps.forEach(function (setupStep, index) {
      setupStep.step_number = index;
    });
    setCustomIntegrationSetupSteps(cloneCustomIntegrationSetupSteps);
  }

  const onSubmit = (data: { name: string; active: boolean; category: string }) => {
    setIsLoading(true);
    const formData = {
      name: data.name,
      image: customIntegrationCompanyLogo,
      square_image: customIntegrationCompanySquareLogo,
      active: data.active,
      category: data.category,
      steps: JSON.stringify(customIntegrationSetupSteps),
    };
    if (customIntegrationID) {
      fetchWithAuth({
        path: `/integrations/custom/${customIntegrationID}`,
        method: "PATCH",
        body: formData,
        onResponse: (data: any) => {
          showSuccessToast(`Successfully edited ${data.name} integration!`);
          setIsLoading(false);
          navigateToCustomIntegrationsConfigurationPage(history);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  showErrorToast(data[field_name][0]);
                }
                if (field_name === "steps") {
                  showErrorToast("Please add a title for every step.");
                }
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    } else {
      fetchWithAuth({
        path: "/integrations/custom",
        method: "POST",
        body: formData,
        onResponse: () => {
          showSuccessToast(`Successfully added ${data.name} integration!`);
          setIsLoading(false);
          navigateToCustomIntegrationsConfigurationPage(history);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  showErrorToast(data[field_name][0]);
                }
                if (field_name === "steps") {
                  showErrorToast("Please add a title for every step.");
                }
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    }
  };

  function handleLogoUpload(files: Array<any>) {
    const logoFile = files[0];
    const blob = new Blob([logoFile], { type: logoFile.type });
    setCustomIntegrationCompanyLogo(blob);
  }

  function handleSquareLogoUpload(files: Array<any>) {
    const logoFile = files[0];
    const blob = new Blob([logoFile], { type: logoFile.type });
    setCustomIntegrationCompanySquareLogo(blob);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6 mb-6">
      <Text variant="h3">{`${customIntegrationID ? "Edit" : "Add"} custom integration`}</Text>
      <div className="flex flex-col gap-y-1">
        <Text>Integration company name*</Text>
        <Form.Control
          as="input"
          name="name"
          type="text"
          ref={register({ required: true, minLength: 1 })}
          className={cx({
            "is-invalid": errors.name,
          })}
          defaultValue={customIntegration?.name ?? ""}
        />

        <Form.Control.Feedback type="invalid">
          Please enter a valid company name.
        </Form.Control.Feedback>
      </div>

      <Controller
        name="active"
        control={control}
        defaultValue={customIntegrationIsActive}
        value={customIntegrationIsActive}
        onChange={() => setCustomIntegrationIsActive(!customIntegrationIsActive)}
        render={({ onChange, name }) => (
          <Toggle
            name={name}
            checked={customIntegrationIsActive}
            label="Is this custom integration active?*"
            labelPlacement="right"
            onChange={onChange}
          />
        )}
      />

      <div className="flex flex-col gap-y-1">
        <Text>What category is this custom integration in?*</Text>
        <Form.Control
          as="select"
          name="category"
          className="custom-select"
          ref={register({ required: true })}
          defaultValue={customIntegration?.category ?? ""}
          custom
        >
          {INTEGRATION_CATEGORY_LIST.map((category: APICategory) => (
            <option value={category}>{displayNameForAPICategory(category)}</option>
          ))}
        </Form.Control>
      </div>
      <div className="flex flex-col gap-y-1">
        <Text>Integration company logo*</Text>
        <Text variant="sm" className="text-gray-60">
          We recommend uploading a logo with a transparent background to keep the design consistent.
        </Text>
        <Dropzone upload={handleLogoUpload} />
      </div>
      <div className="flex flex-col gap-y-1">
        <Text>Integrations company square logo*</Text>
        <Text variant="sm" className="text-gray-60">
          We recommend uploading a square logo to keep the design consistent.
        </Text>
        <Dropzone upload={handleSquareLogoUpload} />
      </div>
      <hr className="border-[1px] border-gray-10 w-full" />
      <Text variant="h3">{`${customIntegrationID ? "Edit" : "Add"} instructions`}</Text>
      {customIntegrationSetupSteps.length > 0 ? (
        <>
          {customIntegrationSetupSteps?.map((setupStep, index) => (
            <ConfigurationCustomIntegrationSetupStep
              key={setupStep.id}
              setupStep={setupStep}
              index={index}
              onDelete={() => deleteSetupStep(index)}
            />
          ))}
          <Button
            variant={ButtonVariant.SecondaryCharcoal}
            fullWidth
            onClick={() =>
              addEmptySetupStep(
                customIntegrationSetupSteps ? customIntegrationSetupSteps.length : 0,
              )
            }
            size="md"
          >
            Add step
          </Button>
        </>
      ) : (
        <Spinner />
      )}
      <hr className="border-[1px] border-gray-10 w-full" />
      <div className="flex flex-col items-center gap-y-3">
        <Button fullWidth type="submit" size="md" loading={isLoading}>
          Save changes
        </Button>
        <Button
          fullWidth
          onClick={() => navigateToCustomIntegrationsConfigurationPage(history)}
          variant={ButtonVariant.TextBlack}
        >
          Cancel changes
        </Button>
      </div>
    </form>
  );
}

type ConfigurationCustomIntegrationSetupStepProps = {
  setupStep: SetupStep;
  index: number;
  onDelete: () => void;
};

export function ConfigurationCustomIntegrationSetupStep(
  props: ConfigurationCustomIntegrationSetupStepProps,
) {
  const { register, errors } = useForm();

  return (
    <Card className="p-6 flex flex-col gap-y-3">
      <div className="flex flex-row justify-between mb-2">
        <Text variant="h4">Step {props.index + 1}</Text>
        <Button size="sm" variant={ButtonVariant.IconShadowHidden} onClick={props.onDelete}>
          <X size={16} />
        </Button>
      </div>

      <div className="flex flex-col gap-y-2">
        <Text>Step title*</Text>
        <Form.Control
          as="input"
          name="stepTitle"
          ref={register({ required: true, minLength: 1 })}
          className={cx({
            "is-invalid": errors.stepTitle,
          })}
          defaultValue={props.setupStep.title}
          onChange={(e) => (props.setupStep.title = e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a title for every step.
        </Form.Control.Feedback>
      </div>
      <div className="flex flex-col gap-y-2">
        <Text>Step description</Text>
        <Text variant="sm" className="text-gray-60">
          You can add HTML here to customize the description.
        </Text>
        <Form.Control
          as="textarea"
          name="stepDescription"
          ref={register()}
          defaultValue={props.setupStep.description}
          onChange={(e) => (props.setupStep.description = e.target.value)}
        />
      </div>
    </Card>
  );
}

export default ConfigurationEditCustomIntegrations;
