import { displayNameForAPICategory } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { FormErrorData, Result } from "../../../../api-client/APIClient";
import {
  fetchCommonModelsFromCategory,
  updateCommonModel,
} from "../../../../api-client/categories/CommonModelTogglesAPIClient";
import { CUSTOM_OBJECT_COMMON_MODEL_NAMES } from "../../../../constants";
import { EnabledAction } from "../../../../models/CommonModel";
import { APICategory } from "../../../../models/Entities";
import { DOCS_PASSTHROUGH_REQUESTS_PATH } from "../../../../router/RouterUtils";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";
import ErrorFallback from "../../../shared/ErrorFallback/ErrorFallback";
import useProductRestrictions from "../../../shared/hooks/useProductRestrictions";
import { SectionHeaderWrapper, TitleHeading } from "../../../shared/MergeLayouts";
import CommonModelToggle from "./CommonModelToggle";
import { createSortedModelsMap, ModelWithSortedFields, SortedModelsMap } from "./CommonModelUtils";
import { getDocsPathForCategory } from "../../../../router/RouterUtils";
import MultiSwitch, { MultiSwitchOption } from "../../../shared/MultiSwitch";
import { palette, spectrum } from "../../../../styles/theme";
import useAppContext from "../../../context/useAppContext";
import { MergeFlagFeature, useMergeFlag } from "../../../shared/hooks/useMergeFlag";
import * as Frigade from "@frigade/react";
import { showErrorToast } from "../../../shared/Toasts";
import { useFlow, useUser } from "@frigade/react";

type ConfigurationCommonModelsPageProps = {
  category: APICategory;
  showUpsellModal: boolean;
  setShowUpsellModal: (isOpen: boolean) => void;
};

const MinWidthCol = styled(Col)`
  min-width: 300px;
`;

const SCOPES_FORCE_RESYNC_FRIGADE_FORM_CSS = `
            a {
              color: #0760f7;
            }

            a:hover {
              opacity: 0.8;
            }

            .fr-button-primary {
              width: 100%;
            }

            .fr-dialog-wrapper {
              padding: 18px !important;
              width: 327px !important;
            }

            .fr-title {
              font-size: 16px !important;
              font-weight: 600;
            }

            .fr-form {
              padding: 18px !important;
              width: 327px !important;
              border-radius: 10px;
            }

            .fr-dismiss {
              color: #b0b7c3;
            }

            .fr-form-step-header {
              position: relative;
            }

            .fr-form-step .fr-form-step-header .fr-title {
              position: relative;
              width: 100%;
              padding-bottom: 16px;
              font-size: 14px;
              color: #121414;
            }

            .fr-subtitle {
              font-size: 14px !important;
              font-weight: 400;
              line-height: 24px;
            }

            .fr-form-step .fr-form-step-header .fr-title:after {
              content: "";
              position: absolute;
              width: calc(100% + 68px);
              height: 1px; /* suit your need */
              background: #eaeaea;
              top: 100%;
              left: -18px;
            }

            .fr-form-step .fr-form-step-header .fr-subtitle {
              margin-top: 16px;
              font-size: 14px;
              color: #121414;
            }

            .fr-form-step .fr-field-checkbox-label {
              font-size: 14px;
              color: #121414;
            }

            .fr-field-checkbox {
              margin-right: 4px;
            }

            .fr-form-step .fr-field-checkbox {
              border-color: #121414;
              width: 16px;
              height: 16px;
              min-width: 16px;
            }
          `;

export const processErrorResult = async (
  result: Result<void>,
  commonModel: ModelWithSortedFields,
) => {
  if (result.status === "error") {
    let isGenericError = !result.error;
    if (result.error) {
      if (result.error.status === 403) {
        showErrorToast(
          commonModel.is_permanently_disabled
            ? "Common model is permanently disabled and cannot be re-enabled."
            : "Toggles are only available for customers on our Grow and Expand plans.",
        );
      } else {
        const data: FormErrorData = await result.error.json();
        if (data.non_field_errors) {
          showErrorToast(data.non_field_errors[0]);
        } else {
          isGenericError = true;
        }
      }
    }
    if (isGenericError) {
      showErrorToast("Something went wrong, please check your connection and try again.");
    }
  }
};

/**
 * Shows the Common Models under an API category and allows the user to enable/disable the syncing
 * of that particular model.
 */
const ConfigurationCommonModelsPage = ({
  category,
  showUpsellModal,
  setShowUpsellModal,
}: ConfigurationCommonModelsPageProps) => {
  const flowId = "flow_BV2zl9c4";
  const { flow } = useFlow(flowId);

  const { addProperties } = useUser();
  const [showDialog, setShowDialog] = useState(false);

  const displayName = displayNameForAPICategory(category);
  const [commonModels, setCommonModels] = useState<SortedModelsMap>({});
  const [hasError, setHasError] = useState(false);
  const [endUserConfigurableModels, setEndUserConigurableModels] = useState<Set<string>>(new Set());
  const { productRestrictions } = useProductRestrictions();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [isViewing, setIsViewing] = useState(!urlParams.has("isEditing"));
  const { user } = useAppContext();
  const areOptionalScopesEnabled = user?.are_end_user_configurable_scopes_enabled || false;
  const canToggleFields = !!productRestrictions?.are_toggles_enabled;

  const { enabled: isDefaultOffScopesEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_DEFAULT_OFF_SCOPES,
    organizationId: user.organization.id,
  });
  const { enabled: dependentModelScopesFlag } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_COMMON_MODEL_SCOPE_DEPENDENCIES,
    organizationId: user.organization.id,
  });

  /**
   * Gets the Common Models on page load and sets them in the UI
   */
  useEffect(() => {
    fetchCommonModelsFromCategory(category)
      .then((result) => {
        if (result.status === "success") {
          return createSortedModelsMap(result.data);
        } else {
          setHasError(true);
          return null;
        }
      })
      .then((commonModelsMap) => {
        if (commonModelsMap) {
          setCommonModels(commonModelsMap);
          const endUserConfigurableModels = Object.values(commonModelsMap).reduce(
            (
              endUserConfigurableModels: Set<string>,
              model: { name: string; end_user_configurable_actions: string[] },
            ) => {
              if (model.end_user_configurable_actions.includes(EnabledAction.READ)) {
                endUserConfigurableModels.add(model.name);
              }
              return endUserConfigurableModels;
            },
            new Set(),
          );
          setEndUserConigurableModels(endUserConfigurableModels);
        }
      });
  }, []);

  const updateEnabledStatus = useCallback(
    (
      modelName: string,
      fieldName: string | null,
      enabledActions: Array<EnabledAction>,
      optionalActions: Array<EnabledAction>,
      nonOptionalActions: Array<EnabledAction>,
      isFieldConfigurable?: boolean,
    ): Promise<Result<void>> => {
      const relatingField = (fieldName && commonModels[modelName].fields[fieldName]) || undefined;
      const relatedModelName = relatingField?.related_to;
      const relatedModel = !relatedModelName ? undefined : commonModels[relatedModelName];
      const isRelatedToSubobject = relatedModel && !relatedModel.has_endpoints;
      const shouldToggleRelatedModelInsteadOfField =
        relatedModelName && isRelatedToSubobject && relatedModel.name !== modelName;
      const transmittedFieldName = shouldToggleRelatedModelInsteadOfField
        ? undefined
        : fieldName ?? undefined;
      return updateCommonModel({
        category,
        enabledActions,
        modelName: shouldToggleRelatedModelInsteadOfField ? relatedModelName : modelName,
        fieldName: transmittedFieldName,
        optionalActions,
        nonOptionalActions,
        isFieldConfigurable,
      }).then((result) => {
        if (result.status === "success") {
          setCommonModels(createSortedModelsMap(result.data));
          return { status: "success", data: undefined };
        } else {
          processErrorResult(result, commonModels[modelName]);
          return result;
        }
      });
    },
    [commonModels],
  );

  const SCOPE_USER_OPTIONS = [
    {
      text: "Viewing",
      id: "viewing",
      selectedColor: palette.blue,
      backgroundColor: spectrum.gray0,
    },
    {
      text: "Editing",
      id: "editing",
      selectedColor: palette.blue,
      backgroundColor: spectrum.gray0,
    },
  ];

  const onSwitch = (selectedViewing: boolean) => {
    if (selectedViewing) {
      history.push({
        search: "",
      });
      setIsViewing(true);
      setShowDialog(false);
    } else {
      if (!isDefaultOffScopesEnabled && !canToggleFields) {
        setShowUpsellModal(true);
        return;
      }
      history.push({
        search: "isEditing=true",
      });
      setIsViewing(false);
      flow?.restart().then(() => setShowDialog(true));
    }
  };
  const editingSwitch = (
    <div>
      <Frigade.Tour
        className="end_user_scopes_tour"
        flowId="flow_1rLtQhTp"
        side="left"
        align="after"
        dismissible={false}
        avoidCollisions={false}
        css={{
          transform: "translate(calc(var(--radix-popper-anchor-width) / 2 - 10px), 10px)",
          ".fr-dot-wrapper": {
            scale: "0.5",
            transform: "translate(20px, -20px)",
          },
        }}
        onComplete={() => {
          onSwitch(false);
        }}
      />
      <MultiSwitch
        options={SCOPE_USER_OPTIONS}
        selectedID={isViewing ? "viewing" : "editing"}
        onSelectOption={(option: MultiSwitchOption) => {
          const selectedViewing = option.id == "viewing";
          onSwitch(selectedViewing);
        }}
        isRounded
        borderRadius={16}
        disabledButtonClass="text-gray-60 !border-0"
        backgroundColor={spectrum.gray0}
      />
    </div>
  );

  return (
    <Row>
      {showDialog && (
        <Frigade.Form
          as={Frigade.Dialog}
          dismissible
          flowId="flow_BV2zl9c4"
          variables={{
            category: category.toUpperCase(),
          }}
          onPrimary={(step, event, data) => {
            if (data?.["dont-show-again"]) {
              // Add prop so the flow wont show up again.
              // This is controlled by the flow's targeting.
              addProperties({ hideFullDataSyncConsent: true });
            }
            return true;
          }}
          onEscapeKeyDown={(e: any) => {
            e.preventDefault();
          }}
          css={SCOPES_FORCE_RESYNC_FRIGADE_FORM_CSS}
        />
      )}
      <MinWidthCol>
        <SectionHeaderWrapper
          title={`${displayName} Common Model Scopes`}
          subtitle={
            <>
              Customize access levels for the data that Merge requests from your end users. Common
              Models will be automatically disabled after 90 days of inactivity. Learn more about{" "}
              <a
                href="https://help.merge.dev/en/articles/5950052-what-are-common-model-scopes"
                rel="noreferrer"
                target="_blank"
              >
                Scopes
              </a>{" "}
              or Merge's {displayNameForAPICategory(category)}{" "}
              <a href={getDocsPathForCategory(category)} target="_blank" rel="noreferrer">
                API reference
              </a>
              .
            </>
          }
          button={editingSwitch}
        >
          <>
            <div className="mt-6">
              {hasError ? (
                <ErrorFallback />
              ) : Object.keys(commonModels).length === 0 ? (
                <EmptyStateWrapper isSpinner />
              ) : (
                Object.values(commonModels)
                  .filter((model) => model.has_endpoints)
                  .filter((model) => !CUSTOM_OBJECT_COMMON_MODEL_NAMES.includes(model.name))
                  .map((model) => (
                    <CommonModelToggle
                      key={model.name}
                      models={commonModels}
                      modelName={model.name}
                      productRestrictions={productRestrictions}
                      updateEnabledStatus={updateEnabledStatus}
                      setShowUpsellModal={setShowUpsellModal}
                      category={category}
                      isViewing={isViewing}
                      onSwitch={onSwitch}
                      areOptionalScopesEnabled={areOptionalScopesEnabled}
                      endUserConfigurableModels={endUserConfigurableModels}
                      canToggleOrgScopesOnly={!canToggleFields}
                      isDefaultScopesOffFlag={isDefaultOffScopesEnabled}
                      dependentModelScopesFlag={dependentModelScopesFlag}
                    />
                  ))
              )}
            </div>
          </>
        </SectionHeaderWrapper>
      </MinWidthCol>
    </Row>
  );
};

export default ConfigurationCommonModelsPage;
