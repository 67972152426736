import React from "react";
import {
  FieldMappingMetaResponse,
  LinkedAccount,
  FieldMappingOptionsResponse,
  FieldMappingInstance,
  FieldMappingTarget,
  FieldMappingCreationAndEditDict,
  FieldMappingPathways,
  FieldMappingModalStep,
} from "../../../../../../../models/Entities";
import { FieldMappingCommonModelConfiguration } from "../../../../../../../models/Entities";

interface FieldMappingModalContextState {
  linkedAccount: LinkedAccount | null;
  setModalStep: (modalStep: FieldMappingModalStep) => void;
  onModalClose: () => void;
  linkedAccountMappingInfo: FieldMappingMetaResponse | {};
  linkedAccountFieldMappingOptions: FieldMappingOptionsResponse | undefined;
  commonModelsForIntegration: Array<string>;
  commonModelsToMappingConfig: { [common_model_id: string]: FieldMappingCommonModelConfiguration };
  fieldMappingTargets: Array<FieldMappingTarget>;
  fieldMappingInstances: Array<FieldMappingInstance>;
  selectedRemoteField: FieldMappingCreationAndEditDict | null;
  setSelectedRemoteField: (selectedRemoteField: FieldMappingCreationAndEditDict | null) => void;
  selectedFieldMappingPathway?: FieldMappingPathways;
  setSelectedFieldMappingPathway: (selectedFieldMappingPathway: FieldMappingPathways) => void;
  jmesPath: string | null;
  setJmesPath: (jmesPath: string | null) => void;
  setCustomMappingMetaResponse: (data: FieldMappingMetaResponse) => void;
}

const FieldMappingModalContext = React.createContext<FieldMappingModalContextState>({
  linkedAccount: null,
  setModalStep: () => {},
  onModalClose: () => {},
  linkedAccountMappingInfo: {},
  commonModelsForIntegration: [],
  commonModelsToMappingConfig: {},
  linkedAccountFieldMappingOptions: undefined,
  fieldMappingTargets: [],
  fieldMappingInstances: [],
  selectedRemoteField: null,
  setSelectedRemoteField: () => {},
  selectedFieldMappingPathway: undefined,
  setSelectedFieldMappingPathway: () => {},
  jmesPath: null,
  setJmesPath: () => {},
  setCustomMappingMetaResponse: () => {},
});

export default FieldMappingModalContext;
