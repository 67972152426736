import React, { useState } from "react";
import {
  Dialog,
  ButtonVariant,
  Text,
  TextField,
  Select,
  TextFieldVariant,
  Typeahead,
  Link,
} from "@merge-api/merge-javascript-shared";
import { UserType } from "../../../../../models/Entities";
import useAppContext from "../../../../context/useAppContext";
import { REGEX_EMAIL_ADDRESS } from "../../../../shared/utils/SharedComponentUtils";
import { displayNameForUserType } from "../../../../../models/Helpers";
import { MERGE_USER_ROLE_HELP_CENTER } from "../../../../../router/RouterUtils";

type InviteTeamMemberModalProps = {
  isShown: boolean;
  onHide: () => void;
  onHandleSubmit: (data: { email: string; user_type: UserType }) => void;
};

const InviteTeamMemberModal = ({ isShown, onHide, onHandleSubmit }: InviteTeamMemberModalProps) => {
  // HOOKS
  const { user } = useAppContext();

  // STATE
  const [inviteUserType, setInviteUserType] = useState<UserType>(UserType.member);
  const [inviteUserEmail, setInviteUserEmail] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  // HANDLERS
  const handleInviteUserEmail = (email: string) => {
    setIsError(!REGEX_EMAIL_ADDRESS.test(email));
    setInviteUserEmail(email);
  };

  return (
    <Dialog
      open={isShown}
      onClose={onHide}
      variant="md"
      title="Invite team member"
      primaryButtonText="Send invite"
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
      onPrimaryButtonClick={() =>
        onHandleSubmit({ email: inviteUserEmail, user_type: inviteUserType })
      }
      primaryButtonDisabled={isError}
    >
      <div className="flex flex-col gap-4">
        <TextField
          label="Email address"
          variant={TextFieldVariant.Shadowed}
          placeholder="Enter email address"
          onChange={(e) => handleInviteUserEmail(e.target.value)}
        />
        <div>
          <Text variant="h6">Role</Text>
          <Text variant="sm" className="text-gray-60">
            Learn more about Merge's{" "}
            <Link href={MERGE_USER_ROLE_HELP_CENTER} target="_blank">
              user permission levels
            </Link>{" "}
          </Text>
          <Typeahead
            className="mt-2"
            disableClearable
            options={user.allowed_invite_roles.sort((a, b) => b.localeCompare(a))} // sort so that it goes from least permissions to most permissions
            getOptionLabel={(option) => {
              return displayNameForUserType(option);
            }}
            onChange={(_, selectedOption) => {
              if (selectedOption) {
                setInviteUserType(selectedOption);
              }
            }}
            value={inviteUserType}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default InviteTeamMemberModal;
