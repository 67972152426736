import React, { useState } from "react";
import clsx from "clsx";
import SyncHistory from "./SyncHistory";
import LinkedAccountConfigurationCard from "./LinkedAccountConfigurationCard";
import { LinkedAccount } from "../../../../../models/Entities";
import useAppContext from "../../../../context/useAppContext";
import useProductRestrictions from "../../../../shared/hooks/useProductRestrictions";
import IntegrationIssueWidget from "./IntegrationIssueWidget";
import { ISSUE_SYSTEM_V2 } from "../../../../../constants";
import { IntegrationIssue } from "../../../IntegrationsManagementEntities";
import { UserType } from "../../../../../models/Entities";
import IDsCard from "./IDsCard";
import DataWarehouseSyncHistory from "./datawarehouse/DataWarehouseSyncHistory";
import useLoadLinkedAccountConditionData from "../selective-sync/hooks/useLoadLinkedAccountConditionData";

// constants
const RIGHT_COLUMN_WIDTH_CLASS = "xl:min-w-[253px] xl:w-[253px]";

type OverviewPageProps = {
  linkedAccount: LinkedAccount;
  shouldShowWebhooksTab: boolean;
  shouldShowSelectiveSyncTab: boolean;
  shouldShowFieldMappingsTab: boolean;
  isDataWarehouseAccount: boolean;
  ongoingIntegrationIssue: IntegrationIssue | null;
  refetchIntegrationIssues: () => void;
};

const OverviewPage = ({
  linkedAccount,
  shouldShowWebhooksTab,
  shouldShowSelectiveSyncTab,
  shouldShowFieldMappingsTab,
  ongoingIntegrationIssue,
  isDataWarehouseAccount,
  refetchIntegrationIssues,
}: OverviewPageProps) => {
  // hooks
  const { user, isUserPrivileged } = useAppContext();
  const { productRestrictions } = useProductRestrictions();
  const { remoteLinkedAccountConditions } = useLoadLinkedAccountConditionData({
    linkedAccount,
  });

  const [openDropdown, setOpenDropdown] = useState<string | null>("");

  // derived state
  const isAdmin = user.type === UserType.admin_with_billing;
  const shouldShowRedactUnmappedDataRow =
    !!productRestrictions?.redact_unmapped_data_enabled && isAdmin;
  const showConfigurationCard =
    (shouldShowFieldMappingsTab ||
      shouldShowWebhooksTab ||
      shouldShowRedactUnmappedDataRow ||
      shouldShowSelectiveSyncTab) &&
    !isDataWarehouseAccount;

  const shouldShowIntegrationIssueWidget =
    ongoingIntegrationIssue !== null &&
    ongoingIntegrationIssue.has_user_seen == false &&
    ongoingIntegrationIssue.issue_generated_by == ISSUE_SYSTEM_V2;

  const includeLinkedAccountScopes =
    isUserPrivileged &&
    productRestrictions?.are_toggles_enabled &&
    user.is_linked_account_scopes_dashboard_enabled;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col xl:flex-row gap-x-6 gap-y-5">
        {/* Left column */}
        <div className="flex flex-col gap-y-5 w-full">
          {/* Issues Card */}
          {shouldShowIntegrationIssueWidget && ongoingIntegrationIssue !== null && (
            <IntegrationIssueWidget
              showPreview
              integrationIssue={ongoingIntegrationIssue}
              linkedAccount={linkedAccount}
              refetchIntegrationIssues={refetchIntegrationIssues}
            />
          )}

          {/* Data Sync Table */}
          <div className="w-full mr-8">
            {isDataWarehouseAccount ? (
              <DataWarehouseSyncHistory linkedAccount={linkedAccount} />
            ) : (
              <>
                {/* Data Sync Table */}
                <SyncHistory
                  linkedAccount={linkedAccount}
                  includeLinkedAccountScopes={includeLinkedAccountScopes}
                  remoteLinkedAccountConditions={remoteLinkedAccountConditions}
                  openDropdown={openDropdown}
                  setOpenDropdown={setOpenDropdown}
                />
              </>
            )}
          </div>
        </div>
        {/* Right clumn */}
        <div className={clsx(RIGHT_COLUMN_WIDTH_CLASS, "flex xl:flex-col flex-row gap-5")}>
          <IDsCard
            linkedAccount={linkedAccount}
            className="flex w-full max-w-[50%] xl:max-w-full"
          />

          {showConfigurationCard && (
            <LinkedAccountConfigurationCard
              className="w-full mb-8"
              linkedAccount={linkedAccount}
              shouldShowFieldMappingsTab={shouldShowFieldMappingsTab}
              shouldShowWebhooksTab={shouldShowWebhooksTab}
              shouldShowSelectiveSyncTab={shouldShowSelectiveSyncTab}
              shouldShowRedactUnmappedDataRow={shouldShowRedactUnmappedDataRow}
              openDropdown={openDropdown}
              setOpenDropdown={setOpenDropdown}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
