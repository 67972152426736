import React from "react";
import { formatDate } from "../../../../../../models/Helpers";
import { LogType } from "../../../../IntegrationsManagementEntities";
import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { X, ExternalLink } from "lucide-react";
import { Link, useHistory } from "react-router-dom";
import { isScraperLogEntry, isWebhookLogEntry } from "../../../../IntegrationsManagementEntities";
import {
  API_TESTER_MERGE_TO_THIRD_PARTY_PATH,
  API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
  navigateToLinkedAccountDetailPageByID,
} from "../../../../../../router/RouterUtils";
import { LOG_DIRECTIONS } from "../../../../../../constants";
import DirectionCell from "../../../shared/DirectionCell";

type Props = {
  title: string;
  logEntry: LogType;
  onClickExit: () => void;
};

export const LogSidePanelBanner = ({ title, logEntry, onClickExit }: Props) => {
  const history = useHistory();
  const isAPIRequestLog = !isScraperLogEntry(logEntry) && !isWebhookLogEntry(logEntry);

  return (
    <div className="p-6 flex justify-between w-full relative flex-wrap lg:flex-nowrap items-center gap-y-5">
      <div className="flex flex-row justify-start items-center gap-x-5 flex-nowrap w-fit">
        <Button size="sm" variant={ButtonVariant.IconShadowHidden} onClick={() => onClickExit()}>
          <X size={20} />
        </Button>
        <div className="flex flex-row items-center h-full gap-x-3 gap-y-2 w-fit justify-center">
          <Text variant="h3">{title}</Text>
          <div className="flex flex-row justify-center items-center bg-gray-0 rounded-full h-fit p-1">
            <DirectionCell log={logEntry} size={24} />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between lg:justify-end gap-x-3 gap-y-2 w-full lg:w-fit">
        <Text>{formatDate(logEntry.created_at)}</Text>
        <Button
          onClick={() => navigateToLinkedAccountDetailPageByID(history, logEntry.linked_account.id)}
          variant={ButtonVariant.SecondaryCharcoal}
          size="sm"
          rightIcon={<ExternalLink size={10} />}
        >
          {logEntry.linked_account.end_user.organization_name}
        </Button>
        {isAPIRequestLog && (
          <Button size="sm" rightIcon={<ExternalLink size={10} />}>
            <Link
              to={{
                pathname:
                  logEntry.direction == LOG_DIRECTIONS.LOG_OUTBOUND
                    ? API_TESTER_MERGE_TO_THIRD_PARTY_PATH
                    : API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
                search: `?linked_account_id=${logEntry.linked_account.id}`,
                state: logEntry,
              }}
            >
              <span className="text-white">Open in API tester</span>
            </Link>
          </Button>
        )}
      </div>
    </div>
  );
};
