import React from "react";
import clsx from "clsx";
import { APICategory, Typeahead } from "@merge-api/merge-javascript-shared";
import INTEGRATION_CATEGORY_LIST, {
  displayNameForAPICategory,
} from "../../../../../../../../models/Helpers";

import { IntegrationDropdownOption } from "../../../../../../../../hooks/useLoadIntegrationsDropdownOptions";
import isUndefined from "lodash/isUndefined";

// visually cuts the last option in half
const INTEGRATIONS_POPPER_HEIGHT = 350;

type MockSandboxSelectProps = {
  className?: string;
  mockSandboxCategory: APICategory;
  setMockSandboxCategory: React.Dispatch<React.SetStateAction<APICategory>>;
  mockSandboxIntegrations: IntegrationDropdownOption[] | undefined;
  mockSandboxIntegration: IntegrationDropdownOption | undefined;
  setMockSandboxIntegration: React.Dispatch<
    React.SetStateAction<IntegrationDropdownOption | undefined>
  >;
};

const MockSandboxSelect = ({
  className,
  mockSandboxCategory,
  setMockSandboxCategory,
  mockSandboxIntegration,
  setMockSandboxIntegration,
  mockSandboxIntegrations,
}: MockSandboxSelectProps) => {
  return (
    <div className={clsx("flex flex-row gap-2", className)}>
      {/* Category list */}
      <div className="w-56 max-w-56">
        <Typeahead
          className="w-56"
          disabled // disabled b/c right now only support HRIS
          options={INTEGRATION_CATEGORY_LIST}
          getOptionLabel={(category: APICategory) => {
            return displayNameForAPICategory(category);
          }}
          disableClearable
          onChange={(_: any, category: APICategory | null) => setMockSandboxCategory(category!)}
          value={mockSandboxCategory}
        />
      </div>
      {/* Integration select */}
      <div className="w-full">
        <Typeahead
          disableClearable
          options={mockSandboxIntegrations ?? []}
          loading={isUndefined(mockSandboxIntegrations)}
          renderOption={(option: IntegrationDropdownOption) => (
            <div className="flex flex-row items-center gap-2">
              <img className="h-[25px] w-[25px] rounded-full" src={option.square_image} />{" "}
              {option.name}
            </div>
          )}
          getOptionLabel={(option) => option.name}
          onChange={(_, integration) => {
            if (integration && !Array.isArray(integration)) setMockSandboxIntegration(integration);
          }}
          value={mockSandboxIntegration}
          popperMaxHeight={INTEGRATIONS_POPPER_HEIGHT}
        />
      </div>
    </div>
  );
};

export default MockSandboxSelect;
