import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { ArrowLeftRight } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface AsyncWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
}

function AsyncWebhook(props: AsyncWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType, onUnselectingWebhookType } = props;
  const selected = selectedWebhookType
    ? selectedWebhookType.has(SelectedWebhookType.ASYNC_OPERATION)
    : false;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Async operation completed"
        description="Receive an alert when an async operation (e.g., POST) has resolved"
        icon={<ArrowLeftRight size={16} />}
        onClick={() => {
          if (selected) {
            onUnselectingWebhookType(SelectedWebhookType.ASYNC_OPERATION);
          } else {
            onSelectedWebhookTypeChange(SelectedWebhookType.ASYNC_OPERATION);
          }
        }}
        selected={selected}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default AsyncWebhook;
