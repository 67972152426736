import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  text?: string;
}

const HorizontalRuleWithText = ({ text }: Props) => (
  <div className="flex items-center">
    {text ? (
      <>
        <hr className="flex-1 border-gray-10" />
        <Text variant="pre-title" className="mx-4 text-gray-60 font-medium leading-[22px]">
          {text}
        </Text>
        <hr className="flex-1 border-charcoal-20" />
      </>
    ) : (
      <hr className="flex-1 border-charcoal-20" />
    )}
  </div>
);

export default HorizontalRuleWithText;
