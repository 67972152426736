import React from "react";
import { Info } from "lucide-react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
  LinkedAccountConditionMetaField,
} from "../../../../../../../../models/Entities";
import { Text, TextFieldVariant, Typeahead } from "@merge-api/merge-javascript-shared";
import { getFilteredFieldOptions, getFieldValue } from "../helpers";
import { snakeCaseToSentenceCase } from "../../../../../../../../utils/casing";
import UpsellFilterOptionMessage from "../../../../../../configuration/selective-sync/ConditionPresetInputCombo/components/UpsellFilterOptionMessage";

type FieldSelectProps = {
  integrationName: string;
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
  showGatedSelectiveSync: boolean;
};

const FieldSelect = ({
  integrationName,
  linkedAccountCondition,
  linkedAccountConditionMeta,
  linkedAccountConditions,
  onLinkedAccountConditionChange,
  showGatedSelectiveSync,
}: FieldSelectProps) => {
  // derived state
  const fieldOptions = getFilteredFieldOptions(
    linkedAccountCondition,
    linkedAccountConditionMeta,
    linkedAccountConditions,
  );
  const fieldValue = getFieldValue(linkedAccountCondition, linkedAccountConditionMeta);

  // event handlers
  const onFieldChange = (_: any, fieldOption: LinkedAccountConditionMetaField | null) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      normalized_key_name: fieldOption?.normalized_key_name || undefined,

      // reset other fields
      condition_schema_id: undefined,
      operator: undefined,
      value: undefined,
    });
  };

  const selectProps = {
    className: "h-full py-[3px]",
    borderVariant: TextFieldVariant.InlinedRounded,
    placeHolder: "Select field...",
    disableClearable: true,
    value: fieldValue,
  };

  return (
    <div className="flex flex-1">
      {showGatedSelectiveSync ? (
        <Typeahead
          {...selectProps}
          options={[fieldValue]}
          getOptionLabel={(option) => snakeCaseToSentenceCase(option?.normalized_key_name)}
          renderOption={(option) => (
            <div className="flex flex-row items-center">
              <Text className="text-gray-90 mr-2">
                {snakeCaseToSentenceCase(option?.normalized_key_name)}
              </Text>
              <Text variant="caption" className="text-gray-60">
                {option?.remote_key_name}
              </Text>
            </div>
          )}
          typeaheadFooter={
            <>
              <div className="border-t-gray-10 border-t text-gray-60 py-2.5 px-3 flex-col items-center -mb-2">
                <Info size={16} className="mr-1.5" />{" "}
                <Text variant="sm">{integrationName} fields in small text</Text>
              </div>
              <UpsellFilterOptionMessage />
            </>
          }
        />
      ) : (
        <Typeahead
          {...selectProps}
          options={fieldOptions}
          getOptionLabel={(option) => snakeCaseToSentenceCase(option.normalized_key_name)}
          renderOption={(option) => (
            <div className="flex flex-row items-center">
              <Text className="text-gray-90 mr-2">
                {snakeCaseToSentenceCase(option.normalized_key_name)}
              </Text>
              <Text variant="caption" className="text-gray-60">
                {option.remote_key_name}
              </Text>
            </div>
          )}
          onChange={onFieldChange}
          typeaheadFooter={
            <div className="border-t-gray-10 border-t text-gray-60 py-2.5 px-3 flex items-center -mb-2">
              <Info size={12} className="mr-1.5" />{" "}
              <Text variant="sm">{integrationName} fields in small text</Text>
            </div>
          }
        />
      )}
    </div>
  );
};

export default FieldSelect;
