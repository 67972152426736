import React from "react";
import {
  ConditionPreset,
  ConditionPresetMeta,
  ConditionPresetMetaField,
} from "../../../../../../models/Entities";
import { TextFieldVariant, Typeahead } from "@merge-api/merge-javascript-shared";
import { getFilteredFieldOptions, getFieldValue } from "../helpers";
import { snakeCaseToSentenceCase } from "../../../../../../utils/casing";
import UpsellFilterOptionMessage from "./UpsellFilterOptionMessage";

type FieldSelectProps = {
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  conditionPresets: Partial<ConditionPreset>[];
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
  showGatedSelectiveSync: boolean;
  disabled?: boolean;
};

const FieldSelect = ({
  conditionPreset,
  conditionPresets,
  conditionPresetMeta,
  onConditionPresetChange,
  showGatedSelectiveSync,
  disabled,
}: FieldSelectProps) => {
  // derived state
  const fieldOptions = getFilteredFieldOptions(
    conditionPreset,
    conditionPresetMeta,
    conditionPresets,
  );
  const fieldValue = getFieldValue(conditionPreset, conditionPresetMeta);

  // event handlers
  const onFieldChange = (_: any, fieldOption: ConditionPresetMetaField | null) => {
    onConditionPresetChange({
      ...conditionPreset,
      normalized_key_name: fieldOption?.normalized_key_name || undefined,

      // reset other fields
      operator: undefined,
      value: undefined,
      is_end_user_configured: undefined,
    });
  };

  return (
    <div className="flex flex-1">
      {showGatedSelectiveSync ? (
        <Typeahead
          className="h-full py-[3px]"
          borderVariant={TextFieldVariant.InlinedRounded}
          placeholder="Select field..."
          options={[fieldValue]}
          disableClearable
          getOptionLabel={(option) => snakeCaseToSentenceCase(option?.normalized_key_name)}
          value={fieldValue}
          typeaheadFooter={<UpsellFilterOptionMessage />}
          disabled={disabled}
        />
      ) : (
        <Typeahead
          className="h-full py-[3px]"
          borderVariant={TextFieldVariant.InlinedRounded}
          placeholder="Select field..."
          options={fieldOptions}
          disableClearable
          getOptionLabel={(option) => snakeCaseToSentenceCase(option.normalized_key_name)}
          value={fieldValue}
          onChange={onFieldChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default FieldSelect;
