import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import ClickableContainer from "../../../../shared/ClickableContainer";
import { SetOpenDropdownType } from "../overview/types";

const customToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: React.ReactNode;
      onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    },
    ref: React.Ref<HTMLSpanElement>,
  ) => (
    <ClickableContainer>
      <span
        ref={ref}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClick(event);
        }}
      >
        {children}
      </span>
    </ClickableContainer>
  ),
);

type ScopesDropdownProps = {
  children: JSX.Element;
  menuOptions: JSX.Element;
  rowId: string;
  openDropdown: string | null;
  setOpenDropdown: SetOpenDropdownType;
};
const ScopesDropdown = ({
  children,
  menuOptions,
  rowId,
  openDropdown,
  setOpenDropdown,
}: ScopesDropdownProps) => {
  const handleToggle = (rowId: string, isOpen: boolean, setOpenDropdown: SetOpenDropdownType) => {
    if (typeof setOpenDropdown !== "function") {
      return;
    }
    setOpenDropdown(isOpen ? rowId : null);
  };

  return (
    <Dropdown
      className="text-[12px]"
      show={openDropdown === rowId}
      onToggle={(isOpen) => handleToggle(rowId, isOpen, setOpenDropdown)}
    >
      <Dropdown.Toggle
        as={customToggle}
        className="hover:cursor-pointer active:cursor-pointer focus:cursor-pointer"
      >
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu rootCloseEvent="click">{menuOptions}</Dropdown.Menu>
    </Dropdown>
  );
};
export default ScopesDropdown;
