import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import { verifyOAuthToken } from "./utils/OAuthAPIClient";
import { TENANT_URL_TO_FRONTEND_URL, VALID_TENANT_URLS } from "../../../../api-client/APIClient";
import Cookies from "universal-cookie";
import { Spinner } from "@merge-api/merge-javascript-shared";
import * as Sentry from "@sentry/react";

const OAuthRedirectScreen = () => {
  const params = new URLSearchParams(window.location.search);
  const state = params.get("state") ?? "";
  let oauth_key = "";
  let tenant_url = "";
  if (state != "") {
    try {
      const parsedState = JSON.parse(state);
      tenant_url = parsedState["tenant_url"];
      oauth_key = params.get("oauth_key") ?? parsedState["key"] ?? "";
      params.set("state", parsedState["key"]);
    } catch {
      oauth_key = params.get("oauth_key") ?? params.get("state") ?? "";
    }
  } else {
    oauth_key = params.get("oauth_key") ?? params.get("state") ?? "";
  }

  let isMergeTenantURL = false;
  if (tenant_url !== "") {
    isMergeTenantURL = VALID_TENANT_URLS.includes(tenant_url);
  } else {
    isMergeTenantURL = true;
  }

  const oauth_authorization_code =
    params.get("oauth_authorization_code") ?? params.get("code") ?? "";

  const obj = Object.fromEntries(params);
  const additional_query_params = JSON.stringify(obj);

  const [isDoneVerifying, setIsDoneVerifying] = useState(false);
  const [hasOAuthError, setHasOAuthError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(
    "Error authenticating. Please close window to try again.",
  );

  const hasRedirectError = params.has("error") || !isMergeTenantURL;

  async function getCookieValue() {
    const cookies = new Cookies();
    const cookieValue = await cookies.get("mergelink_csrftoken");
    return cookieValue || "string";
  }

  const postRedirectContent =
    hasRedirectError || hasOAuthError
      ? errorMessage
      : `All done! Please close window if it does not close automatically.`;
  useEffect(() => {
    const sendCsrfToken = async () => {
      if (isMergeTenantURL) {
        if (tenant_url in TENANT_URL_TO_FRONTEND_URL) {
          const frontend_url = TENANT_URL_TO_FRONTEND_URL[tenant_url];
          if (frontend_url && frontend_url !== "" && !window.location.href.includes(frontend_url)) {
            // Use window.location.replace to properly redirect
            window.location.replace(
              window.location.href.replace(window.location.origin, frontend_url),
            );
            return; // Exit early since we're redirecting
          }
        }

        const merge_link_csrf_token = await getCookieValue();
        verifyOAuthToken({
          oauth_key,
          oauth_authorization_code,
          additional_query_params,
          tenant_url,
          merge_link_csrf_token,
          onResponse: () => {
            setIsDoneVerifying(true);
          },
          onError: async (response: Response | undefined) => {
            const responseJson = await response?.json();
            const nonFieldErrors = responseJson?.non_field_errors;
            let capturedSpecificError = false;
            if (nonFieldErrors?.length > 0) {
              const errorMessage = nonFieldErrors[0];
              Sentry.captureMessage(errorMessage);
              capturedSpecificError = true;
              if (errorMessage == "CSRF token mismatch") {
                setErrorMessage(
                  <>
                    CSRF verification failed Please make sure{" "}
                    <a href="https://help.merge.dev/en/articles/9660563-how-do-i-enable-third-party-cookies-in-my-browser">
                      third party cookies are enabled in your browser
                    </a>{" "}
                    and retry.
                  </>,
                );
              }
            }
            if (!capturedSpecificError) {
              Sentry.captureMessage("OAuth Token verification failed.");
            }
            setHasOAuthError(true);
          },
        });
      }
    };

    sendCsrfToken();
  }, [isMergeTenantURL]);

  return (
    <Container fluid className="h-screen d-flex align-items-center justify-content-center">
      <Col className="d-flex flex-grow-1 align-items-center justify-content-center">
        {!isDoneVerifying && !hasRedirectError && !hasOAuthError ? (
          <Spinner />
        ) : (
          <span>{postRedirectContent}</span>
        )}
      </Col>
    </Container>
  );
};

export default OAuthRedirectScreen;
