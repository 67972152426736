import { useEffect, useMemo, useState } from "react";
import isUndefined from "lodash/isUndefined";
import isEqual from "lodash/isEqual";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import { useLazyRequest } from "../../../../../shared/hooks/useLazyRequest";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../models/Entities";
import { getLinkedAccountConditionPath } from "../helpers";
import { getParsedValueValue } from "../components/LinkedAccountConditionInputCombo/helpers";

type UsePublishChangesProps = {
  remoteLinkedAccountConditions?: LinkedAccountCondition[];
  linkedAccountConditions?: Partial<LinkedAccountCondition>[];
  linkedAccountConditionMeta?: LinkedAccountConditionMeta;
  linkedAccountId: string;
  refetch: () => void;
};

/**
 * Hook for publishing changes to linked account conditions. Makes a series of POST, PATCH, and DELETE endpoint calls for each change.
 */
const usePublishChanges = ({
  remoteLinkedAccountConditions,
  linkedAccountConditions,
  linkedAccountConditionMeta,
  linkedAccountId,
  refetch,
}: UsePublishChangesProps) => {
  // state
  const [added, setAdded] = useState<Partial<LinkedAccountCondition>[]>([]);
  const [changed, setChanged] = useState<LinkedAccountCondition[]>([]);
  const [deleted, setDeleted] = useState<LinkedAccountCondition[]>([]);

  // hooks
  const [addLinkedAccountCondition, { loading: addConditionLoading }] =
    useLazyRequest<LinkedAccountCondition>({
      path: getLinkedAccountConditionPath(linkedAccountId),
      method: HTTPMethod.POST,
      errorText: "Error creating linked account filter. Please try again!",
    });
  const [changeLinkedAccountCondition, { loading: changeConditionLoading }] =
    useLazyRequest<LinkedAccountCondition>({
      method: HTTPMethod.PATCH,
      errorText: "Error updating linked account filter. Please try again!",
    });
  const [deleteLinkedAccountCondition, { loading: deleteConditionLoading }] = useLazyRequest({
    method: HTTPMethod.DELETE,
    errorText: "Error deleting linked account filter. Please try again!",
  });

  const hasChanges = useMemo(() => {
    return size(added) + size(changed) + size(deleted) > 0;
  }, [added, changed, deleted]);

  // event handlers
  const parseChanges = () => {
    if (!linkedAccountConditions || !remoteLinkedAccountConditions) return;

    // parse deletes
    // (remoteLinkedAccountConditions that do not exist in linkedAccountConditions)
    const deleted = remoteLinkedAccountConditions.filter((remoteLinkedAccountCondition) => {
      return (
        linkedAccountConditions.findIndex(({ id }) => remoteLinkedAccountCondition.id === id) === -1
      );
    });

    // parse added
    // (linkedAccountConditions w/o id)
    // We skip any completely empty object so it doesn't get mistakenly treated as a new condition.
    const added = linkedAccountConditions.filter((condition) => {
      return isUndefined(condition.id) && !isEmpty(condition);
    });

    // parse changed
    // (linkedAccountConditions w/ id that do not equal remoteLinkedAccountCondition)
    const changed = linkedAccountConditions.filter((linkedAccountCondition) => {
      const remoteLinkedAccountCondition = remoteLinkedAccountConditions.find(
        ({ id }) => id === linkedAccountCondition.id,
      );

      return (
        remoteLinkedAccountCondition &&
        linkedAccountConditionMeta &&
        !isEqual(linkedAccountCondition, {
          ...remoteLinkedAccountCondition,
          value: getParsedValueValue(remoteLinkedAccountCondition, linkedAccountConditionMeta),
        })
      );
    }) as LinkedAccountCondition[]; // type assert b/c if changed, we know it should have all linked account condition values

    setAdded(added);
    setChanged(changed);
    setDeleted(deleted);
  };

  /**
   * Get all changes, and send an api request for each change
   */
  const publishChanges = async () => {
    await Promise.all([
      // added
      ...added.map((linkedAccountCondition) =>
        addLinkedAccountCondition({
          body: linkedAccountCondition,
        }),
      ),

      // changed
      ...changed.map((linkedAccountCondition) => {
        return changeLinkedAccountCondition({
          path: getLinkedAccountConditionPath(linkedAccountId, linkedAccountCondition.id),
          body: linkedAccountCondition,
        });
      }),

      // deleted
      ...deleted.map(({ id }) =>
        deleteLinkedAccountCondition({
          path: getLinkedAccountConditionPath(linkedAccountId, id),
        }),
      ),
    ]);

    // refetch linkedAccountConditions and linkedAccountConditionMeta after changes
    refetch();
  };

  // effects
  // parse changes on every change
  useEffect(parseChanges, [
    linkedAccountConditionMeta,
    linkedAccountConditions,
    remoteLinkedAccountConditions,
  ]);

  return {
    publishChanges,
    changes: {
      added,
      changed,
      deleted,
    },
    hasChanges,
    publishing: addConditionLoading || changeConditionLoading || deleteConditionLoading,
  };
};

export default usePublishChanges;
