import React, { useMemo } from "react";
import clsx from "clsx";
import { ChevronRight } from "lucide-react";

import {
  Button,
  TextField,
  Link,
  APICategory,
  UnreleasedAPICategory,
  Typeahead,
} from "@merge-api/merge-javascript-shared";
import { APIEndpointMethod, APITesterLinkedAccount } from "../../../../../../../models/Entities";
import { ApiTesterDirection, LinkedAccountType } from "../../../../enums";
import { MERGE_BASE_URL } from "../../../hooks/useSendAPITesterRequest";
import useApiTesterDirection from "../../../hooks/useApiTesterDirection";
import useLinkedAccountType from "../../../hooks/useLinkedAccountType";

type EndpointPathSelectorProps = {
  className?: string;
  linkedAccount?: APITesterLinkedAccount | null;
  onSendClick: () => void;
  method: APIEndpointMethod;
  setMethod: React.Dispatch<React.SetStateAction<APIEndpointMethod>>;
  path: string;
  setPath: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  category?: APICategory | UnreleasedAPICategory;
};

const EndpointPathSelector = ({
  className,
  linkedAccount,
  onSendClick,
  path,
  setPath,
  isLoading,
  category,
  method,
  setMethod,
}: EndpointPathSelectorProps) => {
  // hooks
  const direction = useApiTesterDirection();
  const { linkedAccountType } = useLinkedAccountType();

  // derived state
  // API Reference Link
  const apiReferenceLink =
    direction === ApiTesterDirection.YOU_TO_MERGE
      ? `https://docs.merge.dev/${category}/overview`
      : linkedAccount
      ? `https://docs.merge.dev/integrations/${category}/${linkedAccount.integration.slug}/provider-interactions/`
      : `https://docs.merge.dev/integrations/${category}/overview`;

  const prefix = useMemo(() => {
    if (!linkedAccount) {
      return "{Base URL}";
    }

    if (direction === ApiTesterDirection.MERGE_TO_THIRD_PARTY) {
      return (
        linkedAccount?.override_base_api_url ||
        linkedAccount?.integration.base_api_url ||
        "{Base URL}"
      );
    }

    return `${MERGE_BASE_URL}/api/${category}/v1`;
  }, [direction, linkedAccount, category]);

  return (
    <div className={clsx("p-6 w-full", className)}>
      {/* Header */}
      <div className="w-full flex justify-between items-center flex-row bg-transparent mb-6">
        <h6>Endpoint path</h6>
        <Link className="text-sm" variant="pre-title" target="_blank" href={apiReferenceLink}>
          API reference <ChevronRight size={10} />
        </Link>
      </div>
      <div className="flex flex-col lg:flex-row gap-4 w-full flex-wrap lg:flex-nowrap">
        {/* Method selector */}
        {linkedAccountType !== LinkedAccountType.MOCK_SANDBOX && (
          <div className="w-full lg:w-1/4">
            <Typeahead
              disableClearable
              value={method}
              popperMaxHeight={350}
              onChange={(e, method) => method && setMethod(method)}
              options={[APIEndpointMethod.GET, APIEndpointMethod.POST]}
            />
          </div>
        )}

        {/* Path TextField */}
        <div className="flex flex-row w-full lg:w-3/4 gap-4 group">
          <div className="flex flex-grow">
            <TextField
              placeholder="/endpoint"
              className="w-full"
              inputClassName="min-w-[88px]"
              prefixVariant="url"
              prefixTooltipTitle={prefix}
              prefix={
                <div
                  className="block truncate transition-[max-width] duration-300 ease-out group-focus-within:max-w-[75px] max-w-[125px]"
                  dir="rtl"
                >
                  {/* <bdi> is used inside to ensure that the actual text itself remains LTR (e.g. https:// is not rendered as //:https) */}
                  <bdi>{prefix}</bdi>
                </div>
              }
              value={path}
              onChange={(e) => setPath(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && onSendClick()} // submit on enter from TextField
            />
          </div>
          {/* Send */}
          <Button
            disabled={!linkedAccount && linkedAccountType !== LinkedAccountType.MOCK_SANDBOX}
            onClick={onSendClick}
            loading={isLoading}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EndpointPathSelector;
