import { Card, Dialog, Link, Text } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import size from "lodash/size";
import { ArrowUpRight } from "lucide-react";
import React, { useState } from "react";
import {
  APICategory,
  ConditionPreset,
  ConditionPresetMeta,
} from "../../../../../../models/Entities";
import { snakeCaseToSpaces } from "../../../../../../utils/casing";
import { getOperatorValue, getSupportedIntegrations } from "../helpers";

type SupportedIntegrationsTooltipProps = {
  className?: string;
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  category: APICategory;
};

const SupportedIntegrationsTooltip = ({
  className,
  conditionPreset,
  conditionPresetMeta,
  category,
}: SupportedIntegrationsTooltipProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const operatorValue = getOperatorValue(conditionPreset, conditionPresetMeta, category);
  const supportedIntegrations = getSupportedIntegrations(
    conditionPreset,
    conditionPresetMeta,
    category,
  );

  const numIntegrationsSupportedText = `${size(supportedIntegrations)} integration${
    size(supportedIntegrations) > 1 ? "s" : ""
  } supported`;

  if (!operatorValue || !size(supportedIntegrations)) {
    return null;
  }

  return (
    <div className={clsx(className)}>
      <div className="flex items-center">
        <Text variant="title-sm">
          <Link onClick={() => setIsDialogOpen(true)}>
            {numIntegrationsSupportedText}
            <ArrowUpRight size="12" className="ml-1" />
          </Link>
        </Text>
      </div>
      <Dialog
        footerButtonsHidden
        title={`${conditionPreset.common_model} ${snakeCaseToSpaces(
          conditionPreset.normalized_key_name,
        )} filter`}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <Text>{numIntegrationsSupportedText}:</Text>
        <Card variant="outline" className="p-3 max-h-[240px] mt-4 overflow-hidden overflow-y-auto">
          <div className="flex flex-col space-y-3">
            {supportedIntegrations?.map(({ id, name, square_image }) => (
              <div key={id} className="flex flex-row items-center">
                <img
                  src={square_image}
                  alt={name}
                  className="w-4 h-4 object-cover rounded-full bg-white mr-2"
                />
                <Text>{name}</Text>
              </div>
            ))}
          </div>
        </Card>
      </Dialog>
    </div>
  );
};

export default SupportedIntegrationsTooltip;
