import React from "react";
import { ConditionPreset } from "../../../../../../models/Entities";
import { TextFieldVariant, Typeahead } from "@merge-api/merge-javascript-shared";
import { enumCaseToLowerCase } from "../../../../../../utils/casing";
import UpsellFilterOptionMessage from "./UpsellFilterOptionMessage";

type EnumValueSelectProps = {
  conditionPreset: Partial<ConditionPreset>;
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
  showGatedSelectiveSync: boolean;
  disabled?: boolean;
  enumValueOptions: string[];
};

const EnumValueSelect = ({
  conditionPreset,
  onConditionPresetChange,
  showGatedSelectiveSync,
  disabled,
  enumValueOptions,
}: EnumValueSelectProps) => {
  // derived state
  const value = conditionPreset.value;

  // event handlers
  const onValueChange = (_: any, valueOption: string | null) => {
    onConditionPresetChange({
      ...conditionPreset,
      value: valueOption || undefined,
    });
  };

  return (
    <div className="flex flex-1">
      {showGatedSelectiveSync ? (
        <Typeahead
          className="h-full py-[3px]"
          borderVariant={TextFieldVariant.InlinedRounded}
          placeholder="Select field..."
          options={[value]}
          disableClearable
          getOptionLabel={(option) => enumCaseToLowerCase(option)}
          value={value}
          typeaheadFooter={<UpsellFilterOptionMessage />}
          disabled={disabled}
        />
      ) : (
        <Typeahead
          className="h-full py-[3px]"
          borderVariant={TextFieldVariant.InlinedRounded}
          placeholder="Select field..."
          options={enumValueOptions}
          disableClearable
          getOptionLabel={(option) => enumCaseToLowerCase(option)}
          value={value}
          onChange={onValueChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default EnumValueSelect;
