import { Button, ButtonVariant, Card, Text } from "@merge-api/merge-javascript-shared";
import React from "react";
import SkeletonLoader from "../../shared/SkeletonLoader";

type Props = {
  selected: boolean;
  onClick: () => void;
  title: JSX.Element;
  metric?: number;
};

const LinkedAccountsPageFilterCard = ({ selected, onClick, title, metric }: Props) => {
  return (
    <Card
      type="button"
      className={`p-0 flex flex-col ${selected ? "ring-2 ring-blue-40" : ""}`}
      onClick={onClick}
    >
      <div className="flex flex-row px-3 py-2 items-center justify-between border-b border-gray-10 w-full">
        <div className="flex flex-row items-center gap-x-1.5">{title}</div>

        <Button variant={ButtonVariant.TextBlue} size="sm" onClick={onClick}>
          {selected ? "Remove filter" : "Filter"}
        </Button>
      </div>
      <div className="px-5 py-4 w-full text-left">
        <Text variant="h3">
          {metric ?? <SkeletonLoader fullWidth height={16} borderRadius={4} className="my-0.5" />}
        </Text>
      </div>
    </Card>
  );
};

export default LinkedAccountsPageFilterCard;
