import React, { useEffect, useRef, useState } from "react";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import {
  FileStorageSyncSelectionTypes,
  LinkedAccountFileStorageFilters,
} from "../../../../../../models/Entities";
import { Folder, HardDrive } from "lucide-react";
import EmptyStateWrapper from "../../../../../shared/EmptyStateWrapper";
import SkeletonLoader from "../../../../../shared/SkeletonLoader";

type LinkedAccountFileStorageFiltersProps = {
  linkedAccountFileStorageFilters?: LinkedAccountFileStorageFilters;
};

const LinkedAccountFileStorageFiltersTable = ({
  linkedAccountFileStorageFilters,
}: LinkedAccountFileStorageFiltersProps) => {
  // hooks

  // state
  const [rowWidth, setRowWidth] = useState<number>(516);
  const tableRef = useRef<HTMLDivElement>(null);

  // event handlers
  const updateWidth = () => {
    if (tableRef.current) {
      setRowWidth(tableRef.current.getBoundingClientRect().width);
    }
  };

  function formatBytes(bytes: number) {
    if (!+bytes) return "0 Bytes";

    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    // Format the date using toLocaleString
    return date.toLocaleString("en-US", {
      month: "short", // abbreviated month name
      day: "2-digit", // two-digit day
      hour: "numeric", // numeric hour
      minute: "2-digit", // two-digit minute
      hour12: true, // 12-hour format
    });
  };

  // effects
  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [tableRef, rowWidth]);

  const skeletonRows = new Array(5).fill(null).map((_, index) => (
    <div className="flex flex-col">
      <div className="px-6 py-5 border-b border-gray-10">
        <SkeletonLoader fullWidth height={12} borderRadius={4} />
      </div>
    </div>
  ));

  return (
    <Card>
      <div className="flex flex-col px-6 py-5 gap-y-1">
        <Text variant="h4">Selected files and folders</Text>
        <Text>Your users can choose files and folders to selectively sync in Merge Link</Text>
      </div>
      {!linkedAccountFileStorageFilters ? (
        skeletonRows
      ) : linkedAccountFileStorageFilters?.length > 0 ? (
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th className="px-6 pb-5 border-b border-gray-10">
                <Text variant="h6">Name</Text>
              </th>
              <th className="px-6 pb-5 border-b border-gray-10">
                <Text variant="h6">Size</Text>
              </th>
              <th className="px-6 pb-5 border-b border-gray-10">
                <Text variant="h6">Updated</Text>
              </th>
            </tr>
          </thead>
          {linkedAccountFileStorageFilters.map((filter, index) => {
            return (
              <tr key={index}>
                <td className="px-6 py-5 flex flex-row items-center gap-x-3 border-b border-gray-10">
                  {filter.type.toLowerCase() ===
                  FileStorageSyncSelectionTypes.DRIVE.toLowerCase() ? (
                    <HardDrive size={16} className="text-gray-60" />
                  ) : (
                    <Folder size={16} className="text-gray-60" />
                  )}

                  <Text>{filter.name}</Text>
                </td>
                <td className="px-6 py-5 border-b border-gray-10">
                  {filter.size ? (
                    formatBytes(Number(filter.size))
                  ) : (
                    <Text className="text-gray-60">—</Text>
                  )}
                </td>
                <td className="px-6 py-5 border-b border-gray-10">
                  {filter.updated_at ? (
                    formatDate(filter.updated_at)
                  ) : (
                    <Text className="text-gray-60">—</Text>
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      ) : (
        <div className="border-t border-gray-10 pt-5 pb-6">
          <EmptyStateWrapper title="No files have been selected for this Linked Account" />
        </div>
      )}
    </Card>
  );
};

export default LinkedAccountFileStorageFiltersTable;
