import clsx from "clsx";
import { Info } from "lucide-react";
import React, { useMemo, useState } from "react";
import { RefreshCw } from "lucide-react";
import map from "lodash/map";
import {
  AuthType,
  CommonModelSyncStatus,
  LinkedAccount,
  LinkedAccountCondition,
  LinkedAccountStatus,
  ManualSyncCreditPlans,
  PauseReason,
  SyncFrequencyPlan,
} from "../../../../../models/Entities";
import useTimeout from "../../../../shared/hooks/useTimeout";
import { formatDateToShortMonthDayYear } from "../../../../shared/utils/SharedComponentUtils";
import SyncHistoryTable from "./SyncHistoryTable";
import { UseSyncCreditModal } from "./UseSyncCreditModal";
import { Schemas } from "../../../configuration/common-models/CommonModelToggleTypes";
import { SortedModelsMap } from "../../../configuration/common-models/CommonModelUtils";
import { APICategory, ButtonVariant, HTTPMethod } from "@merge-api/merge-javascript-shared";
import useDeprecatedCommonModel from "../../../../../hooks/useDeprecatedCommonModel";
import { Alert, Button } from "@merge-api/merge-javascript-shared";
import useCommonModels from "../../../../../hooks/useCommonModels";
import { useRequest } from "../../../../shared/hooks/useRequest";
import { useLazyRequest } from "../../../../shared/hooks/useLazyRequest";
import { Tooltip } from "@merge-api/merge-javascript-shared";
import { navigateToBillingPage } from "../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { SetOpenDropdownType } from "./types";

const MONTHLY_SYNC_TOOLTIP_TITLE =
  "Your monthly data sync plan grants you 16 sync credits per year per Linked Account. Credits will only be spent if the sync was successful";
const QUARTERLY_SYNC_TOOLTIP_TITLE =
  "Your quarterly data sync plan grants you 6 sync credits per year per Linked Account. Credits will only be spent if the sync was successful";

export interface Props {
  linkedAccount: LinkedAccount | null;
  includeLinkedAccountScopes?: boolean;
  schemas?: Schemas | null;
  commonModels?: SortedModelsMap | null;
  remoteLinkedAccountConditions?: LinkedAccountCondition[];
  openDropdown: string | null;
  setOpenDropdown: SetOpenDropdownType;
}

/**
 * Shows a header + resync button for sync history. Resync just shortcircuits the
 * interval to fetch data now.
 */
const SyncHistory = ({
  linkedAccount,
  includeLinkedAccountScopes = false,
  remoteLinkedAccountConditions,
  openDropdown,
  setOpenDropdown,
}: Props) => {
  // hooks
  const { commonModels } = useCommonModels({ category: linkedAccount?.category });
  const { filterDeprecatedCommonModels } = useDeprecatedCommonModel({
    category: linkedAccount?.category || APICategory.hris,
  });

  // state
  const [isResyncingVisuallyOnly, setIsResyncingVisuallyOnly] = useState(false);
  const [showUseSyncCreditModal, setShowSyncCreditModal] = useState(false);
  const [isAlertDismissed, setIsAlertDismissed] = useState(false);

  // fetch hooks
  const { refetch: refetchSyncStatus, data: syncStatusData } = useRequest<CommonModelSyncStatus[]>({
    path: `/integrations/linked-accounts/${linkedAccount?.id}/sync-status`,
    skip: !linkedAccount,
    method: HTTPMethod.GET,
    pollInterval: 3000,
  });

  const [forceResync] = useLazyRequest<CommonModelSyncStatus[]>({
    path: `/integrations/linked-accounts/${linkedAccount?.id}/force-resync`,
    method: HTTPMethod.POST,
    successText: `Resync queued for ${linkedAccount?.end_user.organization_name} integration with ${linkedAccount?.integration.name}.`,
    errorText: "Failed to force resync. Please try again.",
    onResponse: () => {
      if (linkedAccount?.remaining_sync_credits !== undefined) {
        // Update FE status of sync credits, BE is source of truth
        linkedAccount.remaining_sync_credits--;
      }
      refetchSyncStatus();
    },
  });

  const history = useHistory();

  const syncStatuses = useMemo(() => {
    if (!syncStatusData) return null;

    const filteredCommonModels = filterDeprecatedCommonModels(map(syncStatusData, "model_name"));
    return syncStatusData.filter((syncStatus) =>
      filteredCommonModels.includes(syncStatus.model_name),
    );
  }, [filterDeprecatedCommonModels, syncStatusData]);

  // derived state
  const isOrganizationOnManualSyncPlan = ManualSyncCreditPlans.includes(
    linkedAccount?.sync_frequency_plan as SyncFrequencyPlan,
  );

  /**
   * Just use this timeout to make the spinner animate for just under a second. We show a toast message so the user
   * knows when the resync has actually kicked off. This is purely for some visual fun
   */
  useTimeout(
    () => {
      setIsResyncingVisuallyOnly(false);
    },
    isResyncingVisuallyOnly ? 990 : null,
  );

  // Fetches data on demand - the button spins at least for a second, or until the network call is finished
  const resyncButton =
    linkedAccount?.auth_type === AuthType.SFTP ||
    (linkedAccount?.auth_type === AuthType.WEB_CONNECTOR &&
      !linkedAccount?.is_test_account) ? null : isOrganizationOnManualSyncPlan ? (
      <div className="bg-slate-0 flex flex-row justify-between items-center gap-14 rounded-lg pl-2 pr-3">
        <div>
          <div className=" text-blue-40 font-semibold leading-5 text-xs">
            {linkedAccount?.remaining_sync_credits} of {linkedAccount?.total_sync_credits} Sync
            Credits remaining
          </div>
          <div className="text-gray-60 leading-5 text-xs">
            <>
              Renews {formatDateToShortMonthDayYear(linkedAccount?.sync_credits_refresh_timestamp)}{" "}
              {linkedAccount?.sync_frequency_plan ===
              SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_MONTHLY ? (
                <Tooltip title={MONTHLY_SYNC_TOOLTIP_TITLE}>
                  <Info size={10} />
                </Tooltip>
              ) : (
                <Tooltip title={QUARTERLY_SYNC_TOOLTIP_TITLE}>
                  <Info size={10} />
                </Tooltip>
              )}
            </>
          </div>
        </div>
        <Tooltip title="Currently syncing models will not be resynced">
          <Button
            variant={ButtonVariant.TertiaryWhite}
            disabled={
              !linkedAccount ||
              !linkedAccount.completed_at ||
              (linkedAccount.remaining_sync_credits ?? 0) <= 0
            }
            onClick={() => setShowSyncCreditModal(true)}
            leftIcon={
              <RefreshCw
                className={clsx("m-0 p-0", {
                  rotating: isResyncingVisuallyOnly,
                })}
                size={12}
              />
            }
          >
            Resync all
          </Button>
        </Tooltip>
      </div>
    ) : (
      <Tooltip title="Currently syncing models will not be resynced">
        <Button
          variant={ButtonVariant.TertiaryWhite}
          disabled={!linkedAccount || !linkedAccount.completed_at}
          onClick={() => {
            setIsResyncingVisuallyOnly(true);
            forceResync();
          }}
          leftIcon={
            <RefreshCw
              className={clsx("m-0 p-0", {
                rotating: isResyncingVisuallyOnly,
              })}
              size={12}
            />
          }
        >
          Resync all
        </Button>
      </Tooltip>
    );

  return (
    <>
      <div className="flex flex-col">
        <div>
          {linkedAccount?.pause_reason === PauseReason.PRICING_PLAN_LIMIT ? (
            <>
              {linkedAccount?.status === LinkedAccountStatus.INCOMPLETE ? (
                <Alert showWarningIcon color="red">
                  Syncing for this account has been paused. Please upgrade your plan to continue
                  syncing.
                </Alert>
              ) : (
                linkedAccount?.status === LinkedAccountStatus.COMPLETE && (
                  <Alert showWarningIcon color="red" className="mb-5">
                    This account is linked but not syncing. Please upgrade your plan to continue
                    syncing.
                  </Alert>
                )
              )}
            </>
          ) : (
            linkedAccount?.pause_reason === PauseReason.PLAN_LIMIT_REACHED &&
            !isAlertDismissed && (
              <Alert className="mb-5" showWarningIcon>
                <div className="flex w-full flex-row items-center justify-between">
                  <span>
                    You've exceeded your account limit for the Free plan. Upgrade your plan to sync
                    data for this Linked Account.
                  </span>
                  <div className="flex gap-3">
                    <Button
                      size="sm"
                      variant={ButtonVariant.TextBlack}
                      onClick={() => setIsAlertDismissed(true)}
                    >
                      Dismiss
                    </Button>
                    <Button
                      onClick={() => navigateToBillingPage(history)}
                      size="sm"
                      variant={ButtonVariant.TertiaryWhite}
                    >
                      Upgrade
                    </Button>
                  </div>
                </div>
              </Alert>
            )
          )}

          {(linkedAccount?.pause_reason !== PauseReason.PRICING_PLAN_LIMIT ||
            linkedAccount?.status === LinkedAccountStatus.COMPLETE) && (
            <SyncHistoryTable
              linkedAccount={linkedAccount}
              syncStatuses={syncStatuses}
              resyncButton={
                linkedAccount?.pause_reason !== PauseReason.PRICING_PLAN_LIMIT &&
                linkedAccount?.pause_reason !== PauseReason.PLAN_LIMIT_REACHED
                  ? resyncButton
                  : undefined
              }
              includeLinkedAccountScopes={includeLinkedAccountScopes}
              commonModels={commonModels}
              remoteLinkedAccountConditions={remoteLinkedAccountConditions}
              openDropdown={openDropdown}
              setOpenDropdown={setOpenDropdown}
            />
          )}
        </div>
      </div>
      {showUseSyncCreditModal && (
        <UseSyncCreditModal
          onClickMethod={() => {
            setIsResyncingVisuallyOnly(true);
            forceResync();
            setShowSyncCreditModal(false);
          }}
          isShown={showUseSyncCreditModal}
          onHide={() => setShowSyncCreditModal(false)}
        />
      )}
    </>
  );
};

export default SyncHistory;
