import React from "react";
import {
  APICategory,
  Button,
  Link,
  Tooltip,
  ButtonVariant,
  UnreleasedAPICategory,
} from "@merge-api/merge-javascript-shared";
import { SectionHeaderWrapper } from "../../../../../shared/MergeLayouts";
import { ExternalLink, MonitorUp } from "lucide-react";
import { LinkedAccount } from "../../../../../../models/Entities";
import useAppContext from "../../../../../context/useAppContext";

type LinkedAccountConditionHeaderProps = {
  hasChanges: boolean;
  onPublishChangesClick: () => void;
  category: APICategory | UnreleasedAPICategory;
  linkedAccount: LinkedAccount;
  children?: JSX.Element;
};

const LinkedAccountConditionHeader = ({
  hasChanges,
  onPublishChangesClick,
  category,
  children,
}: LinkedAccountConditionHeaderProps) => {
  const { user } = useAppContext();

  const showFileStorageSelectiveSync =
    category === APICategory.filestorage && user.is_file_storage_selective_sync_enabled;
  return (
    <SectionHeaderWrapper
      title="Selective Sync"
      button={
        <div className="flex flex-row gap-x-3 items-center">
          {!showFileStorageSelectiveSync && (
            <Tooltip title={!hasChanges && "No changes to publish"}>
              <Button
                variant={ButtonVariant.PrimaryCharcoal}
                leftIcon={<MonitorUp size={16} />}
                onClick={onPublishChangesClick}
                disabled={!hasChanges}
              >
                Publish changes
              </Button>
            </Tooltip>
          )}

          <Button
            variant={ButtonVariant.SecondaryCharcoal}
            href="https://help.merge.dev/en/articles/9113654-selective-sync"
            rightIcon={<ExternalLink size={16} />}
          >
            Learn more
          </Button>
        </div>
      }
      className="mb-5"
    >
      {children}
    </SectionHeaderWrapper>
  );
};

export default LinkedAccountConditionHeader;
