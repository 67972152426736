import React from "react";
import {
  PRIVACY_POLICY_PATH,
  SUBSCRIBER_AGREEMENT_PATH,
  TOS_PATH,
} from "../../../router/RouterUtils";
import useAppContext from "../../context/useAppContext";
import { fetchWithAuth, onLogout } from "../../../api-client/APIClient";
import { showErrorToast } from "../../shared/Toasts";
import { isBillingPlanLaunchPlan } from "../../pages/settings/billing/BillingUtils";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";

const MAR_2022_TOS_UPDATE_TIME = 1647885380000;
const NOV_2021_SECURITY_TIME = 1637001900000;

const UpdatedTermsModal = () => {
  const { user, setUser } = useAppContext();
  const shouldShowModal =
    (!user.terms_agreement_datetime ||
      new Date(user.terms_agreement_datetime).getTime() < MAR_2022_TOS_UPDATE_TIME) &&
    isBillingPlanLaunchPlan(user.organization.organization_billing_plan?.billing_plan);

  const shouldIncludeTOSInModal =
    (!user.terms_agreement_datetime ||
      new Date(user.terms_agreement_datetime).getTime() < NOV_2021_SECURITY_TIME) &&
    isBillingPlanLaunchPlan(user.organization.organization_billing_plan?.billing_plan);

  const acceptTermsAndAgreements = () => {
    fetchWithAuth({
      path: "/users/me",
      method: "PATCH",
      body: { terms_agreed: true },
      onResponse: (data) => {
        setUser(data.user);
      },
      onError: () => {
        showErrorToast("Failed to accept the updated terms and agreements. Please try again.");
      },
    });
  };

  const logout = () => {
    onLogout({
      onError: () => showErrorToast(`Failed to logout. Please try again.`),
    });
  };

  return (
    <Dialog
      open={shouldShowModal}
      onClose={logout}
      onPrimaryButtonClick={acceptTermsAndAgreements}
      onSecondaryButtonClick={logout}
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
      primaryButtonText="I accept the updated terms and agreements"
      secondaryButtonText="Cancel"
      title="Updated terms and agreements"
      variant="md"
    >
      <p>We&apos;ve updated the following legal agreements:</p>
      <p>
        <ul className="ml-4">
          {shouldIncludeTOSInModal && (
            <li>
              <a href={TOS_PATH} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
            </li>
          )}
          <li>
            <a href={SUBSCRIBER_AGREEMENT_PATH} target="_blank" rel="noreferrer">
              Subscriber Agreement
            </a>
          </li>
          {shouldIncludeTOSInModal && (
            <li>
              <a href={PRIVACY_POLICY_PATH} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </li>
          )}
        </ul>
      </p>
      <p>
        {shouldIncludeTOSInModal
          ? "Please click the button below to indicate that you agree to the latest Terms of Use and Subscriber Agreement and have read the updated Privacy Policy."
          : "Please click the button below to indicate that you agree to the latest Subscriber Agreement."}
      </p>
      {!shouldIncludeTOSInModal && (
        <p>
          <i>
            Note: if your organization has signed a Master Services Agreement ("MSA") with Merge,
            that executed MSA will supersede the acceptance of this updated Subscriber Agreement.
          </i>
        </p>
      )}
    </Dialog>
  );
};

export default UpdatedTermsModal;
