import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import React from "react";

type Props = {
  onClickMethod: () => void;
  isShown: boolean;
  onHide: () => void;
};

export const UseSyncCreditModal = ({ onClickMethod, isShown, onHide }: Props) => {
  return (
    <Dialog
      open={isShown}
      onClose={onHide}
      onPrimaryButtonClick={onClickMethod}
      onSecondaryButtonClick={onHide}
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
      primaryButtonText="Resync data"
      secondaryButtonText="Cancel"
      title="Resync data"
      variant="sm"
    >
      <p>This will use one of your sync credits.</p>
      <p>Do you wish to continue?</p>
    </Dialog>
  );
};
