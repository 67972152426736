import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { sortOrganizationIntegrationSettings } from "../../../../api-client/organization/OrganizationIntegrationSettingsAPIClient";
import { OrganizationIntegrationSettings } from "../../../../models/Entities";
import { showErrorToast } from "../../../shared/Toasts";
import IntegrationSettingsRow from "./IntegrationSettingsRow";

interface Props {
  category: string;
  organizationIntegrationsSettings: Array<OrganizationIntegrationSettings>;
  onIntegrationUpdate: any;
}

const SortableIntegrationContainer = ({
  organizationIntegrationsSettings,
  onIntegrationUpdate,
}: Props) => {
  const [sortableOrganizationIntegrationsSettings, setSortableOrganizationIntegrationsSettings] =
    useState<Array<OrganizationIntegrationSettings>>(organizationIntegrationsSettings);

  useEffect(() => {
    setSortableOrganizationIntegrationsSettings(organizationIntegrationsSettings);
  }, [organizationIntegrationsSettings]);

  const onIntegrationToggle = (updatedOrgIntegration: OrganizationIntegrationSettings) => {
    setLastUpdatedOrgIntegration(updatedOrgIntegration);
    onIntegrationUpdate(updatedOrgIntegration, sortableOrganizationIntegrationsSettings);
  };

  const [lastUpdatedOrgIntegration, setLastUpdatedOrgIntegration] =
    useState<OrganizationIntegrationSettings | null>();

  const SortableItem = SortableElement<{
    organizationIntegrationSettings: OrganizationIntegrationSettings;
  }>(
    ({
      organizationIntegrationSettings,
    }: {
      organizationIntegrationSettings: OrganizationIntegrationSettings;
    }) => {
      return (
        <IntegrationSettingsRow
          key={organizationIntegrationSettings.integration.id}
          organizationIntegrationSettings={organizationIntegrationSettings}
          onIntegrationToggle={onIntegrationToggle}
          initiallyExpanded={
            (lastUpdatedOrgIntegration &&
              organizationIntegrationSettings.id == lastUpdatedOrgIntegration.id) ||
            false
          }
        />
      );
    },
  );

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (sortableOrganizationIntegrationsSettings) {
      const revertState = Object.assign([], sortableOrganizationIntegrationsSettings);
      const orgIntegrationId = sortableOrganizationIntegrationsSettings[oldIndex].id;
      setSortableOrganizationIntegrationsSettings(
        arrayMove(sortableOrganizationIntegrationsSettings, oldIndex, newIndex),
      );
      sortOrganizationIntegrationSettings({
        settingsId: orgIntegrationId,
        displayOrder: newIndex,
        onSort: () => {},
        onSortError: () => {
          const errorName = sortableOrganizationIntegrationsSettings[newIndex].integration.name;
          setSortableOrganizationIntegrationsSettings(revertState);
          showErrorToast(`Whoops! Something went wrong sorting ${errorName}. Please try again`);
        },
      });
    }
  };

  const SortableList = SortableContainer<{ items: OrganizationIntegrationSettings[] }>(
    ({ items }: { items: OrganizationIntegrationSettings[] }) => {
      return (
        <ListGroup>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              organizationIntegrationSettings={value}
            />
          ))}
        </ListGroup>
      );
    },
  );

  return (
    <>
      {sortableOrganizationIntegrationsSettings && (
        <SortableList
          items={sortableOrganizationIntegrationsSettings}
          onSortEnd={onSortEnd}
          lockAxis="y"
          useDragHandle
          helperClass="active-sorted-row"
          lockToContainerEdges
        />
      )}
    </>
  );
};

export default SortableIntegrationContainer;
