import { useEffect, useState } from "react";
import useValidateLinkedAccountCondition from "./useValidateLinkedAccountConditions";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../models/Entities";
import { immutibleArray } from "../../../../../../utils/immutible";
import usePublishLinkedAccountConditions from "./usePublishLinkedAccountConditions";
import { getParsedValueValue } from "../components/LinkedAccountConditionInputCombo/helpers";

type UseManageLinkedAccountConditionsProps = {
  linkedAccountId: string;
  remoteLinkedAccountConditions?: LinkedAccountCondition[];
  linkedAccountConditionMeta?: LinkedAccountConditionMeta;
  refetch: () => void;
};

/**
 * Hook for managing state of condition presets.
 *
 * We maintain a local state of all condition presets, and then on save
 * diff between remote and local conditions to make API calls.
 */
const useManageLinkedAccountConditions = ({
  linkedAccountId,
  remoteLinkedAccountConditions,
  linkedAccountConditionMeta,
  refetch,
}: UseManageLinkedAccountConditionsProps) => {
  const [linkedAccountConditions, setLinkedAccountConditions] = useState<
    Partial<LinkedAccountCondition>[]
  >([]);

  // hooks
  const {
    validateChanges,
    linkedAccountConditionErrors,
    onLinkedAccountConditionErrorChange,
    onLinkedAccountConditionErrorDelete,
  } = useValidateLinkedAccountCondition({
    linkedAccountConditions,
  });
  const { publishChanges, publishing, changes, hasChanges } = usePublishLinkedAccountConditions({
    linkedAccountId,
    linkedAccountConditions,
    remoteLinkedAccountConditions,
    linkedAccountConditionMeta,
    refetch,
  });

  // effects
  // update condition presets when remote values changes
  useEffect(() => {
    if (!linkedAccountConditionMeta || !remoteLinkedAccountConditions) return;

    const clonedLinkedAccountConditions = remoteLinkedAccountConditions?.map(
      (linkedAccountCondition) => {
        return {
          ...linkedAccountCondition,
          value: getParsedValueValue(linkedAccountCondition, linkedAccountConditionMeta),
        };
      },
    );

    setLinkedAccountConditions(
      clonedLinkedAccountConditions?.length ? clonedLinkedAccountConditions : [],
    );
  }, [linkedAccountConditionMeta, remoteLinkedAccountConditions]);

  // eventHandlers
  const onLinkedAccountConditionChange = (
    linkedAccountCondition: Partial<LinkedAccountCondition>,
    index: number,
  ) => {
    setLinkedAccountConditions(
      immutibleArray.replace(linkedAccountConditions, index, linkedAccountCondition),
    );
    onLinkedAccountConditionErrorChange(index);
  };

  const onLinkedAccountConditionDelete = (index: number) => {
    setLinkedAccountConditions(immutibleArray.remove(linkedAccountConditions, index));
    onLinkedAccountConditionErrorDelete(index);
  };

  const onLinkedAccountConditionAdd = () => {
    setLinkedAccountConditions(immutibleArray.append(linkedAccountConditions, {}));
  };

  return {
    linkedAccountConditions,
    linkedAccountConditionErrors,
    onLinkedAccountConditionChange,
    onLinkedAccountConditionDelete,
    onLinkedAccountConditionAdd,
    changes,
    hasChanges,
    publishing,
    publishChanges,
    validateChanges,
  };
};

export default useManageLinkedAccountConditions;
