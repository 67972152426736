import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { ArrowRightToLine } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface AsyncPassthroughWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
}

function AsyncPassthroughWebhook(props: AsyncPassthroughWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType, onUnselectingWebhookType } = props;
  const selected = selectedWebhookType?.has(SelectedWebhookType.ASYNC_PASSTHROUGH) || false;
  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Async Passthrough completed"
        description="Receive an alert when an async passthrough request has resolved"
        icon={<ArrowRightToLine size={16} />}
        onClick={() => {
          if (selected) {
            onUnselectingWebhookType(SelectedWebhookType.ASYNC_PASSTHROUGH);
          } else {
            onSelectedWebhookTypeChange(SelectedWebhookType.ASYNC_PASSTHROUGH);
          }
        }}
        selected={selected}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default AsyncPassthroughWebhook;
