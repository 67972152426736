import React from "react";
import { TextFieldVariant, Typeahead } from "@merge-api/merge-javascript-shared";
import { LinkedAccountCondition } from "../../../../../../../../models/Entities";
import { enumCaseToLowerCase } from "../../../../../../../../utils/casing";

type EnumValueSelectProps = {
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
  enumValueOptions: string[];
};

const EnumValueSelect = ({
  linkedAccountCondition,
  onLinkedAccountConditionChange,
  enumValueOptions,
}: EnumValueSelectProps) => {
  // derived state
  const value = linkedAccountCondition.value?.toString();

  // event handlers
  const onValueChange = (_: any, valueOption: string | null) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      value: valueOption || undefined,
    });
  };

  return (
    <div className="flex flex-1">
      <Typeahead
        className="h-full py-[3px]"
        borderVariant={TextFieldVariant.InlinedRounded}
        placeholder="Select field..."
        options={enumValueOptions}
        disableClearable
        getOptionLabel={(option) => enumCaseToLowerCase(option)}
        value={value}
        onChange={onValueChange}
      />
    </div>
  );
};

export default EnumValueSelect;
