import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useAppContext from "../../../../context/useAppContext";
import { Collapse } from "react-bootstrap";
import { CodeBlockFileDownloadInfo } from "../../../../shared/MergeCodeBlock";
import { APILogResponseBodyPayload, LogType } from "../../../IntegrationsManagementEntities";
import LogSidePanelLoading from "./LogSidePanelLoading";
import { LogSidePanelBody } from "./body/LogSidePanelBody";
import { LogSidePanelNotFound } from "./error-views/LogSidePanelNotFound";
import { LogSidePanelUnauthorized } from "./error-views/LogSidePanelUnauthorized";
import { LogSidePanelBanner } from "./banner/LogSidePanelBanner";
import Divider from "../../../../shared/Divider";

type Props = {
  title: string;
  fetchLog: (props: {
    logID: string;
    onSuccess: (data: LogType) => void;
    onError: (error: Response | undefined) => void;
  }) => void;
  fetchResponseBody?: (props: {
    logID: string;
    onSuccess: (data: APILogResponseBodyPayload) => void;
  }) => void;
};

export const LogSidePanel = ({ title, fetchLog, fetchResponseBody }: Props) => {
  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const { logID } = useParams<{ logID: string }>();
  const [logEntry, setLogEntry] = useState<undefined | LogType>();
  const [logResponseBody, setLogResponseBody] = useState<undefined | string>();
  const [logDoesNotExist, setLogDoesNotExist] = useState<boolean>(false);
  const [isRedacted, setIsRedacted] = useState<boolean>(false);
  const [logResponseBodyDownloadInfo, setLogResponseBodyDownloadInfo] = useState<
    CodeBlockFileDownloadInfo | undefined
  >();

  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation();
  const isSidePanelShown = logID !== undefined;
  const { isUserPrivileged } = useAppContext();

  // Suspend the document scrolling
  document.body.style.overflow = "hidden";

  const onEscPressed = useCallback((event: any) => {
    if (event.key === "Escape") {
      onClickExit();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onEscPressed, false);

    return () => {
      document.removeEventListener("keydown", onEscPressed, false);
    };
  }, [onEscPressed]);

  useEffect(() => {
    let isCancelled = false;

    const fetchWithRetry = async (retryCount = 10) => {
      if (retryCount === 0) {
        if (!isCancelled) {
          setLogDoesNotExist(true);
        }
        return;
      }

      fetchLog({
        logID: logID,
        onSuccess: (data) => {
          if (!isCancelled) {
            setLogEntry(data);
            setIsRedacted(data.is_redacted ?? false);
          }
        },
        onError: async (error) => {
          if (error?.status === 404) {
            await sleep(2500);
            fetchWithRetry(retryCount - 1);
          } else {
            if (!isCancelled) {
              setLogDoesNotExist(true);
            }
          }
        },
      });
    };

    if (logID) {
      fetchWithRetry();
    }

    return () => {
      isCancelled = true;
    };
  }, [fetchLog, logID]);

  useEffect(() => {
    if (logEntry) {
      if (fetchResponseBody !== undefined) {
        fetchResponseBody({
          logID,
          onSuccess: (data: APILogResponseBodyPayload) => {
            setLogResponseBody(data.response_body);
            if (data.download_link !== undefined && data.file_size != undefined) {
              // Response Body is link, not Object
              const previewMessage = `Download full response as file (${(
                data.file_size / 1000000
              ).toFixed(1)} MB)`;
              setLogResponseBodyDownloadInfo({
                downloadLink: data.download_link,
                previewMessage: previewMessage,
              });
            }
          },
        });
      } else {
        setLogResponseBody(logEntry.response_body);
      }
    }
  }, [fetchResponseBody, logEntry, logID]);

  useOnClickOutside(ref, () => {
    onClickExit();
  });

  const onClickExit = () => {
    if (history.location.state === undefined) {
      const lastSlashIndex = location.pathname.lastIndexOf("/");
      const baseUrl = location.pathname.substring(0, lastSlashIndex);
      history.push(baseUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <Collapse dimension="width" in={isSidePanelShown}>
        <div
          className="flex flex-col log-side-panel col-md-9 h-full position-fixed overflow-hidden"
          ref={ref}
        >
          {logEntry ? (
            isUserPrivileged ? (
              <>
                <LogSidePanelBanner title={title} logEntry={logEntry} onClickExit={onClickExit} />
                <Divider noMargin />
                <LogSidePanelBody
                  logEntry={logEntry}
                  hasDynamicResponseBody={fetchResponseBody !== undefined}
                  responseBody={logResponseBody}
                  downloadInfo={logResponseBodyDownloadInfo}
                  isRedacted={isRedacted}
                />
              </>
            ) : (
              <>
                <LogSidePanelBanner title={title} logEntry={logEntry} onClickExit={onClickExit} />
                <Divider noMargin />
                <LogSidePanelUnauthorized />
              </>
            )
          ) : logDoesNotExist ? (
            <LogSidePanelNotFound onClickExit={onClickExit} />
          ) : (
            <LogSidePanelLoading title={title} onClickExit={onClickExit} />
          )}
        </div>
      </Collapse>
      <div className={classNames("opaque-backdrop", isSidePanelShown ? "show" : "fade")} />
    </>
  );
};
