import React, { useState } from "react";
import { Modal, Card } from "@merge-api/merge-javascript-shared";
import {
  LinkedAccount,
  FieldMappingTarget,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingOptionsResponse,
  FieldMappingCreationAndEditDict,
  FieldMappingPathways,
  FieldMappingModalStep,
} from "../../../../../../models/Entities";
import { FieldMappingCommonModelConfiguration } from "../../../../../../models/Entities";
import FieldMappingModalContextState from "./context/FieldMappingModalContext";
import { getModalContent } from "./helpers";

type FieldMappingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  linkedAccount: LinkedAccount;
  commonModelsForIntegration: Array<string>;
  linkedAccountMappingInfo: FieldMappingMetaResponse;
  commonModelsToMappingConfigs: { [common_model_id: string]: FieldMappingCommonModelConfiguration };
  fieldMappingTargets: Array<FieldMappingTarget>;
  fieldMappingInstances: Array<FieldMappingInstance>;
  linkedAccountFieldMappingOptions: FieldMappingOptionsResponse | undefined;
  setCustomMappingMetaResponse: (data: FieldMappingMetaResponse) => void;
};

const FieldMappingModal = ({
  isOpen,
  onClose,
  linkedAccount,
  linkedAccountMappingInfo,
  commonModelsForIntegration,
  commonModelsToMappingConfigs,
  fieldMappingTargets,
  fieldMappingInstances,
  linkedAccountFieldMappingOptions,
  setCustomMappingMetaResponse,
}: FieldMappingModalProps) => {
  const [modalStep, setModalStep] = useState<FieldMappingModalStep>(
    FieldMappingModalStep.FIELD_MAPPING_OPTION_SELECTION,
  );
  const [selectedFieldMappingPathway, setSelectedFieldMappingPathway] =
    useState<FieldMappingPathways>(FieldMappingPathways.LINKED_ACCOUNT_SPECIFIC_MAPPING);
  const [selectedRemoteField, setSelectedRemoteField] =
    useState<FieldMappingCreationAndEditDict | null>(null);
  const [jmesPath, setJmesPath] = useState<string | null>(null); // TODO @azhou: might be able to be subsumed into selectedRemoteField

  const modalContent = getModalContent(modalStep);
  return (
    <FieldMappingModalContextState.Provider
      value={{
        linkedAccount: linkedAccount,
        setModalStep: setModalStep,
        onModalClose: onClose,
        linkedAccountMappingInfo: linkedAccountMappingInfo,
        commonModelsForIntegration: commonModelsForIntegration,
        commonModelsToMappingConfig: commonModelsToMappingConfigs,
        linkedAccountFieldMappingOptions: linkedAccountFieldMappingOptions,
        fieldMappingTargets: fieldMappingTargets,
        fieldMappingInstances: fieldMappingInstances,
        selectedRemoteField: selectedRemoteField,
        setSelectedRemoteField: setSelectedRemoteField,
        selectedFieldMappingPathway: selectedFieldMappingPathway,
        setSelectedFieldMappingPathway: setSelectedFieldMappingPathway,
        jmesPath: jmesPath,
        setJmesPath: setJmesPath,
        setCustomMappingMetaResponse: setCustomMappingMetaResponse,
      }}
    >
      <Modal onClose={onClose} open={isOpen} animate>
        <Card className="flex flex-col w-[800px] pt-[18px] pb-6 transition-all duration-1000 ease-in-out">
          {modalContent}
        </Card>
      </Modal>
    </FieldMappingModalContextState.Provider>
  );
};

export default FieldMappingModal;
