import { MoreHorizontal } from "lucide-react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { OrganizationBillingPlan, PaymentMethod, isCreditCard } from "../BillingModels";
import { imageURLForCardBrand as imageURLForPaymentMethod, toTitleCase } from "../BillingUtils";
import BillingAutoPayToggle from "./BillingAutoPayToggle";
import { Button, ButtonVariant, Menu, MenuItem, Text } from "@merge-api/merge-javascript-shared";

type Props = {
  paymentMethod: PaymentMethod;
  settingDefault: boolean;
  billingPlan: OrganizationBillingPlan | undefined;
  updateBillingPlan: (billingPlan: OrganizationBillingPlan) => void;
  onClickSetDefault: () => void;
  setPaymentMethodDeletionTarget: (paymentMethod: PaymentMethod) => void;
};

const PaymentMethodRow = ({
  paymentMethod,
  settingDefault,
  billingPlan,
  updateBillingPlan,
  onClickSetDefault,
  setPaymentMethodDeletionTarget,
}: Props) => {
  return (
    <div className="list-group-item">
      <Row className="row align-items-center">
        <Col className="col-auto">
          <img
            className="h-auto max-w-[38px]"
            src={imageURLForPaymentMethod(paymentMethod)}
            alt="..."
          />
        </Col>
        <Col className="ml-n2">
          <Text variant="h6">
            {isCreditCard(paymentMethod)
              ? toTitleCase(paymentMethod.brand) + " "
              : paymentMethod.bank_name + " account "}
            ending in {paymentMethod.last4}
          </Text>
          {isCreditCard(paymentMethod) && (
            <Text className="text-gray-60">
              Expires {paymentMethod.exp_month}/{paymentMethod.exp_year}
            </Text>
          )}
        </Col>
        <Col className="col-auto mr-n3" style={{ display: "flex", alignItems: "center" }}>
          {billingPlan && paymentMethod.is_default_method && (
            <>
              <BillingAutoPayToggle
                shouldAutoCharge={billingPlan.should_auto_charge}
                updateBillingPlan={updateBillingPlan}
              />
              <Button size="sm" variant={ButtonVariant.TertiaryWhite} disabled>
                Default
              </Button>
            </>
          )}
        </Col>
        <Col className="col-auto">
          {paymentMethod.is_default_method || (
            <Button
              size="sm"
              variant={ButtonVariant.SecondaryCharcoal}
              onClick={() => onClickSetDefault()}
              loading={settingDefault}
            >
              Make default
            </Button>
          )}
        </Col>
        {!paymentMethod.is_default_method && (
          <Col className="col-auto">
            <Menu
              ButtonProps={{
                children: <MoreHorizontal size={16} />,
                variant: ButtonVariant.IconShadowHidden,
              }}
            >
              <MenuItem onClick={() => setPaymentMethodDeletionTarget(paymentMethod)}>
                Delete
              </MenuItem>
            </Menu>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PaymentMethodRow;
