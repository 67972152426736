import * as Sentry from "@sentry/react";
import Cookies from "universal-cookie";
import { UserWithBillingPlan } from "../components/context/AppContext";
import { showErrorToast, showInfoToast, showSuccessToast } from "../components/shared/Toasts";
import { SEC_IN_YEAR, SEC_IN_DAY, FieldMappingSource } from "../constants";
import {
  LinkedAccount,
  User,
  CommonModelToggle,
  CateogryScopeMap,
  CommonModelField,
  CommonModelFieldMap,
} from "../models/Entities";
import { LOGIN_PATH } from "../router/RouterUtils";
import { createFieldMappingOptions } from "../components/pages/configuration/field-mappings/utils/FieldMappingUtils";
import { CSRF_COOKIE_NAME } from "../hooks/useFetchCSRFToken";
import {
  IntegrationCustomization,
  OrganizationCustomization,
} from "../components/pages/configuration/link/context/CustomizationContext";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { WhiteLabelGuide } from "../components/pages/configuration/link/pages/ConfigurationWhiteLabelGuideSettingsPage";

export interface UserSuccessData {
  token: string;
  user: User;
  created: boolean;
  organization: any;
}

export interface MagicLinkResponse {
  magic_link_url: string;
}
export interface FormErrorData {
  [key: string]: string[];
}

export interface ConflictErrorData {
  [key: string]: string;
}

export interface MultipleFormErrorData {
  [index: number]: FormErrorData;
}

export interface PaginatedAPIResponse<T> {
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface SSOLoginData {
  sso_url: string;
}

export enum Tenancy {
  SingleTenant,
  NAMultiTenant,
  EUMultiTenant,
  APMultiTenant,
  Local,
}

export const VALID_TENANT_URLS = [
  "https://api.merge.dev",
  "https://api-eu.merge.dev",
  "https://api-ap.merge.dev",
  "https://api-sandbox.merge.dev",
  "https://api-oyster-eu.merge.dev",
  "https://api-tripactions-eu.merge.dev",
  "https://api-payfit.merge.dev",
  "https://api-develop.merge.dev",
  "http://localhost:8000",
];

export const TENANT_URL_TO_FRONTEND_URL: Record<string, string> = {
  "https://api-develop.merge.dev": "https://develop.merge.dev",
  "http://localhost:8000": "http://localhost:3000",
};

const AUTH_TOKEN_INDICATOR = "authentication_token_indicator";

/**
 * This generic `Result` type enables an API promise to return either a result or an error with one return
 * value. Use it everywhere possible with a `Promise` wrapped around it so our API client calls can become:
 * ```
 * const result = await fetchDataFromAPI(inputInfo)
 * if (result.status === 'success') {
 *     ...
 * } else {
 *     ...
 * }
 * ```
 */
export type Result<ResultType, ErrorType = Response | undefined> =
  | {
      status: "success";
      data: ResultType;
    }
  | { status: "error"; error: ErrorType };

export const API_DOMAIN = (() => {
  switch (process.env.REACT_APP_MERGE_ENV) {
    case "PRODUCTION":
      const base_url = process.env.REACT_APP_BASE_API_URL;
      if (!base_url) {
        Sentry.captureMessage("Base API URL not found for production dashboard.");
      }
      return base_url;
    case "SANDBOX":
      return "https://api-sandbox.merge.dev";
    case "DEVELOP":
      return "https://api-develop.merge.dev";
    case "LOCAL":
    default:
      return "http://localhost:8000";
  }
})();

export const getTenancy = (): Tenancy => {
  const mergeEnv = process.env.REACT_APP_MERGE_ENV;
  if (!mergeEnv || mergeEnv == "LOCAL") {
    return Tenancy.Local;
  }

  const baseAPIURL = process.env.REACT_APP_BASE_API_URL;
  if (baseAPIURL == "https://api.merge.dev") {
    return Tenancy.NAMultiTenant;
  }

  if (baseAPIURL == "https://api-eu.merge.dev") {
    return Tenancy.EUMultiTenant;
  }

  if (baseAPIURL == "https://api-ap.merge.dev") {
    return Tenancy.APMultiTenant;
  }

  return Tenancy.SingleTenant;
};

export const apiURLForPath = (path: string): string => {
  const baseURL = `${API_DOMAIN}/api`;
  if (path.charAt(0) != "/") {
    return `${baseURL}/${path}`;
  }
  return baseURL + path;
};

export const apiURLForPathWithOverrideUrl = (url: string, path: string): string => {
  const baseURL = `${url}/api`;
  if (path.charAt(0) != "/") {
    return `${baseURL}/${path}`;
  }
  return baseURL + path;
};

export const fetchWithoutAuth = ({
  path,
  method = HTTPMethod.GET,
  headers = {},
  body,
  onResponse,
  onError,
}: {
  path: string;
  method?: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}): Promise<void> => {
  const url = apiURLForPath(path);
  return fetchWithoutAuthHelper({
    url,
    method,
    headers,
    body,
    onResponse,
    onError,
  });
};

export const fetchWithoutAuthWithBaseAPIUrlOverride = ({
  path,
  method = HTTPMethod.GET,
  headers = {},
  body,
  overrideBaseAPIUrl,
  onResponse,
  onError,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  overrideBaseAPIUrl: string;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  const url = apiURLForPathWithOverrideUrl(overrideBaseAPIUrl, path);
  fetchWithoutAuthHelper({
    url,
    method,
    headers,
    body,
    onResponse,
    onError,
  });
};

export const fetchWithoutAuthHelper = async ({
  url,
  method,
  headers = {},
  body,
  onResponse,
  onError,
}: {
  url: string;
  method: string;
  headers: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  const cookies = new Cookies();
  const CSRFToken = await cookies.get(CSRF_COOKIE_NAME);

  const updatedHeaders: { [key: string]: string } = {
    ...headers,
    "Content-Type": "application/json;charset=UTF-8",
    "X-CSRFToken": CSRFToken,
  };

  try {
    const response = await fetch(url, {
      method,
      headers: updatedHeaders,
      body: body ? JSON.stringify(body) : null,
      credentials: "include",
    });

    if (!response.ok) {
      throw response;
    }

    const responseJson = await response.json();
    onResponse(responseJson);
  } catch (error: any) {
    if (onError) {
      onError(error && "json" in error ? error : undefined);
    }
  }
};

export const fetchWithAuth = async <DataType extends any = any>({
  path,
  method = HTTPMethod.GET,
  headers = {},
  body,
  onResponse,
  onError,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: DataType) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  let updatedHeaders: { [key: string]: string } | Headers = headers || {};
  let updatedBody: any = body && method !== HTTPMethod.GET ? JSON.stringify(body) : null;
  const cookies = new Cookies();
  const authToken = await cookies.get("authentication_token");
  const CSRFToken = await cookies.get(CSRF_COOKIE_NAME);

  let hasFile = false;
  for (const key in body) {
    if (body[key] instanceof Blob || body[key] instanceof File) {
      hasFile = true;
    }
  }

  if (hasFile) {
    updatedHeaders = new Headers();
    for (const key in headers) {
      updatedHeaders.append(key, headers[key]);
    }

    updatedBody = null;
    if (body) {
      updatedBody = new FormData();
      for (const key in body) {
        if (body[key] instanceof File) {
          updatedBody.append(key, body[key], body[key].name);
        } else {
          updatedBody.append(key, body[key]);
        }
      }
    }
    if (authToken) {
      updatedHeaders.append("Authorization", `Token ${authToken}`);
    }
    if (CSRFToken) {
      updatedHeaders.append("X-CSRFToken", CSRFToken);
    }
  } else {
    if (authToken) {
      updatedHeaders.Authorization = `Token ${authToken}`;
    }
    if (CSRFToken) {
      updatedHeaders["X-CSRFToken"] = CSRFToken;
    }
    updatedHeaders["Content-Type"] = "application/json;charset=UTF-8";
  }

  const url = apiURLForPath(path);

  try {
    const response = await fetch(url, {
      method,
      headers: updatedHeaders,
      body: updatedBody,
      credentials: "include",
    });

    if (response.status === 403) {
      const data = await response.json();

      if (["Invalid token.", "Token has expired."].includes(data.detail)) {
        removeAuthTokenAndUserType();
        window.location.href = LOGIN_PATH;
      }

      throw response;
    }

    if (!response.ok) {
      throw response;
    }

    const data = response.status === 204 ? {} : await response.json();
    onResponse(data);
  } catch (error: any) {
    if (onError) {
      onError(error && "json" in error ? error : undefined);
    }
  }
};

/**
 * Same as above fetchWithAuth, but just returns a promise with the response from fetch and does NOT handle formData logic.
 */
export const fetchWithAuthAsync = async <T extends unknown>({
  path,
  method = HTTPMethod.GET,
  headers = {},
  body,
  signal,
}: {
  path: string;
  method?: string;
  headers?: { [key: string]: string };
  body?: any;
  signal?: RequestInit["signal"];
}): Promise<
  Omit<Response, "body"> & {
    body: T;
  }
> => {
  let updatedHeaders: { [key: string]: string } | Headers = headers || {};
  let updatedBody: any = body && method !== HTTPMethod.GET ? JSON.stringify(body) : null;

  const cookies = new Cookies();
  const authToken = await cookies.get("authentication_token");
  const CSRFToken = await cookies.get(CSRF_COOKIE_NAME);
  let hasFile = false;
  for (const key in body) {
    if (body[key] instanceof Blob || body[key] instanceof File) {
      hasFile = true;
    }
  }
  if (hasFile) {
    updatedHeaders = new Headers();
    for (const key in headers) {
      updatedHeaders.append(key, headers[key]);
    }

    updatedBody = null;
    if (body) {
      updatedBody = new FormData();
      for (const key in body) {
        if (body[key] instanceof File) {
          updatedBody.append(key, body[key], body[key].name);
        } else {
          updatedBody.append(key, body[key]);
        }
      }
    }
    if (authToken) {
      updatedHeaders.append("Authorization", `Token ${authToken}`);
    }
    if (CSRFToken) {
      updatedHeaders.append("X-CSRFToken", CSRFToken);
    }
  } else {
    if (authToken) {
      updatedHeaders.Authorization = `Token ${authToken}`;
    }
    if (CSRFToken) {
      updatedHeaders["X-CSRFToken"] = CSRFToken;
    }
    updatedHeaders["Content-Type"] = "application/json;charset=UTF-8";
  }

  const url = apiURLForPath(path);

  const response = await fetch(url, {
    method,
    headers: updatedHeaders,
    body: updatedBody,
    signal,
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return {
    ...response,
    body: await response.json(),
  };
};

/**
 * Used in API tester. Makes fetch request using API Key + LinkedAccount account_token.
 */
export const fetchWithAPIKey = async ({
  path,
  method,
  headers = {},
  body,
  onResponse,
  onError,
  apiKey,
  accountToken,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: Response) => void;
  onError?: (response: Response | undefined) => void;
  apiKey: string;
  accountToken: string;
}) => {
  const cookies = new Cookies();
  const updatedHeaders: { [key: string]: string } | Headers = headers || {};

  const CSRFToken = await cookies.get(CSRF_COOKIE_NAME);

  if (CSRFToken) {
    updatedHeaders["X-CSRFToken"] = CSRFToken;
  }

  updatedHeaders["X-ACCOUNT-TOKEN"] = accountToken;
  updatedHeaders.Authorization = `Bearer ${apiKey}`;

  const url = apiURLForPath(path);

  try {
    const response = await fetch(url, {
      method,
      body: method === HTTPMethod.GET ? null : body,
      headers: updatedHeaders,
    });

    onResponse(response);
  } catch (error: any) {
    if (onError) {
      onError(error && "json" in error ? error : undefined);
    }
  }
};

export const fetchCurrentUser = (setUser: (response: any) => void) => {
  fetchWithAuth({
    path: "/users/me",
    method: HTTPMethod.GET,
    onResponse: (data: UserWithBillingPlan) => {
      const cookies = new Cookies();
      cookies.set("user_type", data.type, {
        maxAge: SEC_IN_YEAR,
        secure: true,
      });
      setUser(data);
    },
  });
};

export const fetchLinkedAccountFieldMappingInstances = (
  linkedAccountID: string,
  setFieldMappingInstances: (response: any) => void,
  onSuccess: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mappings/${linkedAccountID}/field-mapping-instances`,
    method: HTTPMethod.GET,
    onResponse: (data) => {
      setFieldMappingInstances(data);
      onSuccess();
    },
  });
};

export const fetchFieldMappingInstance = (
  fieldMappingInstanceID: string,
  setFieldMappingInstance: (response: any) => void,
  callBack?: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mapping-instance/${fieldMappingInstanceID}`,
    method: HTTPMethod.GET,
    onResponse: (data: any) => {
      setFieldMappingInstance(data);
      if (callBack) {
        callBack();
      }
    },
  });
};

export const editFieldMappingInstance = (
  fieldMappingInstanceID: string,
  editedFieldMappingInstance: EditFieldMappingInstanceProps,
  onResponse: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mapping-instance/${fieldMappingInstanceID}`,
    body: editedFieldMappingInstance,
    method: HTTPMethod.PATCH,
    onResponse,
  });
};

export const fetchIntegrationWideFieldMappingOptions = (
  integrationId: string,
  fieldMappingTargetId: string,
  commonModel: string,
  setFieldMappingOptions: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/field-mappings/${integrationId}/${fieldMappingTargetId}/meta`,
    method: HTTPMethod.GET,
    onResponse: (data: any) => {
      setFieldMappingOptions(createFieldMappingOptions(data.available_field_mappings, commonModel));
    },
    onError: () => {
      showErrorToast("Unable to fetch Field Mapping options");
    },
  });
};

export const fetchIntegrationWideOverrideOptions = (
  integrationId: string,
  overrideTargetID: string,
  commonModel: string,
  setFieldMappingOptions: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/field-mappings/${integrationId}/${overrideTargetID}/meta?is_common_model_override=True`,
    method: HTTPMethod.GET,
    onResponse: (data: any) => {
      setFieldMappingOptions(data.available_field_mappings);
    },
    onError: () => {
      showErrorToast("Unable to fetch Field Mapping options");
    },
  });
};

export const deleteLinkedAccountFieldMapping = (
  fieldMappingID: string,
  refreshFieldMappings?: () => void,
  onSuccess?: (data: any) => void,
  skipFieldMappingRefresh?: boolean,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mappings/${fieldMappingID}/delete`,
    method: HTTPMethod.DELETE,
    onResponse: (data: any) => {
      if (refreshFieldMappings && !skipFieldMappingRefresh) {
        refreshFieldMappings();
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });
};

export type NewFieldMappingProps = {
  linked_account_id: string;
  common_model_id: string;
  field_key: string;
  origin_type: string;
  field_traversal_path: Array<string>;
  field_description?: string;
  configured_by: FieldMappingSource;
  create_for_organization: boolean;
  field_mapping_target_id?: string;
  api_endpoint_id: string;
  display_name: string;
  jmes_path?: string | null;
};

export type NewIntegrationWideFieldMapping = {
  integration_id: string;
  organization_id: string;
  common_model_id: string;
  field_key: string;
  origin_type: string;
  field_traversal_path: Array<string>;
  field_description?: string;
  configured_by: FieldMappingSource;
  create_for_organization: boolean;
  field_mapping_target_id?: string;
  api_endpoint_id: string;
  display_name: string;
  enable_linked_account_level_overrides?: boolean;
};

export type EditFieldMappingInstanceProps = {
  field_mapping_instance_id: string;
  field_traversal_path: Array<string>;
  field_description?: string;
  api_endpoint_id: string;
  display_name: string;
  enable_linked_account_level_overrides?: boolean;
  origin_type: string;
  jmes_path?: string | null;
};
export const createLinkedAccountFieldMapping = (
  body: NewFieldMappingProps,
  onResponse: (data?: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mappings/create`,
    method: HTTPMethod.POST,
    body,
    onResponse: (data: any) => {
      onResponse(data.field_mapping_instance_id);
    },
  });
};

export const createIntegrationWideFieldMapping = (
  body: NewIntegrationWideFieldMapping,
  isLoading: () => void,
  handleFieldMappingInstanceID?: (id: string) => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mappings/create`,
    method: HTTPMethod.POST,
    body,
    onResponse: (data: any) => {
      isLoading();
      handleFieldMappingInstanceID!(data?.field_mapping_instance_id);
    },
    onError: () => showErrorToast("Unable to create Field Mapping Instance"),
  });
};

const isNewAuthTokenMethod = (): boolean => {
  const cookies = new Cookies();
  return !!cookies.get(AUTH_TOKEN_INDICATOR);
};

export const getAuthToken = (): string | null => {
  const cookies = new Cookies();
  // currently we pass this cookie in a way where the frontend and backend can both set and get it - however
  // in the new work we will pass the cookie from the backend in a way where the frontend cant get it (httponly)
  // for this reason passing a second cookie that the frontend can read that only contains an indicator of if the
  // auth token was passed or not
  const cookieName = isNewAuthTokenMethod() ? AUTH_TOKEN_INDICATOR : "authentication_token";
  const authToken: string | null = cookies.get(cookieName);
  return authToken || null;
};

export const hasAuthToken = () => {
  return getAuthToken() !== null;
};

export const getCookieDomainForEnv = () =>
  (process.env.REACT_APP_MERGE_ENV == "PRODUCTION" ||
    process.env.REACT_APP_MERGE_ENV == "DEVELOP") &&
  !process.env.REACT_APP_MERGE_PROD_FROM_LOCAL
    ? ".merge.dev"
    : undefined;

export const setAuthTokenAndUserType = (authToken: string, userType: string) => {
  const cookieDomain = getCookieDomainForEnv();
  const cookies = new Cookies();
  // TODO (Ankit): Remove this after testing - we shouldnt need to manually set this cookie anymore
  if (!isNewAuthTokenMethod()) {
    cookies.set("authentication_token", authToken, {
      maxAge: SEC_IN_DAY,
      domain: cookieDomain,
      path: "/",
      secure: true,
      sameSite: "lax",
    });
  }
  cookies.set("user_type", userType, {
    maxAge: SEC_IN_YEAR,
    secure: true,
    path: "/",
    domain: cookieDomain,
  });
};

export const setUserType = (userType: string) => {
  const cookies = new Cookies();
  const cookieDomain = getCookieDomainForEnv();

  cookies.set("user_type", userType, {
    maxAge: SEC_IN_YEAR,
    secure: true,
    path: "/",
    domain: cookieDomain,
  });
};

export const removeAuthTokenAndUserType = () => {
  const cookies = new Cookies();
  const cookieDomain = getCookieDomainForEnv();

  if (isNewAuthTokenMethod()) {
    cookies.remove(AUTH_TOKEN_INDICATOR, { path: "/", domain: cookieDomain });
  }

  cookies.remove("authentication_token", { path: "/", domain: cookieDomain });
  cookies.remove("user_type", { path: "/", domain: cookieDomain });
  cookies.remove(CSRF_COOKIE_NAME, { path: "/", domain: cookieDomain });
};

export const onLogout = ({ onError }: { onError: () => void }) =>
  fetchWithAuth({
    path: "/users/logout",
    method: HTTPMethod.POST,
    onResponse: () => {
      removeAuthTokenAndUserType();
      window.location.href = LOGIN_PATH;
    },
    onError,
  });

const getCommonModelFieldsMap = (fields: CommonModelField[]) => {
  return fields.reduce((fieldsMap: CommonModelFieldMap, field: CommonModelField) => {
    return {
      ...fieldsMap,
      [field.field_name]: {
        isEnabled: field.is_enabled,
        fieldDescription: field.field_description,
        fieldType: field.field_type,
      },
    };
  }, {});
};

const getLinkedAccountActionOverrides = (commonModelToggleScopeInfo: any) => {
  return commonModelToggleScopeInfo.linked_account_scoped_actions || [];
};
const getLinkedAccountFieldOverrides = (commonModelToggleScopeInfo: any) => {
  return commonModelToggleScopeInfo.linked_account_scoped_fields || [];
};

export const createScopeMap = (commonModelToggles: CommonModelToggle[]) => {
  const commonModelTogglesMap = commonModelToggles.reduce(
    (commonModelTogglesMap: CateogryScopeMap, commonModelToggle: CommonModelToggle) => {
      const commonModelFieldsMap = getCommonModelFieldsMap(commonModelToggle.fields);
      const linkedAccountActionOverrides = getLinkedAccountActionOverrides(
        commonModelToggle.scope_level_info,
      );
      const linkedAccountFieldOverrides = getLinkedAccountFieldOverrides(
        commonModelToggle.scope_level_info,
      );

      return {
        ...commonModelTogglesMap,
        [commonModelToggle.name]: {
          actions: commonModelToggle.enabled_model_actions,
          capabilities: commonModelToggle.model_capabilities,
          model_description: commonModelToggle.model_description,
          optional_actions: commonModelToggle.end_user_configurable_actions,
          fields: commonModelFieldsMap,
          linkedAccountActionOverrides: linkedAccountActionOverrides,
          linkedAccountFieldOverrides: linkedAccountFieldOverrides,
        },
      };
    },
    {},
  );
  return commonModelTogglesMap;
};

export const getLinkedAccountCommonModelTogglesMap = (
  linkedAccount: LinkedAccount,
  setLinkedAccountLevelCommonModelScopes: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/common-model-toggles/${linkedAccount.category}?linked_account_id=${linkedAccount.id}`,
    method: HTTPMethod.GET,
    onResponse: (commonModelToggles) => {
      setLinkedAccountLevelCommonModelScopes(createScopeMap(commonModelToggles));
    },
  });
};

export const getOrgCommonModelTogglesMap = (
  category: string,
  setOrgLevelCommonModelScopes: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/common-model-toggles/${category}`,
    method: HTTPMethod.GET,
    onResponse: (commonModelToggles) => {
      setOrgLevelCommonModelScopes(createScopeMap(commonModelToggles));
    },
  });
};

export const toggleRedactUnmappedData = (
  enabled: boolean,
  organizationId?: string,
  linkedAccountId?: string,
  onResponse?: (response: {
    feature_enabled_for_organization?: boolean;
    feature_enabled_for_linked_account?: boolean;
  }) => void,
  onError?: (response: Response | undefined) => void,
) => {
  fetchWithAuth({
    path: `integrations/redact-unmapped-data/toggle`,
    method: HTTPMethod.POST,
    body: {
      organization_id: organizationId,
      linked_account_id: linkedAccountId,
      enabled,
    },
    onResponse: (res: {
      feature_enabled_for_organization?: boolean;
      feature_enabled_for_linked_account?: boolean;
    }) => {
      if (onResponse) onResponse(res);
    },
    onError: (err) => {
      if (onError) onError(err);
    },
  });
};

export const postUpgradeInterest = (
  user: User,
  requestedPlanUpgrade: boolean,
  setRequestedPlanUpgrade: (response: any) => void,
) => {
  if (!requestedPlanUpgrade) {
    fetchWithAuth({
      path: `/users/request-upgrade`,
      method: HTTPMethod.PATCH,
      body: { user_email: user.email, time_request: Date.now() },
      onResponse: () => {
        showInfoToast(
          "Successfully requested Plan Upgrade. The Merge team will be in touch shortly!",
        );
        setRequestedPlanUpgrade(true);
      },
      onError: () => {
        showErrorToast("Failed to indicate interest in upgrade. Please try again.");
      },
    });
  }
};

export type CustomizableLinkPostBody = {
  status: string;
  organization_customization_data: OrganizationCustomization | null;
  integration_customizations_data: IntegrationCustomization[] | null;
};

export const publishCustomizableLinkChanges = (body: CustomizableLinkPostBody) => {
  fetchWithAuth({
    path: `/integrations/create-update-customizable-link-settings`,
    method: HTTPMethod.POST,
    body: body,
    onResponse: () => {
      showSuccessToast("Successfully saved changes to Merge Link!");
    },
    onError: () => {
      showErrorToast("Failed to save changes. Please try again.");
    },
  });
};

export const fetchCreateTestLinkToken = (
  setLinkToken: (linkToken: string) => void,
  setAccountLimitReached?: (limitReached: boolean) => void,
) => {
  fetchWithAuth({
    path: "/integrations/create-test-link-token",
    method: HTTPMethod.POST,
    onResponse: (data) => {
      setLinkToken(data.link_token);
    },
    onError: (err: Response | undefined) => {
      if (err && err.status === 403) {
        if (setAccountLimitReached) {
          setAccountLimitReached(true);
          setLinkToken("");
        }
        // Optionally, you can show a toast message here if needed
      } else {
        showErrorToast(
          "Unable to initialize new test Linked Accounts, please check your connection and try again.",
        );
      }
    },
  });
};

export const publishWhiteLabelGuideChanges = (
  whiteLabelGuide: WhiteLabelGuide,
  handleHasSavedWhiteLabelGuide: (handleHasSavedWhiteLabelGuide: boolean) => void,
) => {
  fetchWithAuth({
    path: "/organizations/white-label-guide-configuration",
    method: HTTPMethod.POST,
    body: whiteLabelGuide,
    onResponse: () => {
      showSuccessToast("Successfully saved white-label guides!");
      handleHasSavedWhiteLabelGuide(true);
    },
    onError: () => {
      showErrorToast("Failed to save white-label guides.");
    },
  });
};

export const toggleWhiteLabelGuideEnabled = (active: boolean) => {
  fetchWithAuth({
    path: "/organizations/toggle-white-label-guide-configuration-enabled",
    method: HTTPMethod.PATCH,
    body: { active },
    onResponse: () => {
      showSuccessToast("Successfully saved white-label guides!");
    },
    onError: () => {
      showErrorToast("Failed to save white-label guides.");
    },
  });
};

export const getOrganizationMappingInfo = (
  category: string,
  onSuccess: (data: any) => void,
  onFailure: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization-mapping-info/${category}`,
    method: HTTPMethod.GET,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError: (err) => {
      onFailure();
    },
  });
};
