import React from "react";
import { Text, Card, Tooltip } from "@merge-api/merge-javascript-shared";
import IconForFieldType from "../../../../../../shared/IconForFieldType";
import clsx from "clsx";

interface RemoteFieldCardProps {
  displayName?: string;
  apiEndpointPath?: string;
  badge?: React.ReactNode;
  type?: string | null;
  jmesPath?: string | null;
}

export const RemoteFieldCard = ({
  displayName,
  apiEndpointPath,
  type,
  jmesPath,
  badge,
}: RemoteFieldCardProps) => {
  return (
    <Card variant="outline" className={clsx("flex flex-col p-4 w-[350px] h-fit")}>
      {badge && <div className="mb-2">{badge}</div>}

      <div className="flex flex-col">
        <div className="flex gap-x-1 items-end">
          <Tooltip title={displayName}>
            <div className="flex-grow line-clamp-2 break-all max-w-[300px]">{displayName}</div>
          </Tooltip>
          {type && (
            <div className="flex-shrink-0 pb-[2px]">
              <IconForFieldType size={16} type={type} className="text-gray-60" />
            </div>
          )}
        </div>

        {jmesPath && (
          <div className="flex items-center mt-1">
            <Tooltip title={jmesPath} className="flex flex-grow gap-x-2">
              <>
                <span className="truncate text-sm font-medium text-gray-60">Advanced:</span>
                <span className="text-sm truncate w-[240px] text-black">{jmesPath}</span>
              </>
            </Tooltip>
          </div>
        )}

        {apiEndpointPath && (
          <div className="flex items-center truncate mt-1">
            <Tooltip title={apiEndpointPath} className="flex flex-grow truncate gap-x-2">
              <>
                <Text variant="title-sm" className="text-gray-60">
                  GET
                </Text>
                <span dir="rtl" className="text-sm truncate rtl text-gray-60">
                  {apiEndpointPath ? `${apiEndpointPath}/` : "/"}
                </span>
              </>
            </Tooltip>
          </div>
        )}
      </div>
    </Card>
  );
};

export default RemoteFieldCard;
