import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { fetchCurrentUser, fetchWithAuth } from "../../../../api-client/APIClient";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import SpinnerButton from "../../../shared/SpinnerButton";
import useAppContext from "../../../context/useAppContext";
import { Form } from "react-bootstrap";
import { PaymentMethodTypes } from "./BillingModels";
import { navigateToBillingPage } from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";

type Props = {
  onHide?: () => void;
  isFromUpgrade?: {
    fromUpgradeButton?: boolean;
  };
  //remove after testing: @avirathtib
  freeAccountGated?: boolean;
};

export default function CreditCardSetupForm(props: Props) {
  const BILLING_URI_PREFIX = "/billing";
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const { user, setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState<string>("");
  useEffect(() => {
    fetchWithAuth({
      path: "/billing/stripe/setup-intent",
      method: "POST",
      body: {
        payment_method: PaymentMethodTypes.CREDIT_CARD,
      },
      onResponse: (data) => {
        setSetupIntentClientSecret(data.setup_intent_client_secret);
      },
    });
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (!stripe || !elements || !setupIntentClientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      showErrorToast("Oops, something went wrong! Please try again in 10 seconds.");
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    const result = await stripe.confirmCardSetup(setupIntentClientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: user.name,
        },
      },
    });

    if (result.error) {
      showErrorToast(result.error.message ?? "");
      setIsLoading(false);
    } else {
      showSuccessToast("Credit card added successfully!");

      if (props.onHide) {
        props.onHide();
      }
      if (props.isFromUpgrade?.fromUpgradeButton) {
        fetchWithAuth({
          path: `${BILLING_URI_PREFIX}/update-billing-status`,
          method: "POST",
          onResponse: () => {
            fetchCurrentUser(setUser);
            navigateToBillingPage(history, { refetch: "true", upgraded: "true" });
          },
          onError: (error) => {},
        });
      }

      if (
        props.onHide == null &&
        (props.isFromUpgrade == null || props.isFromUpgrade.fromUpgradeButton == false)
      ) {
        fetchCurrentUser(setUser);
        navigateToBillingPage(history, { refetch: "true" });
      }
    }
  };

  return (
    <Form className="w-100 mt-1">
      {props.freeAccountGated ? (
        <>
          <div className="flex flex-col gap-2">
            <Text variant="h6">Enter your credit card</Text>
            <CardElement />
          </div>
          <div className="flex gap-4 mt-4">
            <Button
              variant={ButtonVariant.SecondaryCharcoal}
              fullWidth
              onClick={() => {
                navigateToBillingPage(history);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(e as React.FormEvent<HTMLElement>);
              }}
              fullWidth
            >
              Confirm
            </Button>
          </div>
        </>
      ) : (
        <>
          <p>Please enter your credit card details below.</p>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          <p className="text-gray-60 mt-3">
            By submitting this information, you authorize Merge to charge your card monthly for the
            amount listed on the billing page.
          </p>
          <Button fullWidth onClick={handleSubmit} loading={isLoading}>
            Save payment method
          </Button>
        </>
      )}
    </Form>
  );
}
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "var(--font-family-sans-serif),sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "15px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#E63757",
      iconColor: "#E63757",
    },
  },
};
