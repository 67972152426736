import React, { useEffect, useState } from "react";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
  OverriddenCommonModelInstance,
  FieldMappingOptionsResponse,
} from "../../../../../models/Entities";
import FieldMappingCommonModels from "./FieldMappingCommonModels";
import { SectionHeaderWrapper } from "../../../../shared/MergeLayouts";
import { LinkedAccountStatuses } from "../../../../../constants";
import {
  getLinkedAccountFieldMappings,
  getFieldMappingMeta,
} from "../../../configuration/field-mappings/utils/FieldMappingUtils";
import FieldMappingUpsellModal from "../../../configuration/field-mappings/ConfigurationFieldMappingsUpsellModal";
import useProductRestrictions from "../../../../shared/hooks/useProductRestrictions";
import { Card, Link, Alert, Text, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import FieldMappingsExplainer from "./fieldMappings.svg";
import useCategoryCommonModels from "../hooks/useCategoryCommonModels";
import { BookOpen, ExternalLink, Plus } from "lucide-react";
import { DOCS_FIELD_MAPPINGS_PATH, openInNewTab } from "../../../../../router/RouterUtils";
import useAppContext from "../../../../context/useAppContext";
import FieldMappingModal from "./modal/FieldMappingModal";

type FieldMappingsProps = {
  fieldMappingInstances: Array<FieldMappingInstance>;
  fieldMappingTargets: Array<FieldMappingTarget>;
  fieldMappingConfigurations: Array<FieldMappingCommonModelConfiguration> | null;
  overriddenCommonModelInstances: Array<OverriddenCommonModelInstance>;
  linkedAccount: LinkedAccount;
  isLoading: boolean;
};

const MODAL_TOP_VALUE = "0px";

/**
 * Shows existing and available custom mappings for a linked account
 */
const FieldMappings = ({
  fieldMappingInstances,
  fieldMappingTargets,
  fieldMappingConfigurations,
  overriddenCommonModelInstances,
  linkedAccount,
  isLoading,
}: FieldMappingsProps) => {
  // hooks
  const { productRestrictions } = useProductRestrictions();
  const { categoryCommonModels } = useCategoryCommonModels({ linkedAccount });

  // state
  const [customMappingMetaResponse, setCustomMappingMetaResponse] = useState<
    FieldMappingMetaResponse | undefined
  >();
  const [linkedAccountFieldMappingOptions, setLinkedAccountFieldMappingOptions] = useState<
    FieldMappingOptionsResponse | undefined
  >();
  const [showUpsellModal, setShowUpsellModal] = useState(
    !productRestrictions?.are_custom_fields_enabled,
  );

  const [isFieldMappingCreationModalOpen, setIsFieldMappingCreationModalOpen] = useState(false);

  // effects
  useEffect(() => {
    linkedAccount &&
      getFieldMappingMeta({
        linkedAccountID: linkedAccount.id,
        onFetch: (data) => {
          setCustomMappingMetaResponse(data);
        },
        onError: () => {},
      });
  }, [linkedAccount]);

  useEffect(() => {
    linkedAccount &&
      getLinkedAccountFieldMappings({
        linkedAccountID: linkedAccount.id,
        onFetch: (data) => {
          setLinkedAccountFieldMappingOptions(data);
        },
        onError: () => {},
      });
  }, [linkedAccount]);

  const fieldMappingsByCommonModel = [...fieldMappingInstances, ...fieldMappingTargets].reduce(
    (
      accumulatingObj: {
        [key: string]: Array<FieldMappingInstance | FieldMappingTarget>;
      },
      fieldMapping: FieldMappingInstance | FieldMappingTarget,
    ) => {
      const commonModelName = fieldMapping.common_model_name;
      accumulatingObj[commonModelName] = [
        ...(accumulatingObj[commonModelName] ?? []),
        fieldMapping,
      ];

      return accumulatingObj;
    },
    {},
  );

  const hasFieldMappingsLoaded = fieldMappingConfigurations !== null && !isLoading;

  const commonModelsToMappingConfigs = fieldMappingConfigurations
    ? fieldMappingConfigurations
        .sort((a, b) => a.common_model_name.localeCompare(b.common_model_name))
        .reduce(
          (
            accum: {
              [common_model_id: string]: FieldMappingCommonModelConfiguration;
            },
            mapping_target,
          ) => {
            accum[`${mapping_target.common_model_name}`] = mapping_target;
            return accum;
          },
          {},
        )
    : null;

  if (
    linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED ||
    linkedAccount.status === LinkedAccountStatuses.INCOMPLETE
  ) {
    return (
      <>
        <SectionHeaderWrapper
          className="mb-4"
          title="Field Mapping"
          headerRightHandContent={
            <Button
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              onClick={() => openInNewTab(DOCS_FIELD_MAPPINGS_PATH)}
              leftIcon={<BookOpen size={12} />}
            >
              Field Mapping docs
            </Button>
          }
        >
          <Alert showWarningIcon color="blue">{`Field Mapping is not available when ${
            linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED
              ? "account relink is needed"
              : "the linked account is incomplete"
          }`}</Alert>
        </SectionHeaderWrapper>
      </>
    );
  }

  const fieldMappingCards = hasFieldMappingsLoaded
    ? categoryCommonModels
        .filter((modelName) => {
          const fieldMappings = fieldMappingsByCommonModel[modelName] ?? [];
          const mappedTargets = fieldMappings.filter(
            (fieldMappingTarget: FieldMappingInstance | FieldMappingTarget) => {
              if ("field_traversal_path" in fieldMappingTarget) {
                return true;
              }

              return false;
            },
          );
          const filteredInstances = overriddenCommonModelInstances
            ? overriddenCommonModelInstances?.filter((ocmInstance) => {
                return ocmInstance.common_model_name == modelName;
              })
            : [];
          if (mappedTargets.length == 0 && filteredInstances.length == 0) {
            return false;
          }

          return true;
        })
        .map((modelName, index) => {
          return (
            <>
              <FieldMappingCommonModels
                key={index}
                commonModelName={modelName}
                linkedAccount={linkedAccount}
                fieldMappings={fieldMappingsByCommonModel[modelName] ?? []}
                linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
                hasFieldMappingsLoaded={hasFieldMappingsLoaded}
                overriddenCommonModelInstances={overriddenCommonModelInstances?.filter(
                  (ocmInstance) => {
                    return ocmInstance.common_model_name == modelName;
                  },
                )}
              />
            </>
          );
        })
    : [];

  return (
    <>
      {!productRestrictions?.are_custom_fields_enabled && (
        <FieldMappingUpsellModal
          showUpsellModal={showUpsellModal}
          setShowUpsellModal={setShowUpsellModal}
          modalTopValue={MODAL_TOP_VALUE}
        />
      )}
      {isFieldMappingCreationModalOpen &&
        commonModelsToMappingConfigs &&
        customMappingMetaResponse && (
          <FieldMappingModal
            isOpen={isFieldMappingCreationModalOpen}
            onClose={() => {
              setIsFieldMappingCreationModalOpen(false);
            }}
            linkedAccount={linkedAccount}
            linkedAccountMappingInfo={customMappingMetaResponse}
            commonModelsForIntegration={categoryCommonModels}
            commonModelsToMappingConfigs={commonModelsToMappingConfigs}
            fieldMappingTargets={fieldMappingTargets}
            fieldMappingInstances={fieldMappingInstances}
            linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
            setCustomMappingMetaResponse={setCustomMappingMetaResponse}
          />
        )}
      <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
        <SectionHeaderWrapper
          className="mb-5"
          title="Field Mapping"
          button={
            <div className="flex flex-row gap-x-3">
              <Button
                onClick={() => {
                  setIsFieldMappingCreationModalOpen(true);
                }}
                leftIcon={<Plus size={16} />}
              >
                Mapping
              </Button>
              <Button
                variant={ButtonVariant.SecondaryCharcoal}
                onClick={() =>
                  window.open(
                    "https://docs.merge.dev/supplemental-data/field-mappings/overview/",
                    "_blank",
                  )
                }
                rightIcon={<ExternalLink size={16} />}
              >
                Learn more
              </Button>
            </div>
          }
        >
          <div className="flex flex-col gap-8 mb-6">
            {hasFieldMappingsLoaded && fieldMappingCards.length == 0 && (
              <Card>
                <div className="border-b border-gray-10 px-6 py-5 items-center">
                  <Text variant="h4">Learn about Merge Field Mappings</Text>
                </div>

                <div className="pt-5 pb-6 px-6 gap-y-4">
                  <Text>
                    Map third-party <strong>Remote Fields</strong> to{" "}
                    <strong>new target fields</strong> or <strong>override existing fields</strong>{" "}
                    for this Linked Account. Go to{" "}
                    <Link href="/configuration/field-mappings/target-fields" target="_blank">
                      Advanced Configuration
                    </Link>{" "}
                    to add a default integration-wide mapping.
                  </Text>
                  <div className="text-center">
                    <img className="max-w-full w-fit" src={FieldMappingsExplainer} />
                  </div>
                </div>
              </Card>
            )}

            {hasFieldMappingsLoaded
              ? fieldMappingCards
              : categoryCommonModels.map((modelName, index) => (
                  <>
                    <FieldMappingCommonModels
                      key={index}
                      commonModelName={modelName}
                      linkedAccount={linkedAccount}
                      fieldMappings={fieldMappingsByCommonModel[modelName] ?? []}
                      hasFieldMappingsLoaded={hasFieldMappingsLoaded}
                      overriddenCommonModelInstances={[]}
                      linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
                    />
                  </>
                ))}
          </div>
        </SectionHeaderWrapper>
      </div>
    </>
  );
};

export default FieldMappings;
