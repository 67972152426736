import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../shared/SpinnerButton";
import { showErrorToast } from "../../../shared/Toasts";
import { fetchWithoutAuth, FormErrorData, hasAuthToken } from "../../../../api-client/APIClient";
import { Alert, Col, Form, Row } from "react-bootstrap";
import MergeLogo from "../../../../assets/svg/logos/merge/logo.svg";
import { useMediaQuery } from "react-responsive";
import { LANDING_PAGE_PATH, LOGIN_PATH } from "../../../../router/RouterUtils";
import { REGEX_EMAIL_ADDRESS } from "../../../shared/utils/SharedComponentUtils";
import DeprecatedH1 from "../../../../deprecated/DeprecatedH1";

const Title = styled(DeprecatedH1)`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
`;

interface SendResetPasswordFormProps {
  onSuccess: (isSuccessful: boolean) => void;
}

interface FormData {
  email: string;
}

function SendResetPasswordScreen() {
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <>
      {hasAuthToken() ? <Redirect to="/" /> : null}
      <div className="ForgotPassword">
        <div
          className={cx(
            "ForgotPassword-header d-flex align-items-center",
            isDesktopOrTablet ? "justify-content-between" : "justify-content-center",
          )}
        >
          <a href={LANDING_PAGE_PATH}>
            <img src={MergeLogo} />
          </a>
          {isDesktopOrTablet && <Link to={LOGIN_PATH}>Back to login</Link>}
        </div>

        <div className="ForgotPassword-form d-flex align-items-center justify-content-center">
          <div className="px-6 col-12 col-md-5 col-xl-4 my-9">
            <Title className="text-center mb-3">Forgot password</Title>
            {resetPasswordSuccess ? (
              <SendResetPasswordSuccess />
            ) : (
              <SendResetPasswordForm onSuccess={setResetPasswordSuccess} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function SendResetPasswordForm(props: SendResetPasswordFormProps) {
  const [isErrorShown, setIsErrorShown] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm();
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });

  const resetPassword = (data: FormData) => {
    setIsLoading(true);

    fetchWithoutAuth({
      path: "/users/password/send-reset",
      method: "POST",
      body: { email: data.email },
      onResponse: () => {
        setIsLoading(false);
        props.onSuccess(true);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                setIsErrorShown(data[field_name][0]);
                continue;
              }
              setError(field_name, { message: data[field_name][0] });
            }
            showErrorToast("Failed to reset password.");
          });
        } else {
          showErrorToast("Something went wrong, please check your connection and try again.");
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <p className="mt-6 mb-9 text-center">Get a link to reset your password.</p>
      <Form onSubmit={handleSubmit(resetPassword)}>
        {isErrorShown && <Alert variant="primary">{isErrorShown}</Alert>}
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Email"
            className={cx({ "is-invalid": errors.email })}
            ref={register({
              required: true,
              pattern: REGEX_EMAIL_ADDRESS,
            })}
          />
          {errors?.email?.message && (
            <Form.Control.Feedback type="invalid">{errors.email.message}</Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="text-center">
          <SpinnerButton
            text="Submit"
            isLoading={isLoading}
            className="primaryAction btn btn-primary btn-block"
          />
        </Form.Group>
        {!isDesktopOrTablet && (
          <Row>
            <Col className="text-center">
              <Link to={LOGIN_PATH}>Back to login</Link>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}

function SendResetPasswordSuccess() {
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <>
      <p className="mt-6 mb-9 text-center">
        We&apos;ve emailed you instructions to reset your password.
      </p>
      {!isDesktopOrTablet && (
        <Row>
          <Col className="text-center">
            <Link to={LOGIN_PATH}> Back to login</Link>
          </Col>
        </Row>
      )}
    </>
  );
}

export default SendResetPasswordScreen;
