import React from "react";
import { ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import LinkedAccountConditionPublishChangesDialogItem from "./LinkedAccountConditionPublishChangesDialogItem";
import { LinkedAccountCondition } from "../../../../../../models/Entities";

type LinkedAccountConditionPublishChangesDialogProps = {
  organizationName: string;
  changes: {
    added: Partial<LinkedAccountCondition>[];
    changed: LinkedAccountCondition[];
    deleted: LinkedAccountCondition[];
  };
  publishChanges: () => void;
  onClose: () => void;
  open: boolean;
  publishing: boolean;
};

const LinkedAccountConditionPublishChangesDialog = ({
  organizationName,
  changes,
  publishChanges,
  open,
  onClose,
  publishing,
}: LinkedAccountConditionPublishChangesDialogProps) => {
  return (
    <Dialog
      title="Publish changes"
      open={open}
      onClose={onClose}
      variant="md"
      primaryButtonText="Publish"
      onPrimaryButtonClick={publishChanges}
      primaryButtonLoading={publishing}
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
    >
      <div className="flex flex-col">
        <Text className="pb-4">
          Publishing your changes will affect {organizationName} data on the next sync
        </Text>

        {/* added */}
        {changes.added.map((linkedAccountCondition, index) => (
          <LinkedAccountConditionPublishChangesDialogItem
            key={index}
            className="mb-2"
            linkedAccountCondition={linkedAccountCondition}
            action="added"
          />
        ))}

        {/* changed */}
        {changes.changed.map((linkedAccountCondition) => (
          <LinkedAccountConditionPublishChangesDialogItem
            key={linkedAccountCondition.id}
            className="mb-2"
            linkedAccountCondition={linkedAccountCondition}
            action="changed"
          />
        ))}

        {/* deleted */}
        {changes.deleted.map((linkedAccountCondition) => (
          <LinkedAccountConditionPublishChangesDialogItem
            key={linkedAccountCondition.id}
            className="mb-2"
            linkedAccountCondition={linkedAccountCondition}
            action="deleted"
          />
        ))}
      </div>
    </Dialog>
  );
};

export default LinkedAccountConditionPublishChangesDialog;
