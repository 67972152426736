import React from "react";
import { ReactComponent as Link } from "../../assets/svg/merge-link.svg";

interface Props {
  size: number;
  className?: string;
}

const MergeLinkIcon = ({ size, className }: Props) => {
  return <Link height={size} width={size} className={className} />;
};

export default MergeLinkIcon;
