import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { stringRemoveSpaces } from "../../../../../../../../services";
import {
  Badge,
  Button,
  ButtonVariant,
  Checkbox,
  Text,
  Typeahead,
} from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";
import MaybeDisable from "../../../../../../../shared/MaybeDisable";

interface Props {
  commonModel: string;
  deletedWebhookEnabled: boolean;
  selectedFields: string[];
  enabledFieldsForModel?: string[];
  selectedCommonModelEvents: string[];
  onRemoveCommonModel: (commonModel: string) => void;
  onCheckboxChange: (isChecked: boolean, value: string) => void;
  selectedChangedDataCommonModelsToFields?: Record<string, string[]>;
  setSelectedChangedDataCommonModelsToFields: (
    selectedChangedDataCommonModelsToFields: Record<string, string[]>,
  ) => void;
}
const ALL_FIELDS_OPTION = "All fields";
const ChangedDataCommonModelCard = ({
  commonModel,
  deletedWebhookEnabled,
  selectedFields: initialSelectedFields,
  enabledFieldsForModel,
  selectedCommonModelEvents,
  onRemoveCommonModel,
  onCheckboxChange,
  selectedChangedDataCommonModelsToFields,
  setSelectedChangedDataCommonModelsToFields,
}: Props) => {
  // state
  const [selectedFields, setSelectedFields] = useState<string[]>(
    initialSelectedFields.length > 0 ? initialSelectedFields : [ALL_FIELDS_OPTION],
  );

  // use effect that updates the selected changed data common models to fields
  useEffect(() => {}, [selectedFields]);

  // handlers
  const handleRemoveCommonModel = () => {
    onRemoveCommonModel(commonModel);
  };

  const handleTypeaheadChange = (_: any, selected: string[] | null) => {
    if (selected) {
      if (selected.includes(ALL_FIELDS_OPTION) && !selectedFields.includes(ALL_FIELDS_OPTION)) {
        setSelectedFields([ALL_FIELDS_OPTION]);
        setSelectedChangedDataCommonModelsToFields({
          ...selectedChangedDataCommonModelsToFields,
          [commonModel]: [ALL_FIELDS_OPTION],
        });
      } else if (
        selected.includes(ALL_FIELDS_OPTION) &&
        selected.length > 1 &&
        selectedFields.includes(ALL_FIELDS_OPTION)
      ) {
        setSelectedFields(selected.filter((item) => item !== ALL_FIELDS_OPTION));
        setSelectedChangedDataCommonModelsToFields({
          ...selectedChangedDataCommonModelsToFields,
          [commonModel]: selected.filter((item) => item !== ALL_FIELDS_OPTION),
        });
      } else {
        setSelectedFields(selected);
        setSelectedChangedDataCommonModelsToFields({
          ...selectedChangedDataCommonModelsToFields,
          [commonModel]: selected,
        });
      }
    }
  };

  const handleCheckboxChange = (eventName: string) => (isChecked: boolean) => {
    onCheckboxChange(isChecked, stringRemoveSpaces(commonModel) + eventName);
  };

  const isChecked = (eventName: string) =>
    selectedCommonModelEvents.includes(stringRemoveSpaces(commonModel) + eventName);

  const options = [ALL_FIELDS_OPTION, ...(enabledFieldsForModel || [])];
  const renderOption = (option: string) => {
    return (
      <div className="flex justify-between items-center hover:bg-gray-100 w-100">
        <Text>{option}</Text>
      </div>
    );
  };

  return (
    <div className="border border-gray-10 rounded-lg w-full bg-white mb-2 gap-y-3 mt-5">
      <div className="border-b border-gray-10 p-4 flex lg:flex-row cursor-default flex-col lg:items-center items-start justify-between">
        <Text variant="h6">{commonModel}</Text>
        <div className="flex flex-row justify-center items-center lg:mt-0 mt-3">
          <Checkbox
            onChange={handleCheckboxChange(".added")}
            checked={isChecked(".added")}
            className="lg:ml-6"
            label="Created"
          />
          <Checkbox
            onChange={handleCheckboxChange(".changed")}
            checked={isChecked(".changed")}
            className="ml-6"
            label="Updated"
          />
          <MaybeDisable
            className="flex items-center justify-center"
            disable={!deletedWebhookEnabled}
            tooltip={`
                Checking the 'Deleted' option will allow your organization to receive deletion webhooks from Merge if your organization has either the Deletion Detection or the Receiving Webhooks feature enabled. Reach out to us at support@merge.dev to turn on either feature.
                  `}
            inline
            hasPadding={false}
          >
            <Checkbox
              onChange={handleCheckboxChange(".removed")}
              checked={isChecked(".removed")}
              className="ml-6"
              label="Deleted"
              disabled={!deletedWebhookEnabled}
            />
          </MaybeDisable>

          <Button
            size="sm"
            className="ml-3"
            variant={ButtonVariant.IconShadowHidden}
            onClick={handleRemoveCommonModel}
          >
            <X size={16} />
          </Button>
        </div>
      </div>
      {enabledFieldsForModel && enabledFieldsForModel.length > 0 ? (
        <div className="flex flex-col gap-2 px-4 pt-3 pb-4 w-full">
          <Text variant="h6">Select fields</Text>
          <div>
            {selectedFields.includes(ALL_FIELDS_OPTION) ? (
              <Typeahead
                multiple
                showSearchIcon
                onChange={handleTypeaheadChange}
                options={options}
                value={selectedFields}
                renderOption={renderOption}
                disableCloseOnSelect
                disableClearable
                placeholder=""
                tagsLimit={4}
              />
            ) : (
              <Typeahead
                multiple
                showSearchIcon
                onChange={handleTypeaheadChange}
                options={options}
                value={selectedFields}
                renderOption={renderOption}
                disableCloseOnSelect
                disableClearable
                placeholder=""
                tagsLimit={4}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="mt-3 px-4 py-2 w-full">
          <div className="text-gray-60 mb-2">Field configuration not available for this model</div>
        </div>
      )}
    </div>
  );
};

export default ChangedDataCommonModelCard;
