import React from "react";
import size from "lodash/size";
import { X } from "lucide-react";
import IntegrationIssueLogsRow from "./IntegrationIssueLogsRow";
import { LinkedAccount, Log } from "../../../../../models/Entities";
import IntegrationIssueDetailedErrorContainer from "./IntegrationIssueDetailedErrorContainer";
import { IntegrationIssue } from "../../../IntegrationsManagementEntities";
import ImpactedModelsRow from "./ImpactedModelsRow";
import { getImpactedModels } from "../../../utils/IntegrationsManagementUtils";
import { dismissIntegrationIssue } from "../../../utils/IntegrationsManagementAPIClient";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import { Button, ButtonVariant, Card, HTTPMethod, Text } from "@merge-api/merge-javascript-shared";
import { useRequest } from "../../../../shared/hooks/useRequest";
import { IssueDescription } from "../../../../../constants";

type IntegrationIssuesWidgetProps = {
  integrationIssue: IntegrationIssue;
  linkedAccount: LinkedAccount | null;
  showPreview: boolean;
  refetchIntegrationIssues: () => void;
};
const IntegrationIssueWidget = ({
  integrationIssue,
  linkedAccount,
  showPreview,
  refetchIntegrationIssues,
}: IntegrationIssuesWidgetProps) => {
  const { data: issueLogs } = useRequest<{ recent_logs: Log[] }>({
    path: `integrations/issues/${integrationIssue.id}/logs`,
    method: HTTPMethod.GET,
    skip: !integrationIssue,
  });

  const impactedModels = integrationIssue?.metadata
    ? getImpactedModels(integrationIssue.metadata)
    : {};
  const isMissingPermissionsIssue = integrationIssue.error_description == "Missing permission";
  const isWarningIssue =
    integrationIssue.error_description == IssueDescription.MISSING_PERMISSION ||
    integrationIssue.error_description == IssueDescription.SFTP_MISSING_REQUIRED_FIELDS ||
    integrationIssue.error_description == IssueDescription.SFTP_INCORRECTLY_FORMATTED_ROW;
  const hasImpactedModels = Object.keys(impactedModels).length > 0;
  return (
    <div>
      <div className={`h-[6px] rounded-t-lg ${isWarningIssue ? "bg-yellow-70" : "bg-red-60"}`} />
      <Card className="flex flex-col rounded-t-none p-4">
        <div className="flex flex-row justify-between">
          <Text variant="h5" className={isWarningIssue ? "text-yellow-70" : "text-red-60"}>
            {integrationIssue.error_description}
          </Text>
          <Button
            size="sm"
            variant={ButtonVariant.IconShadowHidden}
            className="ml-auto"
            onClick={() => {
              dismissIntegrationIssue({
                integrationIssueId: integrationIssue.id,
                onSuccess: () => {
                  showSuccessToast("Successfully dismissed Issue!");
                  refetchIntegrationIssues();
                },
                onFailure: () => {
                  showErrorToast("Unable to dismiss Issue");
                },
              });
            }}
          >
            <X size={16} />
          </Button>
        </div>
        {isMissingPermissionsIssue && integrationIssue.metadata && hasImpactedModels && (
          <ImpactedModelsRow impactedModels={impactedModels} isOverviewPage />
        )}
        <Text variant="h6" className="mt-4">
          Recommended remediation to share with your customer
        </Text>
        {integrationIssue && (
          <IntegrationIssueDetailedErrorContainer
            issueMetadata={integrationIssue.metadata}
            integrationIssue={integrationIssue}
            showPreview={showPreview}
            isMissingPermissionIssue={isMissingPermissionsIssue}
          />
        )}
        {size(issueLogs?.recent_logs) > 0 && showPreview && linkedAccount && (
          <IntegrationIssueLogsRow
            logs={issueLogs?.recent_logs ?? []}
            linkedAccount={linkedAccount}
          />
        )}
      </Card>
    </div>
  );
};
export default IntegrationIssueWidget;
