import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { useMergeLink } from "@mergeapi/react-merge-link";
import React, { useState } from "react";
import MergeLinkIcon from "../../../shared/MergeLinkIcon";

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  tenantConfig: {} | { tenantConfig: { apiBaseURL: string } };
  onNewLinkedAccount: (wasSuccess: boolean) => void;
  accountLimitReached?: boolean;
}

const ProdMergeLinkActionButton = ({
  linkToken,
  tenantConfig,
  onNewLinkedAccount,
  accountLimitReached,
}: Props) => {
  const [wasSuccess, setWasSuccess] = useState(false);
  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: () => {
      setWasSuccess(true);
    },
    onExit: () => onNewLinkedAccount(wasSuccess),
    ...tenantConfig,
  });

  return (
    <Button
      size="sm"
      variant={ButtonVariant.TertiaryWhite}
      onClick={() => {
        setWasSuccess(false);
        open();
      }}
      disabled={accountLimitReached === true ? true : !isReady}
      leftIcon={<MergeLinkIcon size={16} />}
    >
      Create test Linked Account
    </Button>
  );
};

export default ProdMergeLinkActionButton;
