import React from "react";
import uniq from "lodash/uniq";

import { stringRemoveSpaces, removeValueFromStringArray } from "../../../../../../services";
import ChangedDataCategoryCard from "./WebhookTypeSelect/components/ChangedDataCategoryCard";
import { SelectedWebhookType } from "../enums";
import useProductRestrictions from "../../../../../shared/hooks/useProductRestrictions";
import { CategoryMap, CategoryToModelsToFieldsMap } from "../hooks/useWebhookOptions";
import { APICategory, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import INTEGRATION_CATEGORY_LIST, {
  displayNameForAPICategory,
} from "../../../../../../models/Helpers";
import SyncCommonModelCard from "./WebhookTypeSelect/components/SyncCommonModelCard";

interface CommonModelSelectProps {
  selectedCategories: APICategory[];
  onSelectedCategoriesChange: (selectedCategories: APICategory[]) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  modelToCategoryMap: CategoryMap;
  modelsToFieldsEnabledMap?: CategoryToModelsToFieldsMap;
  selectedChangedDataCommonModelsToFields?: Record<string, string[]>;
  selectedCommonModels: string[];
  selectedCommonModelEvents: string[];
  onSelectedCommonModelsChange: (selectedCommonModels: string[]) => void;
  onSelectedCommonModelEventsChange: (selectedCommonModelEvents: string[]) => void;
  setSelectedChangedDataCommonModelsToFields: (
    selectedChangedDataCommonModelsToFields: Record<string, string[]>,
  ) => void;
  currentSection: SelectedWebhookType;
}

function CommonModelSelect(props: CommonModelSelectProps) {
  const {
    selectedCategories,
    onSelectedCategoriesChange,
    selectedWebhookType,
    modelToCategoryMap,
    modelsToFieldsEnabledMap,
    selectedChangedDataCommonModelsToFields,
    selectedCommonModels,
    selectedCommonModelEvents,
    onSelectedCommonModelsChange,
    onSelectedCommonModelEventsChange,
    setSelectedChangedDataCommonModelsToFields,
    currentSection,
  } = props;

  // hooks
  const { productRestrictions, orgBillingPlan } = useProductRestrictions();

  // event handlers
  const deselectChangedDataCommonModel = (commonModel: string) => {
    // remove common models for a specific category from the selected common models
    const updatedCommonModels = selectedCommonModels.filter((m) => !commonModel.includes(m));

    const updatedSelectedEvents = selectedCommonModelEvents.filter(
      (e) => !stringRemoveSpaces(commonModel).includes(e.split(".")[0]),
    );
    onSelectedCommonModelsChange(updatedCommonModels);
    onSelectedCommonModelEventsChange(uniq(updatedSelectedEvents));
  };

  const deselectCommonModels = (commonModels: string[]) => {
    // remove common models for a specific category from the selected common models
    const updatedCommonModels = selectedCommonModels.filter((m) => !commonModels.includes(m));

    const updatedSelectedEvents = selectedCommonModelEvents.filter(
      (e) => !commonModels.map((m) => stringRemoveSpaces(m)).includes(e.split(".")[0]),
    );
    onSelectedCommonModelsChange(updatedCommonModels);
    onSelectedCommonModelEventsChange(uniq(updatedSelectedEvents));
  };

  const deselectCategory = (category: APICategory) => {
    // when the entire category is deselected we need to remove the category from selectedCategories
    // and remove all the relevant common models
    const commonModelsForSelectedCategory = getCommonModelsForCategory(category);
    deselectCommonModels(commonModelsForSelectedCategory);
    onSelectedCategoriesChange(selectedCategories.filter((m) => m !== category));
  };

  // additional state for category selection, and logic for processing those commonModels based on category selected

  const getCommonModelsForCategory = (category: APICategory) => {
    if (category === undefined) {
      return [];
    }
    return Object.entries(modelToCategoryMap)
      .filter(([model, modelCategory]) => modelCategory === category)
      .map(([model]) => model);
  };

  // state for handling which type of webhook selected

  const isCommonModelSync =
    (selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT) ||
      selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_SYNC_ANY)) &&
    (currentSection === SelectedWebhookType.COMMON_MODEL_SYNC_SELECT ||
      currentSection === SelectedWebhookType.COMMON_MODEL_SYNC_ANY);

  const isChangedDataSelect =
    selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT) &&
    currentSection === SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT;
  const isSyncSelect =
    selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT) && isCommonModelSync;

  // when a common model is selected or deselected, update associated events
  const updateSelectedChangedDataEvents = (checked: boolean, value: string) => {
    if (checked) {
      onSelectedCommonModelEventsChange([...selectedCommonModelEvents, value]);
    } else {
      onSelectedCommonModelEventsChange(
        removeValueFromStringArray(selectedCommonModelEvents, value),
      );
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation(); // Prevent the click event from propagating
      }}
    >
      <div className="flex flex-row items-center w-full mb-4 justify-normal gap-3 flex-wrap">
        {INTEGRATION_CATEGORY_LIST.map((category) => (
          <Button
            className="gap-3"
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            onClick={() => {
              onSelectedCategoriesChange([...selectedCategories, category]);
            }}
            disabled={selectedCategories && selectedCategories.includes(category)}
          >
            {displayNameForAPICategory(category)}
          </Button>
        ))}
      </div>
      {isChangedDataSelect && (
        <div className="flex content-around flex-wrap cursor-pointer gap-5">
          {selectedCategories.map((category) => {
            const selectedCommonModelsForCategory = getCommonModelsForCategory(category).filter(
              (m) => selectedCommonModels.includes(m),
            );
            return (
              <ChangedDataCategoryCard
                category={category}
                commonModelOptions={getCommonModelsForCategory(category)}
                modelsToFieldsEnabledMap={modelsToFieldsEnabledMap}
                selectedCommonModels={selectedCommonModels}
                selectedCommonModelEvents={selectedCommonModelEvents}
                selectedCommonModelsForCategory={selectedCommonModelsForCategory}
                onRemoveCategory={() => deselectCategory(category)}
                onRemoveCommonModel={deselectChangedDataCommonModel}
                deletedWebhookEnabled={
                  orgBillingPlan?.deletion_detection_enabled ||
                  productRestrictions?.third_party_webhooks_enabled ||
                  false
                }
                onCheckboxChange={(isChecked, value) =>
                  updateSelectedChangedDataEvents(isChecked, value)
                }
                onSelectedCommonModelsChange={onSelectedCommonModelsChange}
                onSelectedCommonModelEventsChange={onSelectedCommonModelEventsChange}
                selectedChangedDataCommonModelsToFields={selectedChangedDataCommonModelsToFields}
                setSelectedChangedDataCommonModelsToFields={
                  setSelectedChangedDataCommonModelsToFields
                }
              />
            );
          })}
        </div>
      )}
      {isSyncSelect && (
        <div className="flex flex-row content-around gap-x-3 gap-y-3 flex-wrap cursor-pointer">
          {selectedCategories.map((category) => {
            const selectedCommonModelsForCategory = getCommonModelsForCategory(category).filter(
              (m) => selectedCommonModels.includes(m),
            );
            return (
              <SyncCommonModelCard
                category={category}
                onRemoveCategory={() => deselectCategory(category)}
                commonModelOptions={getCommonModelsForCategory(category)}
                selectedCommonModels={selectedCommonModels}
                selectedCommonModelEvents={selectedCommonModelEvents}
                selectedCommonModelsForCategory={selectedCommonModelsForCategory}
                onSelectedCommonModelsChange={onSelectedCommonModelsChange}
                onSelectedCommonModelEventsChange={onSelectedCommonModelEventsChange}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CommonModelSelect;
