import React, { useEffect } from "react";
import {
  ConditionPreset,
  ConditionPresetMeta,
  ConditionPresetMetaOperator,
  USER_CONFIGURED_OPERATOR,
} from "../../../../../../models/Entities";
import { APICategory, Text, TextFieldVariant, Typeahead } from "@merge-api/merge-javascript-shared";
import { getOperatorOptions, getOperatorValue } from "../helpers";
import { Calculator } from "lucide-react";
import { operatorMap } from "../../constants";
import isUndefined from "lodash/isUndefined";
import UserConfiguredPlaceholder from "./UserConfiguredPlaceholder";
import UpsellFilterOptionMessage from "./UpsellFilterOptionMessage";

type OperatorSelectProps = {
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
  showGatedSelectiveSync: boolean;
  disabled?: boolean;
  category: APICategory;
};

const OperatorSelect = ({
  conditionPreset,
  conditionPresetMeta,
  onConditionPresetChange,
  showGatedSelectiveSync,
  disabled,
  category,
}: OperatorSelectProps) => {
  // derived state
  const operatorOptions = getOperatorOptions(conditionPreset, conditionPresetMeta, category);
  const operatorValue = getOperatorValue(conditionPreset, conditionPresetMeta, category);

  // event handlers
  const onOperatorChange = (_: any, option: ConditionPresetMetaOperator | null) => {
    if (option?.operator == USER_CONFIGURED_OPERATOR) {
      onConditionPresetChange({
        ...conditionPreset,
        operator: undefined,
        value: undefined,
        is_end_user_configured: true,
      });
    } else {
      onConditionPresetChange({
        ...conditionPreset,
        operator: option?.operator || undefined,
        is_end_user_configured: false,
      });
    }
  };

  // effects
  // set operatorOption to user configured if only one option exists
  useEffect(() => {
    if (
      operatorOptions.length === 1 && // only on operator option
      !conditionPreset.is_end_user_configured && // not already set to is_end_user_configured
      !isUndefined(conditionPreset.normalized_key_name) // there is a field selected already (if not then operator options being 1 is expected)
    ) {
      onConditionPresetChange({
        ...conditionPreset,
        operator: undefined,
        value: undefined,
        is_end_user_configured: true,
      });
    }
  }, [conditionPreset, onConditionPresetChange, operatorOptions.length]);

  if (operatorValue?.operator === USER_CONFIGURED_OPERATOR && operatorOptions.length === 1) {
    return <UserConfiguredPlaceholder disabled={disabled} />;
  }

  return (
    <div className="flex flex-1">
      {showGatedSelectiveSync ? (
        <Typeahead
          className="h-full py-[3px]"
          placeholder="Select operator..."
          disableClearable
          borderVariant={TextFieldVariant.InlinedRounded}
          options={[operatorValue]}
          value={operatorValue}
          getOptionLabel={(operatorObject) => {
            const option = operatorObject?.operator
              ? conditionPresetOperatorMap[operatorObject.operator]
              : null;

            if (operatorObject?.operator === USER_CONFIGURED_OPERATOR) {
              return conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR].title as string;
            }

            return option?.title as string;
          }}
          renderOption={(operatorObject) => {
            let option = operatorObject
              ? conditionPresetOperatorMap[operatorObject.operator]
              : null;

            if (operatorObject?.operator === USER_CONFIGURED_OPERATOR) {
              option = conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR];
            }

            return (
              <div className="flex flex-col">
                <Text className="text-gray-90">{option?.title}</Text>
                <Text variant="caption" className="text-gray-60">
                  {option?.subtitle}
                </Text>
              </div>
            );
          }}
          typeaheadFooter={<UpsellFilterOptionMessage />}
          disabled={disabled}
        />
      ) : (
        <Typeahead
          className="h-full py-[3px]"
          placeholder="Select operator..."
          disableClearable
          borderVariant={TextFieldVariant.InlinedRounded}
          options={operatorOptions}
          value={operatorValue}
          onChange={onOperatorChange}
          getOptionLabel={({ operator }) => {
            return conditionPresetOperatorMap[operator].title as string;
          }}
          renderOption={({ operator }) => {
            let option = conditionPresetOperatorMap[operator];

            if (operator === USER_CONFIGURED_OPERATOR) {
              option = conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR];
            }

            return (
              <div className="flex flex-col">
                <Text className="text-gray-90">{option.title}</Text>
                <Text variant="caption" className="text-gray-60">
                  {option.subtitle}
                </Text>
              </div>
            );
          }}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default OperatorSelect;

// constants
export const conditionPresetOperatorMap = {
  ...operatorMap,
  [USER_CONFIGURED_OPERATOR]: {
    title: "is user configured",
    subtitle: "Configurable by your user in Link",
  },
};

export const USER_CONFIGURED_OPERATOR_OPTION: ConditionPresetMetaOperator = {
  operator: USER_CONFIGURED_OPERATOR,
  key_type: null,
  supported_integrations: [],
};
